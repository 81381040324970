import React from "react";
import {
  isCommercialUser,
  isLabHead,
  isСhieftain,
  isLabEmployee,
  isTenderUser,
  isMedUser,
  isAccountantEmployee,
} from "../../utils/defineUser";
import { formatDate } from "../../utils/formatDate";
import { dateToText, dateToTextTemp } from "../../utils/stages";
import { useContext } from "react";
import ProjectContext from "../../context/ProjectsContext";
import WorkloadCell from "./WorkloadCell";

function StatisticTableRow({ project, tableType, incomeTab, index, type }) {
  const { setEcoStarDebt } = useContext(ProjectContext);

  if (tableType === "income_by_paid" && project.title === "Итого") {
    setEcoStarDebt(project?.debtSumSM?.total.toFixed(2));
  }

  return (
    <>
      {tableType === "income" && (
        <tr>
          <td>{dateToTextTemp(project.title)}</td>
          <StatisticInnerTable
            tableType={tableType}
            incomeTab={incomeTab}
            total={project?.total?.total}
            com={project?.total?.commercial}
            ten={project?.total?.tender}
          />
          {type !== "plannedDate" && (
            <>
              <StatisticInnerTable
                tableType={tableType}
                incomeTab={incomeTab}
                total={project?.fulfilment?.total}
                com={project?.fulfilment?.commercial}
                ten={project?.fulfilment?.tender}
              />
              <StatisticInnerTable
                tableType={tableType}
                incomeTab={incomeTab}
                total={project?.terminated?.total}
                com={project?.terminated?.commercial}
                ten={project?.terminated?.tender}
              />
              <StatisticInnerTable
                tableType={tableType}
                incomeTab={incomeTab}
                total={project?.fulfilled?.total}
                com={project?.fulfilled?.commercial}
                ten={project?.fulfilled?.tender}
              />
              <StatisticInnerTable
                tableType={tableType}
                incomeTab={incomeTab}
                total={project?.acts?.total}
                com={project?.acts?.commercial}
                ten={project?.acts?.tender}
              />
            </>
          )}

          {/* <td>
            {
              project?.sumToPaySM?.total || project?.sumToPaySM?.total === 0
                ? project.sumToPaySM.total.toFixed(2)
                : ' - '
            }
          </td>
          <td>
            {
              project?.paidSumSM?.total || project?.paidSumSM?.total === 0
                ? project.paidSumSM.total.toFixed(2)
                : ' - '
            }
          </td>
          */}
          {/* <td>
            {
              project?.debtSumSM?.total || project?.debtSum?.total === 0
                ? project.debtSumSM.total.toFixed(2)
                : ' - '
            }  </td> */}
        </tr>
      )}
      {tableType === "returns" && (
        <tr>
          <td>{dateToTextTemp(project.title)}</td>
          <StatisticInnerTable
            tableType={tableType}
            total={project?.roSTotal?.total}
            com={project?.roSTotal?.commercial}
            ten={project?.roSTotal?.tender}
          />
          <StatisticInnerTable
            tableType={tableType}
            total={project?.roSReturned?.total}
            com={project?.roSReturned?.commercial}
            ten={project?.roSReturned?.tender}
          />
          <StatisticInnerTable
            tableType={tableType}
            total={project?.roSLeft?.total}
            com={project?.roSLeft?.commercial}
            ten={project?.roSLeft?.tender}
          />
        </tr>
      )}
      {tableType === "total_expenses" && (
        <tr>
          <td>{dateToTextTemp(project.title)}</td>
          <StatisticInnerTable
            tableType={tableType}
            total={project?.expensesTotal?.total}
          />
        </tr>
      )}
      {tableType === "expenses" && (
        <tr>
          <td>{dateToTextTemp(project?.description)}</td>
          <StatisticInnerTable
            tableType={tableType}
            data={project?.amount.toFixed(2)}
          />
          <StatisticInnerTable
            tableType={tableType}
            data={formatDate(project?.paymentDate)}
          />
        </tr>
      )}
      {tableType === "workload" && (
        <tr>
          <td>{index}</td>
          <td>{dateToTextTemp(project?.title)}</td>
          <StatisticInnerTable
            tableType={tableType}
            project={project}
            workloadData={{
              total: {
                projectCount:
                  project?.waitingForRequest?.allSummed?.projectsCount,
                workspaceCount:
                  project?.waitingForRequest?.allSummed?.workspaceCount,
              },
              sout: project?.waitingForRequest?.all?.sout,
              pk: project?.waitingForRequest?.all?.pk,
              opr: project?.waitingForRequest?.all?.opr,
              datesCount: null,
              containsX: null,
            }}
          />
          <StatisticInnerTable
            tableType={tableType}
            workloadData={{
              total: {
                projectCount:
                  project?.informationCollection?.allSummed?.projectsCount,
                workspaceCount:
                  project?.informationCollection?.allSummed?.workspaceCount,
              },
              sout: project?.informationCollection?.all?.sout,
              pk: project?.informationCollection?.all?.pk,
              opr: project?.informationCollection?.all?.opr,
              datesCount: project?.informationCollection?.byDates,
              containsX: project?.informationCollection?.allSummed?.withX,
            }}
            project={project}
          />
          <StatisticInnerTable
            tableType={tableType}
            workloadData={{
              total: {
                projectCount: project?.readyToWork?.allSummed?.projectsCount,
                workspaceCount: project?.readyToWork?.allSummed?.workspaceCount,
              },
              sout: project?.readyToWork?.all?.sout,
              pk: project?.readyToWork?.all?.pk,
              opr: project?.readyToWork?.all?.opr,
              datesCount: project?.readyToWork?.byDates,
              containsX: project?.readyToWork?.allSummed?.withX,
            }}
            project={project}
          />
          <StatisticInnerTable
            tableType={tableType}
            workloadData={{
              total: {
                projectCount: project?.acceptedToWork?.allSummed?.projectsCount,
                workspaceCount:
                  project?.acceptedToWork?.allSummed?.workspaceCount,
              },
              sout: project?.acceptedToWork?.all?.sout,
              pk: project?.acceptedToWork?.all?.pk,
              opr: project?.acceptedToWork?.all?.opr,
              datesCount: project?.acceptedToWork?.byDates,
              containsX: project?.acceptedToWork?.allSummed?.withX,
            }}
            project={project}
          />
          <StatisticInnerTable
            tableType={tableType}
            project={project}
            isGeneral
          />
          <StatisticInnerTable
            tableType={tableType}
            project={project}
            workloadData={{
              total: {
                projectCount:
                  project?.waitingAndAgreement?.allSummed?.projectsCount,
                workspaceCount:
                  project?.waitingAndAgreement?.allSummed?.workspaceCount,
              },
              sout: project?.waitingAndAgreement?.all?.sout,
              pk: project?.waitingAndAgreement?.all?.pk,
              opr: project?.waitingAndAgreement?.all?.opr,
              datesCount: null,
              containsX: null,
            }}
          />
          <StatisticInnerTable
            tableType={tableType}
            project={project}
            workloadData={{
              total: {
                projectCount: project?.finish?.allSummed?.projectsCount,
                workspaceCount: project?.finish?.allSummed?.workspaceCount,
              },
              sout: project?.finish?.all?.sout,
              pk: project?.finish?.all?.pk,
              opr: project?.finish?.all?.opr,
              datesCount: null,
              containsX: null,
            }}
          />
          <StatisticInnerTable
            tableType={tableType}
            project={project}
            isTotal
          />
        </tr>
      )}
      {tableType === "income_by_paid" && (
        <tr>
          <td>{dateToText(project.title)}</td>
          <StatisticInnerTable
            tableType={tableType}
            incomeTab={incomeTab}
            total={project?.paidSum?.total}
            com={project?.paidSum?.commercial}
            ten={project?.paidSum?.tender}
          />
          <td>
            {project?.sumToPaySM?.total || project?.sumToPaySM?.total === 0
              ? project.sumToPaySM.total.toFixed(2)
              : " - "}
          </td>
          <td>
            {project?.expensesTotal?.total
              ? project.expensesTotal.total.toFixed(2)
              : " - "}
          </td>
          <td>
            {project?.sumToPay?.total
              ? project.sumToPay.total.toFixed(2)
              : " - "}
          </td>
          <td>
            {project?.paidSumSM?.total || project?.paidSumSM?.total === 0
              ? project.paidSumSM.total.toFixed(2)
              : " - "}
          </td>
          <td>
            {project?.debtSumSM?.total || project?.debtSum?.total === 0
              ? project.debtSumSM.total.toFixed(2)
              : " - "}
          </td>
        </tr>
      )}
    </>
  );
}

export function StatisticInnerTable({
  tableType,
  total,
  com,
  ten,
  data,
  incomeTab,
  project,
  workloadData,
  isGeneral = false,
  isTotal = false,
}) {
  const valueHandler = (value) => (isNaN(value) ? 0 : value);
  return (
    <td>
      {tableType === "income" && (
        <table style={{ width: "100%" }}>
          <>
            <tr>
              <td colSpan={2} style={{ border: "1px solid transparent" }}>
                {total?.toFixed(2) || 0}
              </td>
            </tr>
            <tr>
              <td style={{ border: "1px solid transparent" }}>
                K: {com?.toFixed(2) || 0}
                <br />
                Т: {ten?.toFixed(2) || 0}
              </td>
            </tr>
          </>
          {/* {
							isLabHead()
							&&
							//Tender statistics
							<tr>
								<td style={{ border: '1px solid transparent' }}> {ten || ten === 0 ? ten.toFixed(2) : ' - '}</td>
							</tr>
						} */}
          {isCommercialUser() && (
            //Commercial statistics
            <tr>
              <td style={{ border: "1px solid transparent" }}>
                {" "}
                {com || com === 0 ? com.toFixed(2) : " - "}
              </td>
            </tr>
          )}
          {incomeTab === "common" && (
            <>
              <tr>
                <td colSpan={2} style={{ border: "1px solid transparent" }}>
                  {total || total === 0 ? total.toFixed(2) : " - "}
                </td>
              </tr>
              <tr>
                <td style={{ border: "1px solid transparent" }}>
                  K: {com || com === 0 ? com.toFixed(2) : " - "}
                </td>
                <td style={{ border: "1px solid transparent" }}>
                  Т: {ten || ten === 0 ? ten.toFixed(2) : " - "}
                </td>
              </tr>
            </>
          )}
          {incomeTab === "commercial" && (
            <tr>
              <td style={{ border: "1px solid transparent" }}>
                {" "}
                {com || com === 0 ? com.toFixed(2) : " - "}
              </td>
            </tr>
          )}
          {incomeTab === "tender" && (
            <tr>
              <td style={{ border: "1px solid transparent" }}>
                {" "}
                {ten || ten === 0 ? ten.toFixed(2) : " - "}
              </td>
            </tr>
          )}
        </table>
      )}
      {tableType === "returns" && (
        <table style={{ width: "100%" }}>
          <td colSpan={2} style={{ border: "1px solid transparent" }}>
            {total || total === 0 ? total.toFixed(2) : " - "}
          </td>
        </table>
      )}
      {tableType === "total_expenses" && (
        <span>{total || total === 0 ? total.toFixed(2) : " - "}</span>
      )}
      {tableType === "expenses" && <span>{data}</span>}
      {tableType === "workload" && (
        <WorkloadCell
          project={project}
          workloadData={workloadData}
          isGeneral={isGeneral}
          isTotal={isTotal}
        />
      )}
      {tableType === "income_by_paid" && (
        <table style={{ width: "100%" }}>
          <tr>
            <td colSpan={2} style={{ border: "1px solid transparent" }}>
              {total || total === 0 ? total.toFixed(2) : " - "}
            </td>
          </tr>
          <tr>
            <td style={{ border: "1px solid transparent" }}>
              K: {com || com === 0 ? com.toFixed(2) : " - "}
            </td>
            <td style={{ border: "1px solid transparent" }}>
              Т: {ten || ten === 0 ? ten.toFixed(2) : " - "}
            </td>
          </tr>
        </table>
      )}
      {tableType === "agent_reports" && (
        <table style={{ width: "100%" }}>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td></td>
          </tr>
        </table>
      )}
    </td>
  );
}
export { StatisticTableRow };
