import ProjectTableHeaders from "./ProjectHeaders";
import { useContext, useEffect, useState } from "react";
import ProjectContext from "../../context/ProjectsContext";
import ProjectRowTender from "./ProjectRowTender";
import { userIsAgent } from "utils/userIsAgent";
import { fetchAccount } from "utils/fetchAccount";

function ProjectsTableTender({ projects, departmentId, setProjects }) {
  const {
    currentPageNumber,
    additionalInfoValue,
    additionalInfoValue2,
    account,
    setAccount,
  } = useContext(ProjectContext);
  const PAGE_ROW_COUNT = account?.organizationId === 1 ? 20 : 2500;
  const [items, setItems] = useState([]);

  useEffect(() => {
    fetchAccount().then((res) => {
      setAccount(res);
    });
  }, []);

  useEffect(() => {
    if (account?.organizationId) {
      const accountOrgId = account.organizationId;
      if (accountOrgId === 1) {
        setItems(projects);
        return;
      } else {
        console.log(projects);

        userIsAgent().then((res) => {
          if (res) {
            console.log(123123);
            const data = projects?.filter((record) => {
              return record?.organizationId === accountOrgId;
            });
            setItems(data);
            if (data?.length === 0) setProjects(0);
          } else {
            setItems(projects);
          }
        });
      }
    }
  }, [account]);

  const generateProjectNumber = (idx) => {
    const lastNumber = PAGE_ROW_COUNT * currentPageNumber;
    const firstNumber = lastNumber - PAGE_ROW_COUNT + 1;

    const arr = [];
    for (let i = firstNumber; i <= lastNumber; i++) {
      arr.push(i);
    }
    return arr[idx];
  };

  return (
    <section className="workspace__table-wrapper">
      <div className="table-wrapper">
        <table
          className={`table workspace__table ${
            additionalInfoValue && additionalInfoValue2 ? "padding_0" : ""
          }`}
        >
          <ProjectTableHeaders departmentId={departmentId} />
          <tbody>
            {items &&
              items.map((project, idx) => (
                <ProjectRowTender
                  key={project.id}
                  project={project}
                  departmentId={departmentId}
                  projectNumber={generateProjectNumber(idx)}
                />
              ))}
          </tbody>
        </table>
      </div>
    </section>
  );
}
export default ProjectsTableTender;
