import { api } from 'settings/Api';
const rejectTender = async (projectId, type, stage) => {
	let url;
	switch (stage) {
		case 'Basket': {
			url = `/api/sout/admin/separated-projects/${projectId}`;
			break;
		}
		case 'PrintedAndSent': {
			url = `/api/sout/workflow/separated-project/suspend/${projectId}`;
			break;
		}
		default: {
			url = `/api/sout/workflow/${type}/reject/${projectId}`;
			break;
		}
	}

	
	const token = JSON.parse(localStorage.getItem("authTokens")).data.accessToken;
	let res = await api(url, {
		method: stage === 'Basket'?'DELETE':"GET",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
			// 'Authorization':  'String(authTokens.access) ,
		},
	});
	let data = await res.json();

	//
	return res;
};
export default rejectTender;
