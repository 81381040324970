export const TENDER_STAGES = [
	'Draft', //0,
	'Accepted', //1
	'Filed', //2
	'WonAndSigning', //3
	'AlternateIdAssignment',
	'InformationRequest', //4
	"WaitingForRequest ",
	'ReadyToWork', //5
	'Measurements', //6
	'PerformerAppointed',//7
	'AcceptedToWork', //8 
	'Agreement', //9
	'PrintedAndSent', //10
	'UnloadingDocuments', //11
	'Acts', //12
	'Payment', //13
	'WaitingForReport', //14
	'ReturnOfSecurity', //15
	'Finish', //16
	'Archive' //17
];

export const COMMERCIAL_STAGES = [
	'Draft', //0
	'AlternateIdAssignment', //1
	'InformationRequest', //2
	'ReadyToWork', //3
	'Measurements', //4
	'PerformerAppointed', //5
	'AcceptedToWork', //6
	'Agreement', //7
	'PrintedAndSent', //8
	'UnloadingDocuments', //9
	'Acts', //10
	'Payment', //11
	'WaitingForReport', //12
	'Finish', //13
	'Archive' //14
];

export const REJECT_STAGES = [
	'Basket',
	'Lost',
	'NotFiled',
	'NotSigned',
	'Terminated',
];

const RU_DICTIONARY = {
	//Tender/Commercial stages:
	'Draft': 'Черновик',
	'Accepted': 'Принято в работу по заявке',
	'Filed': 'Подано',
	'ContractDepartmentNumber': "Идентификационный номер",
	'WonAndSigning': 'Победа в тендере и подписание',
	'InformationRequest': 'Сбор информации',
	'ReadyToWork': 'Готово к работе',
	'Measurements': 'Замеры',
	'PerformerAppointed': 'Исполнитель назначен',
	'AcceptedToWork': 'Принято в работу',
	'Agreement': 'Согласование',
	'PrintedAndSent': 'Распечатан и отправлен',
	'UnloadingDocuments': 'Выгрузка',
	"Verification": 'Проверка',
	'Errors': 'Ошибки',
	'Acts': 'Акты',
	'Payment': 'Ожидают оплаты и получения актов',
	'ReturnOfSecurity': 'Возврат обеспечения',
	'Finish': 'Завершен',
	'Archive': 'Архив',
	//Reject stages:
	'Basket': 'Корзина',
	'Lost': 'Не выигранные',
	'NotFiled': 'Не подано',
	'NotSigned': 'Не подписан',
	'Terminated': 'Расторгнут',
	//Execution programm id's:
	'LaborExpert_3_0': 'ТрудЭксперт',
	'Attestation_5_1': 'Аттестация',
	//Assesment type id's:
	'ProductionControl': 'ПК',
	'WorkingConditionsSpecialAssesment': 'СОУТ',
	//Common:
	'None': 'Нет',
	'PaymentOrder': 'ПП',
	'BankGuarantee': 'БГ',
	'Rate': 'Тариф',

	'null': 'Нет',

	Other: "Другое",
	None: "Нет",
	DigitalAuction: "Электронный аукцион",
	RequestForQuotations: "Запрос котировок или предложений",

	'TenderId': 'ID Тендера',
	'AssesmentTypeId': 'Тип оценки',
	'HaveWorkbook': 'Рабочая тетрадь',
	'OfferSecuringMethodId': 'Обеспечение предложения',
	'ContractSecuringMethodId': 'Обеспечение контракта',
	'CurrentWorkFlowStageId': 'Текущая площадка',
	'AcceptingOffersDeadline': 'Дата окончания подачи заявок',
	'PlannedExecutionDate': 'Плановая дата исполнения',
	'PublicationDate': 'Дата публикации',
	'ConclusionContractDate': 'Дата заключения договора',
	'AuctionDate': 'Дата аукциона',
	'ContractPrice': 'Цена договора',
	DepartmentType: 'Тип проекта',
	TradingFloorName: 'Название площадки',
	StartMaxPrice: 'НМЦК',
	Price: 'Цена',
	OfferAmount: 'Наша цена',
	Description: 'Комментарий к заявке',
	FailureDescription: 'Комментарий о причине неподачи',
	OfferSecuringMethod: 'Обеспечение заявки',
	SecurityAmount: 'Сумма обеспечения заявки',
	ContractSecuringMethod: 'Обеспечение контракта',
	OfferSecurityAmount: 'Сумма обеспечения контракта',
	TradingFloorCommision: 'Комиссия площадки',
	PersonInCharge: "Ответственный",
	TradingFloorCommissionAmount: 'Сумма комиссии площадки',
	WinnerPrice: 'Цена победителя',
	WinnerId: 'Победитель',
	ExecutionProgramId: 'Программа исполнения',
	TenderDepartment: 'Сотрудник тендерного отдела',
	CommercialDepartment: 'Сотрудник коммерческого отдела',
	Laboratory: 'Сотрудник лаборатории',
	WorkerId: 'Замерщик',
	WorkspaceCount: 'Число рабочих мест',
	UnitCount: 'Число подразделений',
	ActAmount: 'Сумма по акту',
	ProjectStage: 'Этап',
	PeopleForMedicalExamination: 'Количество людей',
	AggregatorLink: 'Ссылка от агрегатора',
	InformationCollectedAt: 'Дата получения полной информации',
	AlternateId: 'Идентификационный номер',
	ExecutionDescription: 'Комментарий к исполнению',
	AdditionalCustomerName: 'Дополнительное наименование',
	SummaryReportDate: 'Дата итогового протокола',
	OperationTypeId: 'Вид процедуры',
	Regions: 'Регионы',
	LegalBasesId: 'Закон закупки',
	HasTradingRobot: 'Кто отыгрывает',
	InformationCollectionDate: 'Дата получения информации',
	ActDate: 'Дата акта',
	AcceptedToWorkDate: 'Дата приема в работу',
	SentDate: 'Дата отправки',
	UnloadingDate: 'Дата выгрузки',
	MeasurementsDate: 'Дата замеров',
	PerformerAppointedDate: 'Дата назначения исполнителя',
	ApprovalsDate: 'Дата согласования',
	TitleSheetSignDate: 'Дата подписания титульного листа',
	SendingForApprovalDate: 'Дата отправки на согласование',
	ReportSendingDate: 'Дата отправки отчета',
	InformationReceiptDate: 'Дата получение информации',
	AdditionalName: "Доп.наименование",
	ContactData: 'Контактные данные',
	'DepartmentId': 'Отдел заключения договора',
	PaidDate: 'Дата оплаты',
	ActHasReached: 'Акт получен?',

	'false': 'Нет',
	'true': 'Да',

	'DigitalAuction': 'Электронный аукцион',
	'RequestForQuotations': 'Запрос котировок или предложений',
	'Other': 'Прочее',

	'Gov': '44 ФЗ',
	'PartialGov': '223 ФЗ',

	'SummaryReportDate': 'Дата итогового протокола',

	'TenderWorkFlow': 'Тендерный',
	'CommercialWorkFlow': 'Коммерческий',
	'MeasurementStatusId': 'Замеры',
	'NotAssigned': 'Не назначены',
	'NotRequired': 'Не требуются',
	'Assigned': 'Назначены',
	'Complited': 'Проведен',

  "AssessmentTypeId": 'Тип оценки',
  ContractNumber: "Номер договора",
  Id: 'ID проекта',
  ManagerINN: 'ИНН организатора',
  CustomerINN: "ИНН заказчика",
  PublicationDate: 'Дата публикации',
  InformationRequestDate: 'Дата запроса информации',
  ProfessionalRisks : "ОПР",
  PersonInChargeLastName: "Ответственный",
  AcceptingOffersDeadline: 'Дата окончания подачи заявки',
  StartMaxPrice: 'НМЦК',
  Region: 'Регион',
  User: 'Ответственный',
  Eis: 'ЕИС (ID)',
  OurPrice: 'Наша цена',
  Name: 'Доп. наименование',

	0: ' - ',
}

export const translateText = (text) => RU_DICTIONARY[text] || text;

