import { useState, useEffect, memo, useContext } from "react";
import Sidebar from "components/Sidebar";
import { Select } from "components/Select/Select";
import { useNavigate } from "react-router-dom";
import Asterisk from "components/Asterisk";
import { isAdmin, isCommercialUser, isTenderUser } from "utils/defineUser";
import { getCustomerNameByINN } from "utils/getCustomerNameByINN";
import { validate } from "shared/validate";
import { DatePicker } from "components/DateInput/DateInput";
import { buildValidation } from "shared/buildValidation";
import AuthContext from "context/AuthContext";
import { fetchContractByID } from "entities/contracts/service/fetchContractById";
import ContractContext from "context/ContractContext";
import { createContract } from "entities/contracts/service/createContract";
import { editContract } from "entities/contracts/service/editContract";
import Spinner from "components/Spinner";
import { TextInput } from "components/TextInput/TextInput";
import { Checkbox, Checkboxes } from "components/Checkboxes/Checkboxes";
import { fetchTenderById } from "utils/fetchTenderById";
import { Button } from "shared/ui/Button/Button";
import cross from "assets/images/cross-rounded.svg";
import { Grommet, Grid, Box } from "grommet";
import SelectRegions from "components/common/SelectRegions";
import { ContractConclusionDepartmentChoiceModal } from "entities/contracts/modals/ContractConclusionDepartmentChoiceModal";
import { Text } from "shared/ui/Text/Text";
import { dateToString } from "shared/formatDate";
import { getAgents } from "utils/getAgents";
import { getBranchOffices } from "utils/getBranchOffices";
import { fetchAccountInfo } from "utils/fetchAccountInfo";

const ChangeContracts = memo(({ type, contractID }) => {
  const { updateToken } = useContext(AuthContext);

  const navigate = useNavigate();

  const {
    commercialEmployees,
    tenderEmployees,
    getEmployees,
    tempContract,
    setTempContract,
    updateTempContract,
    initTempContract,
  } = useContext(ContractContext);

  const [isContractLoading, setIsContractLoading] = useState(false);
  console.log(tempContract);
  const [isSearching, setIsSearching] = useState(false);
  const [customerNameViaINN, setCustomerNameViaINN] = useState();

  const [isNotariusChecked, setIsNotariusChecked] = useState(false);
  const [isNullPK, setIsNullPK] = useState(false);
  const [isNullOPR, setIsNullOPR] = useState(false);
  const [isNullSOUT, setIsNullSOUT] = useState(false);
  const [workOnRequest, setWorkOnRequest] = useState(false);
  const [orgs, setOrgs] = useState([]);
  const [branchOffices, setBranchOffices] = useState([]);

  const changeContracts = (contract, type) => {
    if (type === "add") createContract(contract, () => navigate(-1));
    if (type === "edit") editContract(contract, () => navigate(-1));
  };

  useEffect(() => {
    return () => initTempContract();
  }, []);

  useEffect(() => {
    if (type === "edit") {
      if (tempContract?.tenderId) {
        fetchTenderById(tempContract.tenderId)
          .then((res) => {
            setTenderData({
              name: res.manager.name,
              inn: res.manager.inn,
            });
          })
          .catch((e) => {
            setTenderData({
              name: null,
              inn: null,
            });
          });
      }
    }
  }, [tempContract]);

  const getContractByID = () => {
    setIsContractLoading(true);
    fetchContractByID(contractID).then((res) => {
      if (res === 401) updateToken();
      console.log(res);
      setTempContract({
        ...res,
        projects: null,
      });
      setWorkOnRequest(res?.workOnRequest);
      setCustomerNameViaINN(res?.customer?.name);
      setIsContractLoading(false);
    });
  };

  useEffect(() => {
    if (type) {
      if (type === "edit") {
        getContractByID();
      }
    }
  }, []);

  useEffect(() => {
    getEmployees();
    getAgents(setOrgs);
  }, []);

  useEffect(() => {
    if (!tempContract?.organisationId) {
      setTempContract({
        ...tempContract,
        organisationId: tempContract?.personInCharge?.organizationId,
        branchOfficeId: tempContract?.personInCharge?.branchOfficeId,
      });
      getBranchOffices(
        tempContract?.personInCharge?.organizationId,
        setBranchOffices
      );
    } else {
      getBranchOffices(tempContract?.organisationId, setBranchOffices);
    }
  }, [tempContract?.organisationId]);

  const [displayErrors, setDisplayErrors] = useState(false);

  const customerNameHandler = (name, inn) => {
    updateTempContract("customer", {
      inn: inn,
      name: name,
    });
  };

  const fetchDaData = (inn) => {
    setIsSearching(true);
    updateTempContract("customer", { ...tempContract.customer, inn: inn });
    if (inn.length === 10 || inn.length === 12) {
      getCustomerNameByINN(inn).then((res) => {
        setCustomerNameViaINN(res);
        customerNameHandler(res, inn);
        setIsSearching(false);
      });
    }
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    updateTempContract(name, value);
  };
  const handleSubmit = (e) => {
    let conditions = buildValidation({
      type,
      stage: tempContract?.workFlow?.workFlowStatusId,
      isNotariusChecked: isNotariusChecked,
      assesmentTypesLength: tempContract.assesmentTypes.length,
      customerINN: tempContract?.customer?.inn,
      personInChargeId: tempContract?.personInChargeId,
      customerNameViaINN: customerNameViaINN,
      customer: tempContract?.customer,
      departments: tempContract.departments,
      isOPRorPK: () => {
        tempContract?.assesmentTypes.forEach((el) => {
          if (el.id === "ProductionControl" || el.id === "ProfessionalRisks") {
            return true;
          }
          return true;
        });
      },
    });
    validate(e, conditions, () => {
      changeContracts(
        {
          ...tempContract,
          workOnRequest: workOnRequest,
          workspaceCount: {
            sout: tempContract?.workspaceCount?.sout
              ? parseInt(tempContract?.workspaceCount?.sout)
              : null,
            pk: tempContract?.workspaceCount?.pk
              ? parseInt(tempContract?.workspaceCount?.pk)
              : null,
            opr: tempContract?.workspaceCount?.opr
              ? parseInt(tempContract?.workspaceCount?.opr)
              : null,
          },
          personInChargeId: tempContract?.personInChargeId,
          personInCharge: null,
          // workers: lockUser?.id !== "null" ? [lockUser] : [],
          measuringBlocks: null,
          projects: null,
          conclusionContractDate: dateToString(
            tempContract.conclusionContractDate
          ),
          plannedExecutionDate: dateToString(tempContract.plannedExecutionDate),
        },
        type
      );
    });
  };
  const [tenderData, setTenderData] = useState({
    name: "",
    inn: "",
  });

  const getTenderById = (tenderId) => {
    updateTempContract("tenderId", tenderId);
    if (tenderId) {
      fetchTenderById(tenderId)
        .then((res) => {
          setTenderData({
            name: res.manager.name,
            inn: res.manager.inn,
          });
        })
        .catch((e) => {
          setTenderData({
            name: null,
            inn: null,
          });
        });
    } else {
      setTenderData({
        name: null,
        inn: null,
      });
    }
  };
  const [departmentInputValue, setDepartmentInputValue] = useState({
    departmentNumber: "",
    kpp: "",
  });

  const clearFields = () => {
    setDepartmentInputValue({
      kpp: "",
      departmentNumber: "",
    });
  };

  const addDepartment = (e) => {
    e.preventDefault();
    if (!departmentInputValue.kpp || !departmentInputValue.departmentNumber)
      return;
    let newItem = {
      kpp: Number(departmentInputValue.kpp),
      departmentNumber: Number(departmentInputValue.departmentNumber),
    };
    let indexOfNewItem = tempContract.departments.findIndex(
      (x) =>
        x.kpp === newItem.kpp && x.departmentNumber === newItem.departmentNumber
    );
    if (indexOfNewItem !== -1) {
      alert(
        `Такой департамент уже введён! Он под номером ${indexOfNewItem + 1}`
      );
      return;
    }
    setTempContract({
      ...tempContract,
      departments: [...tempContract.departments, newItem],
    });
    clearFields();
  };

  const deleteDepartment = (kpp, departmentNumber) => {
    setTempContract({
      ...tempContract,
      departments: tempContract.departments.filter(
        (x) => x.kpp !== kpp || x.departmentNumber !== departmentNumber
      ),
    });
  };

  return (
    <>
      {!tempContract?.contractConclusionDepartmentId && !isContractLoading ? (
        <ContractConclusionDepartmentChoiceModal
          close={() => navigate("/sout/contracts/Draft")}
        />
      ) : (
        <main className="workspace">
          <Sidebar />
          <div className="workspace__content">
            <section className="workspace__wrapper">
              <h2 className="title workspace__page-title">
                <span
                  style={{
                    fontSize: "22px",
                    lineHeight: "1px",
                    marginRight: "10px",
                  }}
                >
                  &#10010;
                </span>
                {type === "add" ? "Добавить" : "Редактировать"}
                &nbsp;договор
              </h2>
              {isContractLoading ? (
                <Spinner />
              ) : (
                <form
                  className={`form contracts__form ${
                    displayErrors ? "form--isValid" : ""
                  }`}
                  onSubmit={(e) => handleSubmit(e)}
                >
                  <fieldset className="workspace__ensure-wrapper contracts__ensure-wrapper">
                    <Grommet style={{ fontSize: 16 }}>
                      <Grid
                        width="1000px"
                        columns="320px"
                        // rows="76px"
                        gap="20px"
                        fontSize="16px"
                      >
                        <Box gap="10px">
                          <div
                            className="input-wrapper"
                            style={{
                              display: "flex",
                              alignItems: "end",
                            }}
                          >
                            {tempContract.contractConclusionDepartmentId ===
                              "TenderDepartment" && (
                              <TextInput
                                value={tempContract.tenderId}
                                alignLabel="left"
                                labelHeight="24px"
                                label="ID тендера"
                                onChange={(e) => {
                                  getTenderById(e.target.value);
                                }}
                                type="number"
                                // disabled={!isAdmin()}
                                disabled={type === "edit"}
                                required={type === "add"}
                                name="tenderId"
                                style={{
                                  width: "100px",
                                  marginBottom: 13,
                                  marginRight: "20px",
                                }}
                              />
                            )}
                            <Checkboxes
                              label="Тип оценки"
                              marginTop={"-15px"}
                              values={[
                                ["WorkingConditionsSpecialAssesment", "СОУТ"],
                                ["ProductionControl", "ПК"],
                                ["ProfessionalRisks", "ОПР"],
                              ]}
                              data={tempContract.assesmentTypes}
                              isChecked={(key) => {
                                return (
                                  tempContract.assesmentTypes.findIndex(
                                    (x) => x.id === key
                                  ) !== -1
                                );
                              }}
                              updateValues={(key) => {
                                if (
                                  tempContract.assesmentTypes.findIndex(
                                    (x) => x.id === key
                                  ) !== -1
                                ) {
                                  updateTempContract(
                                    "assesmentTypes",
                                    tempContract.assesmentTypes.filter(
                                      (x) => x.id !== key
                                    )
                                  );
                                } else {
                                  updateTempContract("assesmentTypes", [
                                    ...tempContract.assesmentTypes,
                                    { id: key },
                                  ]);
                                }
                              }}
                              errorMessage="Выберите хотя бы один тип"
                            />
                          </div>
                          {isAdmin() && (
                            <div
                              style={{
                                margin: "-18px 0 10px 0",
                                textAlign: "start",
                                fontSize: 16,
                              }}
                            >
                              <Checkbox
                                label={"Работа по заявке"}
                                name={"workOnRequest"}
                                onChange={() =>
                                  setWorkOnRequest(!workOnRequest)
                                }
                                checked={workOnRequest}
                              />
                            </div>
                          )}
                          <TextInput
                            type="text"
                            label="ИНН заказчика"
                            value={tempContract?.customer?.inn}
                            alignLabel="left"
                            labelHeight="24px"
                            onChange={(e) => fetchDaData(e.target.value)}
                            disabled={type === "edit"}
                            required={type === "add"}
                            pattern="^(\d{10}|\d{12})$"
                            maxLength="12"
                            name="customerINN"
                            className={"innMarginTop"}
                            errorMessage="Введите 10 или 12 цифр"
                          />
                          <label
                            className="workspace__input-title required"
                            style={{ maxWidth: "380px", marginTop: "-10px" }}
                          >
                            <p>
                              Наименование заказчика
                              {type === "add" && <Asterisk />}
                              {type === "add" && (
                                <input
                                  style={{
                                    marginRight: "5px",
                                    marginLeft: "10px",
                                    cursor: "pointer",
                                  }}
                                  type="checkbox"
                                  name="isNotarius"
                                  onChange={(e) =>
                                    setIsNotariusChecked(e.target.checked)
                                  }
                                  checked={isNotariusChecked}
                                />
                              )}
                            </p>
                            {type === "edit" ? (
                              <p>
                                {tempContract.contractConclusionDepartmentId ===
                                "CommercialDepartment"
                                  ? customerNameViaINN
                                  : tempContract?.customer?.name}
                              </p>
                            ) : isNotariusChecked ? (
                              <input
                                className="input"
                                type="text"
                                value={tempContract?.customer?.name}
                                onChange={(e) =>
                                  updateTempContract("customer", {
                                    ...tempContract.customer,
                                    name: e.target.value,
                                  })
                                }
                                required
                              />
                            ) : customerNameViaINN === undefined ? (
                              <p>Введите ИНН заказчика...</p>
                            ) : isSearching ? (
                              <p>Поиск по базе данных...</p>
                            ) : customerNameViaINN !== null ? (
                              <p>{customerNameViaINN}</p>
                            ) : (
                              <span
                                className="error-message visible"
                                style={{ marginBottom: 5 }}
                              >
                                Заказчик не найден!
                              </span>
                            )}

                            {tempContract.contractConclusionDepartmentId ===
                              "TenderDepartment" && (
                              <>
                                <p style={{ marginTop: "20px" }}>
                                  Наименование организатора
                                </p>
                                <p>
                                  {tenderData.name || (
                                    <p>Введите ID тендера...</p>
                                  )}
                                </p>
                                <p style={{ marginTop: "10px" }}>
                                  ИНН организатора
                                </p>
                                <p>
                                  {tenderData.inn || (
                                    <p>Введите ID тендера...</p>
                                  )}
                                </p>
                              </>
                            )}
                          </label>
                          <TextInput
                            label="Доп. наименование"
                            type="text"
                            name="additionalName"
                            alignLabel="left"
                            labelHeight="24px"
                            value={tempContract.additionalName}
                            onChange={(e) => onChange(e)}
                          />

                          <label className="workspace__input-title contracts__input-title">
                            Контактные данные
                            <textarea
                              style={{
                                resize: "none",
                                position: "sticky",
                                zIndex: 1,
                              }}
                              className="textarea"
                              cols="50"
                              rows="5"
                              name="contactData"
                              value={tempContract?.contactData}
                              onChange={(e) => onChange(e)}
                            />
                          </label>
                          <div style={{ marginTop: -20 }}>
                            <SelectRegions
                              required={true}
                              label={"Регион:"}
                              errorMessage={"Выьерите регион"}
                              regions={tempContract.regions}
                              updateRegions={(updatedRegions) =>
                                updateTempContract("regions", updatedRegions)
                              }
                            />
                          </div>
                        </Box>
                        <Box gap="5px">
                          {tempContract.contractConclusionDepartmentId !==
                            "TenderDepartment" &&
                            type === "add" && (
                              <Checkbox
                                name={"withoutInnerNumber"}
                                label={"Без внутреннего номера"}
                                checked={tempContract?.withoutInnerNumber}
                                onChange={() =>
                                  setTempContract({
                                    ...tempContract,
                                    withoutInnerNumber:
                                      !tempContract?.withoutInnerNumber,
                                  })
                                }
                              />
                            )}
                          <TextInput
                            label="Номер договора"
                            type="text"
                            alignLabel="left"
                            labelHeight="24px"
                            name="contractNumber"
                            value={tempContract.contractNumber}
                            onChange={(e) => onChange(e)}
                            required={
                              tempContract.contractConclusionDepartmentId ==
                              "TenderDepartment"
                                ? true
                                : false
                            }
                            errorMessage="Введите номер договора"
                          />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div style={{ display: "flex" }}>
                              <TextInput
                                label="Число РМ СОУТ"
                                type="text"
                                disabled={
                                  !tempContract?.assesmentTypes.find(
                                    (item) =>
                                      item?.id ===
                                      "WorkingConditionsSpecialAssesment"
                                  )
                                }
                                pattern={"^[ 0-9]+$"}
                                alignLabel="left"
                                required={
                                  !isNullSOUT &&
                                  tempContract?.assesmentTypes.find(
                                    (item) =>
                                      item?.id ===
                                      "WorkingConditionsSpecialAssesment"
                                  )
                                }
                                labelHeight="24px"
                                className={"contracts__sout-size"}
                                name="workspaceCount"
                                value={tempContract?.workspaceCount?.sout}
                                onChange={(e) =>
                                  setTempContract({
                                    ...tempContract,
                                    workspaceCount: {
                                      ...tempContract?.workspaceCount,
                                      sout: e.target.value,
                                    },
                                  })
                                }
                              />
                              <label
                                style={{
                                  marginTop: 30,
                                  marginLeft: 5,
                                  width: 50,
                                }}
                              >
                                <input
                                  type="checkbox"
                                  checked={isNullSOUT}
                                  onClick={() => setIsNullSOUT(!isNullSOUT)}
                                />
                                <span
                                  style={{
                                    fontFamily: "monospace",
                                    marginLeft: 5,
                                    fontSize: 16,
                                  }}
                                >
                                  X
                                </span>
                              </label>
                            </div>
                            <div style={{ display: "flex" }}>
                              <TextInput
                                label="Число РМ ОПР"
                                type="text"
                                disabled={
                                  !tempContract?.assesmentTypes.find(
                                    (item) => item?.id == "ProfessionalRisks"
                                  )
                                }
                                alignLabel="left"
                                required={
                                  !isNullOPR &&
                                  tempContract?.assesmentTypes.find(
                                    (item) => item?.id === "ProfessionalRisks"
                                  )
                                }
                                labelHeight="24px"
                                name=""
                                className={"contracts__RM-size"}
                                value={tempContract.workspaceCount?.opr}
                                onChange={(e) =>
                                  setTempContract({
                                    ...tempContract,
                                    workspaceCount: {
                                      ...tempContract?.workspaceCount,
                                      opr: e.target.value,
                                    },
                                  })
                                }
                              />
                              <label
                                style={{
                                  marginTop: 30,
                                  marginLeft: 5,
                                  width: 50,
                                }}
                              >
                                <input
                                  type="checkbox"
                                  checked={isNullOPR}
                                  onClick={() => setIsNullOPR(!isNullOPR)}
                                />
                                <span
                                  style={{
                                    fontFamily: "monospace",
                                    marginLeft: 5,
                                    fontSize: 16,
                                  }}
                                >
                                  X
                                </span>
                              </label>
                            </div>
                          </div>
                          <div style={{ display: "flex" }}>
                            <TextInput
                              label="Число РМ ПК"
                              type="text"
                              alignLabel="left"
                              labelHeight="24px"
                              required={
                                !isNullPK &&
                                tempContract?.assesmentTypes.find(
                                  (item) => item?.id === "ProductionControl"
                                )
                              }
                              disabled={
                                !tempContract?.assesmentTypes.find(
                                  (item) => item?.id === "ProductionControl"
                                )
                              }
                              className={"contracts__input-size"}
                              name=""
                              value={tempContract.workspaceCount?.pk}
                              onChange={(e) =>
                                setTempContract({
                                  ...tempContract,
                                  workspaceCount: {
                                    ...tempContract?.workspaceCount,
                                    pk: e.target.value,
                                  },
                                })
                              }
                            />
                            <label
                              style={{
                                marginTop: 30,
                                marginLeft: 5,
                                width: 50,
                              }}
                            >
                              <input
                                type="checkbox"
                                checked={isNullPK}
                                onClick={() => setIsNullPK(!isNullPK)}
                              />
                              <span
                                style={{
                                  fontFamily: "monospace",
                                  marginLeft: 5,
                                  fontSize: 16,
                                }}
                              >
                                X
                              </span>
                            </label>
                          </div>

                          <div className="contracts__datepicker-edit__wrapper">
                            <label className="workspace__input-title contracts__input-title required">
                              <div className="contracts__input-diapason__title">
                                <span>Дата заключения договора</span>
                                <Asterisk />
                              </div>
                              <DatePicker
                                className="input"
                                name="conclusionContractDate"
                                value={tempContract.conclusionContractDate}
                                onChange={(date) =>
                                  setTempContract({
                                    ...tempContract,
                                    conclusionContractDate: date,
                                  })
                                }
                                required={true}
                                min={new Date("01.01.2000")}
                              />
                              <span className="error-message">
                                Введите дату заключения
                              </span>
                            </label>
                            <label className="workspace__input-title contracts__input-title required">
                              <div className="contracts__input-diapason__title">
                                <span>Плановая дата исполнения</span>
                                <Asterisk />
                              </div>
                              <DatePicker
                                className="input"
                                name="conclusionContractDate"
                                value={tempContract.plannedExecutionDate}
                                onChange={(date) =>
                                  setTempContract({
                                    ...tempContract,
                                    plannedExecutionDate: date,
                                  })
                                }
                                required={true}
                                min={new Date("01.01.2000")}
                              />
                              <span className="error-message">
                                Введите дату исполнения
                              </span>
                            </label>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <TextInput
                              label="Цена"
                              type="number"
                              alignLabel="left"
                              labelHeight="24px"
                              className={"contracts__input-size"}
                              name="price"
                              value={tempContract?.price}
                              onChange={(e) => onChange(e)}
                            />
                            {!isCommercialUser() && (
                              <TextInput
                                label="Сумма по акту"
                                type="number"
                                pat
                                alignLabel="left"
                                labelHeight="24px"
                                className={"contracts__input-size"}
                                name="actSum"
                                value={tempContract?.act?.sum}
                                onChange={(e) =>
                                  setTempContract({
                                    ...tempContract,
                                    act: {
                                      ...tempContract?.act,
                                      sum: e.target.value,
                                    },
                                  })
                                }
                              />
                            )}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            {!isCommercialUser() && (
                              <label
                                style={{ width: "48%" }}
                                className="workspace__input-title contracts__input-title required"
                              >
                                <div className="contracts__input-diapason__title">
                                  <span>Дата акта</span>
                                </div>
                                <DatePicker
                                  className="input"
                                  name=""
                                  value={tempContract?.act?.date || null}
                                  onChange={(date) =>
                                    setTempContract({
                                      ...tempContract,
                                      act: { ...tempContract?.act, date: date },
                                    })
                                  }
                                  min={new Date("01.01.2000")}
                                />
                              </label>
                            )}
                          </div>
                        </Box>
                        <Box gap="5px">
                          {!(isTenderUser() || isCommercialUser()) && (
                            <>
                              <Select
                                className="workspace__input-title"
                                required={true}
                                label={
                                  tempContract.contractConclusionDepartmentId ===
                                  "TenderDepartment"
                                    ? "Тендерный отдел"
                                    : "Коммерческий отдел"
                                }
                                name="personInChargeId"
                                dataSrc="server"
                                data={
                                  tempContract.contractConclusionDepartmentId ===
                                  "TenderDepartment"
                                    ? tenderEmployees
                                    : commercialEmployees
                                }
                                value={tempContract?.personInChargeId}
                                onChange={onChange}
                              />
                              <Select
                                className="workspace__input-title"
                                required={true}
                                label="Организация"
                                name="organisationId"
                                dataSrc="server"
                                data={orgs}
                                value={tempContract?.organisationId}
                                onChange={onChange}
                              />
                              <Select
                                className="workspace__input-title"
                                required={true}
                                label="Филиал"
                                name="branchOfficeId"
                                dataSrc="server"
                                data={branchOffices}
                                value={tempContract?.branchOfficeId}
                                onChange={onChange}
                              />
                            </>
                          )}
                          <label className="workspace__input-title contracts__input-title">
                            Комментарий к исполнению
                            <textarea
                              style={{ resize: "none" }}
                              className="textarea"
                              cols="50"
                              rows="5"
                              name="executionDescription"
                              value={tempContract?.executionDescription}
                              onChange={(e) => onChange(e)}
                              // readOnly={type === "edit"}
                            />
                          </label>
                        </Box>
                      </Grid>
                    </Grommet>
                  </fieldset>
                  <div
                    className="line"
                    style={{
                      borderBottom: "2px solid #000",
                      margin: "10px 0",
                    }}
                  ></div>
                  {!isCommercialUser() && (
                    <div className="departments" style={{ width: "100%" }}>
                      <div className="departments__count">
                        <h3
                          style={{ textAlign: "center", marginBottom: "10px" }}
                        >
                          Количество подразделений:{" "}
                          {tempContract.departments.length}
                        </h3>
                      </div>
                      <div
                        className="depertments__fields"
                        style={{
                          display: "flex",
                          justifyContent: "space-evenly",
                          alignItems: "end",
                          flexWrap: "wrap",
                        }}
                      >
                        <TextInput
                          label="КПП"
                          alignLabel="left"
                          labelHeight="24px"
                          value={departmentInputValue.kpp}
                          onChange={(e) =>
                            setDepartmentInputValue({
                              ...departmentInputValue,
                              kpp: e.target.value,
                            })
                          }
                          style={{ width: "220px" }}
                          type="number"
                          pattern={"^[ 0-9]+$"}
                        />
                        <TextInput
                          label="Идентификационный номер"
                          alignLabel="left"
                          labelHeight="24px"
                          value={departmentInputValue.departmentNumber}
                          onChange={(e) =>
                            setDepartmentInputValue({
                              ...departmentInputValue,
                              departmentNumber: e.target.value,
                            })
                          }
                          style={{ width: "220px" }}
                          type="number"
                          pattern={"^[ 0-9]+$"}
                        />
                        <Button color="blue" onClick={(e) => addDepartment(e)}>
                          Добавить
                        </Button>
                      </div>
                      <div
                        className="departments__list"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                          margin: "10px auto 20px auto",
                        }}
                      >
                        {tempContract.departments.map((item, index) => (
                          <div
                            className="departments__item"
                            style={{
                              display: "flex",
                              justifyContent: "space-evenly",
                              width: "100%",
                            }}
                          >
                            <div
                              style={{
                                width: "220px",
                                borderRadius: "4px",
                                background: "rgba(217, 217, 217, 0.75)",
                                marginBottom: "10px",
                                padding: "3px 3px",
                                boxSizing: "border-box",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <span>{index + 1})</span>
                              {item.kpp}
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "220px",
                                borderRadius: "4px",
                                background: "rgba(217, 217, 217, 0.75)",
                                marginBottom: "10px",
                                padding: "3px 3px",
                                boxSizing: "border-box",
                              }}
                            >
                              {item.departmentNumber}
                              <img
                                onClick={() =>
                                  deleteDepartment(
                                    item.kpp,
                                    item.departmentNumber
                                  )
                                }
                                alt="cross"
                                src={cross}
                                style={{ cursor: "pointer" }}
                              />
                            </div>
                            <div
                              style={{ width: "124px", height: "32px" }}
                            ></div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  <div
                    className="buttons"
                    style={{
                      display: "flex",
                      width: "300px",
                      justifyContent: "space-between",
                      margin: "auto",
                    }}
                  >
                    <button
                      className="button button--blue"
                      type="submit"
                      onClick={() => {
                        setDisplayErrors(true);
                      }}
                    >
                      {type === "add" ? "Добавить" : "Сохранить"}
                    </button>
                    <button
                      className="button button--blue"
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/sout/contracts/Draft");
                      }}
                    >
                      Отмена
                    </button>
                  </div>
                </form>
              )}
            </section>
          </div>
        </main>
      )}
    </>
  );
});

export default ChangeContracts;
