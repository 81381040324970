export const AccessRights = {
  admin: {
    canChange: ["tender", "contract", "project", "location"],
    tender: {
      Draft: {
        prev: true,
      },
      Estimated: null,
      Approved: null,
      AcceptedToWork: null,
      Filed: null,
      WonAndSigning: null,
      Finish: {
        next: false,
        reject: false,
      },
      Archive: {
        next: true,
        reject: true,
      },
      NotFiled: {
        next: true,
        reject: true,
      },
      Lost: {
        next: true,
        reject: true,
      },
      NotSigned: {
        next: true,
        reject: true,
      },
      Basket: {
        next: true,
        reject: true,
      },
    },
    contract: {
      suspend: true,
      edit: ["Suspended", "Hung",],
      trash: ["Archive", "Terminated"],
      info: [],
      movePrev: ["Draft"],
      moveNext: ["Archive", "Basket", "Terminated", "Suspended", "Hung",],
      documents: [],
    },
    project: {
      ReadyToWork: {},
      OrganizationsAnalysis: {
        prev: true,
      },
      AcceptedToWork: {},
      Agreement: {},
      PrintedAndSent: {},
      UnloadingDocuments: {},
      Finish: {},
      InformationRequest: {},
      ExecutorsAnalysis: {},
      Archive: {
        next: true,
        reject: true,
      },
      Terminated: {
        next: true,
        prev: true,
        reject: true,
      },
      Lost: {
        next: true,
        reject: true,
      },
      NotSigned: {
        next: true,
        reject: true,
      },
      Basket: {
        next: true,
      },
      Suspended: {
        next: true,
        edit: true,
      },
      Hung: {
        next: true,
        edit: true,
        reject: true
      }
    },
    location: {
      canCreate: true,
      edit: (location, locType) =>
        location?.workFlow?.workFlowStatusId === "Suspended",
      trash: (location, locType) =>
        location?.workFlow?.workFlowStatusId === "Archive" ||
        location?.workFlow?.workFlowStatusId === "Basket" ||
        location?.workFlow?.workFlowStatusId === "Terminated",
      info: (location, locTyp) => false,
      movePrev: (location, locType) =>
        location?.workFlow?.workFlowStatusId === "Draft" ||
        location?.workFlow?.workFlowStatusId === "Suspended" ||
        location.measuringBlockTypeId === "PotentialMeasuringBlock" ||
        locType === "Potential",
      moveNext: (location, locType) =>
        location?.workFlow?.workFlowStatusId === "Archive" ||
        location?.workFlow?.workFlowStatusId === "Basket" ||
        location?.workFlow?.workFlowStatusId === "Suspended" ||
        location?.workFlow?.workFlowStatusId === "Terminated",

      documents: (location, locTyp) =>
        location?.workFlow?.workFlowStatusId === "Suspended",
    },
  },
  lab_chief: {
    canChange: ["tender", "location"],
    tender: {
      Draft: {
        prev: true,
      },
      Estimated: {
        next: true,
        reject: true,
      },
      Approved: {
        next: true,
        prev: true,
        reject: true,
      },
      AcceptedToWork: {
        next: true,
        prev: true,
        reject: true,
      },
      Filed: {
        prev: true,
        next: true,
        reject: true,
      },
      WonAndSigning: {
        next: true,
        prev: true,
        reject: true,
      },
      Finish: {
        next: true,
        prev: true,
        reject: true,
      },
      Archive: {
        next: true,
        reject: true,
      },
      NotFiled: {
        next: true,
        reject: true,
      },
      Lost: {
        next: true,
        reject: true,
      },
      NotSigned: {
        next: true,
        reject: true,
      },
      Basket: {
        next: true,
        prev: true,
      },
    },
    contract: {
      suspend: true,
      edit: [],
      trash: [
        "Draft",
        "Acts",
        "WaitingForUnloadingDocuments",
        "Finish",
        "Archive",
        "Terminated",
        "Basket",
      ],
      info: ["Draft", "Basket"],
      movePrev: [
        "Draft",
        "IdAssigning",
        "InformationRequest",
        "PreparationAndExecution",
        "SigningAndCollectingInformation",
        "Acts",
        "WaitingForUnloadingDocuments",
        "Finish",
        "Archive",
        "Basket",
      ],
      moveNext: [
        "Draft",
        "IdAssigning",
        "ProjectsCreation",
        "Suspended",
        "SigningAndCollectingInformation",
        "PreparationAndExecution",
        "Acts",
        "WaitingForUnloadingDocuments",
        "Finish",
        "Archive",
        "Terminated",
        "Basket",
      ],
      documents: ["Draft", "Basket"],
    },
    project: {
      OrganizationsAnalysis: {
        prev: true,
        next: true,
        edit: true,
        reject: true,
      },
      ExecutorsAnalysis: {
        prev: true,
        next: true,
        edit: true,
        reject: true,
      },
      InformationRequest: {
        prev: true,
        next: true,
        edit: true,
        reject: true,
      },
      ReadyToWork: {
        prev: true,
        next: true,
        edit: true,
        reject: true,
      },
      AcceptedToWork: {
        prev: true,
        next: true,
        edit: true,
        reject: true,
      },
      Agreement: {
        prev: true,
        next: true,
        edit: true,
        reject: true,
      },
      PrintedAndSent: {
        prev: true,
        next: true,
        edit: true,
        reject: true,
      },
      Verification: {
        prev: true,
        next: true,
        edit: true,
        reject: true,
      },
      UnloadingDocuments: {
        prev: true,
        next: true,
        edit: true,
        reject: true,
      },
      Finish: {
        prev: true,
        next: true,
        edit: true,
        reject: true,
      },
      Archive: {
        prev: true,
        next: true,
        edit: true,
        reject: true,
      },
      Errors: {
        prev: true,
        next: true,
        edit: true,
        reject: true,
      },
      Terminated: {
        prev: true,
        next: true,
        edit: true,
        reject: true,
      },
      Basket: {
        prev: true,
        next: true,
        edit: true,
        reject: true,
      },
      Suspended: {
        next: true,
        edit: true,
      },
    },
    location: {
      canCreate: true,
      edit: (location, locType) =>
        location.measuringBlockTypeId === "PotentialMeasuringBlock" ||
        location?.workFlow?.workFlowStatusId === "Archive" ||
        location?.workFlow?.workFlowStatusId === "Finish" ||
        location?.workFlow?.workFlowStatusId === "Basket" ||
        location?.workFlow?.workFlowStatusId === "Suspended" ||
        locType === "Potential",
      trash: (location, locType) =>
        location?.workFlow?.workFlowStatusId === "Archive" ||
        location?.workFlow?.workFlowStatusId === "Finish" ||
        location?.workFlow?.workFlowStatusId === "Basket" ||
        location?.workFlow?.workFlowStatusId === "Terminated",
      info: (location, locType) =>
        location?.workFlow?.workFlowStatusId === "Suspended",
      movePrev: (location, locType) =>
        location?.workFlow?.workFlowStatusId === "Draft" ||
        location?.workFlow?.workFlowStatusId === "Unassigned" ||
        location.measuringBlockTypeId === "PotentialMeasuringBlock" ||
        locType === "Potential",
      moveNext: (location, locType) =>
        location?.workFlow?.workFlowStatusId === "Archive" ||
        location?.workFlow?.workFlowStatusId === "Finish" ||
        location?.workFlow?.workFlowStatusId === "Basket" ||
        location?.workFlow?.workFlowStatusId === "Suspended" ||
        location?.workFlow?.workFlowStatusId === "Terminated" ||
        location.measuringBlockTypeId === "PotentialMeasuringBlock" ||
        locType === "Potential",
      documents: (location, locType) =>
        location?.workFlow?.workFlowStatusId === "Suspended",
    },
  },
  lab_expert: {
    canChange: ["project"],
    tender: {
      Draft: {
        edit: true,
        next: true,
        prev: true,
        reject: true,
      },
      Estimated: {
        edit: true,
        next: true,
        prev: true,
        reject: true,
      },
      Approved: {
        edit: true,
        next: true,
        prev: true,
        reject: true,
      },
      AcceptedToWork: {
        edit: true,
        next: true,
        prev: true,
        reject: true,
      },
      Filed: {
        edit: true,
        next: true,
        prev: true,
        reject: true,
      },
      WonAndSigning: {
        edit: true,
        next: true,
        prev: true,
        reject: true,
      },
      Finish: {
        edit: true,
        next: true,
        prev: true,
        reject: true,
      },
      Archive: {
        edit: true,
        next: true,
        prev: true,
        reject: true,
      },
      NotFiled: {
        edit: true,
        next: true,
        prev: true,
        reject: true,
      },
      Lost: {
        edit: true,
        next: true,
        prev: true,
        reject: true,
      },
      NotSigned: {
        edit: true,
        next: true,
        prev: true,
        reject: true,
      },
      Basket: {
        edit: true,
        next: true,
        prev: true,
        reject: true,
      },
    },
    contract: {
      edit: [
        "Draft",
        "IdAssigning",
        "LocationsCreation",
        "ProjectsCreation",
        "InformationRequest",
        "PreparationAndExecution",
        "Acts",
        "WaitingForUnloadingDocuments",
        "Finish",
        "Suspended",
        "Archive",
        "Terminated",
        "Basket",
        "Hung",
      ],
      trash: [
        "Draft",
        "IdAssigning",
        "LocationsCreation",
        "ProjectsCreation",
        "InformationRequest",
        "PreparationAndExecution",
        "Acts",
        "WaitingForUnloadingDocuments",
        "Finish",
        "Suspended",
        "Archive",
        "Terminated",
        "Basket",
        "Hung",
      ],
      info: ["Draft", "Basket"],
      movePrev: [
        "Draft",
        "IdAssigning",
        "LocationsCreation",
        "ProjectsCreation",
        "InformationRequest",
        "PreparationAndExecution",
        "Acts",
        "WaitingForUnloadingDocuments",
        "Finish",
        "Suspended",
        "Archive",
        "Terminated",
        "Basket",
      ],
      moveNext: [
        "Draft",
        "IdAssigning",
        "LocationsCreation",
        "ProjectsCreation",
        "InformationRequest",
        "PreparationAndExecution",
        "Acts",
        "WaitingForUnloadingDocuments",
        "Finish",
        "Suspended",
        "Archive",
        "Terminated",
        "Basket",
        "Hung",
      ],
      documents: ["Draft", "Basket"],
    },
    project: {
      OrganizationsAnalysis: {
        prev: true,
        next: true
      },
      ExecutorsAnalysis: {
        prev: true,
      },
      InformationRequest: {},
      ReadyToWork: {},
      AcceptedToWork: {},
      Agreement: {},
      PrintedAndSent: {},
      Verification: {
        reject: true,
        next: true,
      },
      UnloadingDocuments: {
        reject: true,
        prev: true,
        next: true
      },
      Finish: {
        next: true,
        prev: true,
        edit: true,
        reject: true,
      },
      Archive: {
        prev: true,
        next: true,
        edit: true,
        reject: true,
      },
      Terminated: {
        prev: true,
        next: true,
        edit: true,
        reject: true,
      },
      Errors: {
        next: true,
        edit: true,
        reject: true,
      },
      Basket: {
        next: true,
        edit: true,
        reject: true,
      },
      Suspended: {
        next: true,
        edit: true,
      },
      Hung: {
        next: true,
        edit: true,
      }
    },
    location: null,
  },
  tender_chief: {
    canChange: ["tender", "contract"],
    tender: {
      Draft: {
        prev: true,
        next: true,
      },
      Estimated: {
        next: true,
        reject: true,
        prev: true,
      },
      Approved: {
        prev: true,
      },
      AcceptedToWork: null,
      Filed: null,
      WonAndSigning: null,
      Finish: {
        edit: true,
        reject: true,
      },
      Archive: {
        edit: true,
        next: true,
        prev: true,
        reject: true,
      },
      NotFiled: {
        edit: true,
        next: true,
        reject: true,
      },
      Lost: {
        edit: true,
        next: true,
        reject: true,
      },
      NotSigned: {
        edit: true,
        next: true,
        reject: true,
      },
      Basket: {
        edit: true,
        next: true,
        reject: true,
      },
    },
    contract: {
      edit: [],
      trash: [
        "Suspended",
        "IdAssigning",
        "SigningAndCollectingInformation",
        "LocationsCreation",
        "ProjectsCreation",
        "InformationRequest",
        "PreparationAndExecution",
        "Acts",
        "WaitingForUnloadingDocuments",
        "Finish",
        "Archive",
        "Terminated",
        "Basket",
      ],
      info: [],
      movePrev: [
        "Draft",
        "Suspended",
        "IdAssigning",
        "SigningAndCollectingInformation",
        "LocationsCreation",
        "ProjectsCreation",
        "InformationRequest",
        "PreparationAndExecution",
        "Acts",
        "WaitingForUnloadingDocuments",
        "Finish",
        "Archive",
        "Terminated",
      ],
      moveNext: [
        "Suspended",
        "IdAssigning",
        "SigningAndCollectingInformation",
        "LocationsCreation",
        "ProjectsCreation",
        "InformationRequest",
        "PreparationAndExecution",
        "Acts",
        "WaitingForUnloadingDocuments",
        "Finish",
        "Archive",
        "Terminated",
        "Basket",
      ],
      documents: [],
    },
    project: null,
    location: null,
  },
  lab_manager: {
    canChange: ['tender',"location", "project"],
    tender: {
      Draft: {
        prev: true,
      },
      Estimated: {},
      Approved: {
        next: true,
        edit: true,
        reject: true,
      },
      AcceptedToWork: {
        prev: true,
        next: true,
        edit: true,
        reject: true,
      },
      Filed: {
        prev: true,
        next: true,
        edit: true,
        reject: true,
      },
      WonAndSigning: {
        prev: true,
        next: true,
        edit: true,
        reject: true,
      },
      Finish: {
        prev: true,
        next: true,
        edit: true,
        reject: true,
      },
      Archive: {
        prev: true,
        next: true,
        edit: true,
        reject: true,
      },
      NotFiled: {
        prev: true,
        next: true,
        edit: true,
        reject: true,
      },
      Lost: {
        prev: true,
        next: true,
        edit: true,
        reject: true,
      },
      NotSigned: {
        prev: true,
        next: true,
        edit: true,
        reject: true,
      },
      Basket: {
        next: true,
        edit: true,
        reject: true,
      },
    },
    contract: {
      suspend: true,
      edit: [],
      trash: [
        "Draft",
        "Acts",
        "WaitingForUnloadingDocuments",
        "Finish",
        "Archive",
        "Terminated",
        "Basket",
        "Hung",
      ],
      info: ["Draft", "Basket"],
      movePrev: [
        "Draft",
        "IdAssigning",
        "Acts",
        "Finish",
        "Archive",
        "Suspended",
        "WaitingForUnloadingDocuments",
        "Basket",
      ],
      moveNext: [
        "Draft",
        "Suspended",
        "PreparationAndExecution",
        "Acts",
        "WaitingForUnloadingDocuments",
        "Finish",
        "Archive",
        "Terminated",
        "Basket",
        "Hung",
      ],
      documents: ["Draft", "Basket"],
    },
    project: {
      OrganizationsAnalysis: {
        prev: true,
      },
      ExecutorsAnalysis: {},
      InformationRequest: {},
      ReadyToWork: {},
      AcceptedToWork: {},
      Agreement: {},
      PrintedAndSent: {},
      Verification: {
      },
      UnloadingDocuments: {
        reject: true,
      },
      Finish: {
        next: true,
        edit: true,
        reject: true,
      },
      Errors: {
        next: true,
        edit: true,
        reject: true,
      },
      Archive: {
        prev: true,
        next: true,
        edit: true,
        reject: true,
      },
      Terminated: {
        prev: true,
        next: true,
        edit: true,
        reject: true,
      },
      Basket: {
        next: true,
        edit: true,
        reject: true,
      },
      Suspended: {
        next: true,
        edit: true,
        reject: true,
      },
      Hung: {
        next: true,
        edit: true,
        reject: true,
      },
    },
    location: {
      // canCreate - надо ввводить stageId который в адресной строке находится, чтобы сделать кнопку доступной
      canCreate: true,
      edit: (location, locType) =>
        location.measuringBlockTypeId === "PotentialMeasuringBlock" ||
        location?.workFlow?.workFlowStatusId === "Archive" ||
        location?.workFlow?.workFlowStatusId === "Finish" ||
        location?.workFlow?.workFlowStatusId === "Basket" ||
        location?.workFlow?.workFlowStatusId === "Suspended" ||
        locType === "Potential",
      trash: (location, locType) =>
        location?.workFlow?.workFlowStatusId === "Archive" ||
        location?.workFlow?.workFlowStatusId === "Finish" ||
        location?.workFlow?.workFlowStatusId === "Basket" ||
        location?.workFlow?.workFlowStatusId === "Terminated",
      info: (location, locType) =>
        location?.workFlow?.workFlowStatusId === "Suspended",
      movePrev: (location, locType) =>
        location?.workFlow?.workFlowStatusId === "Draft" ||
        location?.workFlow?.workFlowStatusId === "Unassigned" ||
        location.measuringBlockTypeId === "PotentialMeasuringBlock" ||
        locType === "Potential",
      moveNext: (location, locType) =>
        location?.workFlow?.workFlowStatusId === "Archive" ||
        location?.workFlow?.workFlowStatusId === "Finish" ||
        location?.workFlow?.workFlowStatusId === "Suspended" ||
        location?.workFlow?.workFlowStatusId === "Basket" ||
        location?.workFlow?.workFlowStatusId === "Terminated" ||
        location.measuringBlockTypeId === "PotentialMeasuringBlock" ||
        locType === "Potential",
      documents: (location, locType) =>
        location?.workFlow?.workFlowStatusId === "Suspended",
    },
  },
  lab_assist: {
    canChange: [],
    tender: null,
    contract: {
      edit: [
        "Draft",
        "Suspended",
        "ProjectsCreation",
        "IdAssigning",
        "LocationsCreation",
        "SigningAndCollectingInformation",
        "InformationCollection",
        "PreparationAndExecution",
        "Acts",
        "WaitingForPayment",
        "ReturnOfSecurity",
        "ReturnOfActs",
        "WaitingForUnloadingDocuments",
        "Finish",
        "Archive",
        "Terminated",
        "Basket",
        "Hung"
      ],
      trash: [
        "Draft",
        "ProjectsCreation",
        "IdAssigning",
        "SigningAndCollectingInformation",
        "LocationsCreation",
        "InformationRequest",
        "PreparationAndExecution",
        "Acts",
        "Suspended",
        "WaitingForPayment",
        "ReturnOfSecurity",
        "ReturnOfActs",
        "WaitingForUnloadingDocuments",
        "Finish",
        "Archive",
        "Terminated",
        "Basket",
        "Hung"
      ],
      info: ["Draft", "Basket"],
      movePrev: [
        "Draft",
        "ProjectsCreation",
        "IdAssigning",
        "PreparationAndExecution",
        "Acts",
        "WaitingForPayment",
        "ReturnOfSecurity",
        "Suspended",
        "Finish",
        "Archive",
        "Terminated",
        "Basket",
        "Hung"
      ],
      moveNext: [
        "Draft",
        "Suspended",
        "ProjectsCreation",
        "SigningAndCollectingInformation",
        "LocationsCreation",
        "InformationRequest",
        "PreparationAndExecution",
        "WaitingForPayment",
        "ReturnOfSecurity",
        "ReturnOfActs",
        "WaitingForUnloadingDocuments",
        "Finish",
        "Archive",
        "Terminated",
        "Basket",
        "Hung"
      ],
      documents: ["Draft", "Basket"],
    },
    project: null,
    location: null,
  },
  tender_emp: {
    canChange: ["tender", "contract"],
    tender: {
      Draft: {
        prev: true,
        next: true,
      },
      Estimated: {
        reject: true,
        prev: true,
        next: true,
      },
      Approved: {
        prev: true,
      },
      AcceptedToWork: {
        prev: true
      },
      Filed: null,
      WonAndSigning: null,
      Finish: {
        reject: true,
        edit: true
      },
      Archive: {
        edit: true,
        next: true,
        prev: true,
        reject: true,
      },
      NotFiled: {
        edit: true,
        next: true,
        reject: true,
      },
      Lost: {
        edit: true,
        next: true,
        reject: true,
      },
      NotSigned: {
        edit: true,
        next: true,
        reject: true,
      },
      Basket: {
        edit: true,
        next: true,
        reject: true,
      },
    },
    contract: {
      edit: [],
      trash: [
        "Suspended",
        "IdAssigning",
        "SigningAndCollectingInformation",
        "LocationsCreation",
        "ProjectsCreation",
        "InformationRequest",
        "PreparationAndExecution",
        "Acts",
        "WaitingForUnloadingDocuments",
        "Finish",
        "Archive",
        "Terminated",
        "Basket",
      ],
      info: [],
      movePrev: [
        "Draft",
        "Suspended",
        "IdAssigning",
        "SigningAndCollectingInformation",
        "LocationsCreation",
        "ProjectsCreation",
        "InformationRequest",
        "PreparationAndExecution",
        "Acts",
        "WaitingForUnloadingDocuments",
        "Finish",
        "Archive",
        "Terminated",
      ],
      moveNext: [
        "Suspended",
        "IdAssigning",
        "SigningAndCollectingInformation",
        "LocationsCreation",
        "ProjectsCreation",
        "InformationRequest",
        "PreparationAndExecution",
        "Acts",
        "WaitingForUnloadingDocuments",
        "Finish",
        "Archive",
        "Terminated",
        "Basket",
      ],
      documents: [],
    },
    project: null,
    location: null,
  },
  lab_emp: {
    canChange: ["project"],
    tender: {
      Draft: {
        prev: true,
        next: true,
        edit: true,
        reject: true,
      },
      Estimated: {
        prev: true,
        next: true,
        edit: true,
        reject: true,
      },
      Approved: {
        prev: true,
        next: true,
        edit: true,
        reject: true,
      },
      AcceptedToWork: {
        prev: true,
        next: true,
        edit: true,
        reject: true,
      },
      Filed: {
        prev: true,
        next: true,
        edit: true,
        reject: true,
      },
      WonAndSigning: {
        prev: true,
        next: true,
        edit: true,
        reject: true,
      },
      Finish: {
        prev: true,
        next: true,
        edit: true,
        reject: true,
      },
      Archive: {
        prev: true,
        next: true,
        edit: true,
        reject: true,
      },
      NotFiled: {
        prev: true,
        next: true,
        edit: true,
        reject: true,
      },
      Lost: {
        prev: true,
        next: true,
        edit: true,
        reject: true,
      },
      NotSigned: {
        prev: true,
        next: true,
        edit: true,
        reject: true,
      },
      Basket: {
        prev: true,
        next: true,
        edit: true,
        reject: true,
      },
    },
    contract: {
      edit: [
        "Draft",
        "IdAssigning",
        "LocationsCreation",
        "ProjectsCreation",
        "InformationRequest",
        "PreparationAndExecution",
        "Acts",
        "WaitingForUnloadingDocuments",
        "Finish",
        "Suspended",
        "Archive",
        "Terminated",
        "Basket",
        "Hung"
      ],
      trash: [
        "Draft",
        "IdAssigning",
        "LocationsCreation",
        "ProjectsCreation",
        "InformationRequest",
        "PreparationAndExecution",
        "Acts",
        "WaitingForUnloadingDocuments",
        "Finish",
        "Suspended",
        "Archive",
        "Terminated",
        "Basket",
        "Hung"
      ],
      info: ["Draft", "Basket"],
      movePrev: [
        "Draft",
        "IdAssigning",
        "LocationsCreation",
        "ProjectsCreation",
        "InformationRequest",
        "PreparationAndExecution",
        "Acts",
        "Suspended",
        "WaitingForUnloadingDocuments",
        "Finish",
        "Archive",
        "Terminated",
        "Basket",
        "Hung"
      ],
      moveNext: [
        "Draft",
        "IdAssigning",
        "LocationsCreation",
        "ProjectsCreation",
        "InformationRequest",
        "PreparationAndExecution",
        "Acts",
        "WaitingForUnloadingDocuments",
        "Finish",
        "Suspended",
        "Archive",
        "Terminated",
        "Basket",
        "Hung"
      ],
      documents: ["Draft", "Basket"],
    },
    project: {
      OrganizationsAnalysis: {
        prev: true,
        next: true,

        reject: true,
        info: true,
      },
      ExecutorsAnalysis: {
        prev: true,
        next: true,

        reject: true,
        info: true,
      },
      InformationRequest: {
        prev: true,
        reject: true,
      },
      ReadyToWork: {
        reject: true,
      },
      AcceptedToWork: {
        reject: true,
      },
      Agreement: {
        reject: true,
      },
      PrintedAndSent: {
      },
      Verification: {
        reject: true,
        next: true,
      },
      UnloadingDocuments: {
        next: true,
        edit: true,
        prev: true,
        reject: true,
      },
      Finish: {
        next: true,
        prev: true,
        edit: true,
        reject: true,
      },
      Archive: {
        prev: true,
        edit: true,
        next: true,
        reject: true,
      },
      Terminated: {
        prev: true,
        edit: true,
        next: true,
        reject: true,
      },
      Basket: {
        next: true,
        reject: true,
        edit: true,
      },
      Suspended: {
        next: true,
        reject: true,
        edit: true,
      },
      Waiting: {
        reject: true,
      },
      Hung: {
        next: true,
        reject: true,
        edit: true,
      },
      Error: {
        next: true,
        reject: true,
        edit: true,
      },
      disabledFields: {
        assessmentType: true,
        name: false,
        regions: true,
        workspaceCount: true,
        plannedExecutionDate: true,
        organizationId: true,
        branchOfficeId: true,
        laboratoryUserId: true,
        contractDepartmentNumber: true
      },
    },
    location: null,
  },
  comercial_chief: {
    canChange: ["contract"],
    tender: null,
    contract: {
      edit: [],
      trash: [
        "Acts",
        "WaitingForUnloadingDocuments",
        "Finish",
        "Archive",
        "Terminated",
        "Basket",
      ],
      info: [],
      movePrev: [
        "Draft",
        "IdAssigning",
        "LocationsCreation",
        "ProjectsCreation",
        "InformationRequest",
        "PreparationAndExecution",
        "Acts",
        "WaitingForUnloadingDocuments",
        "Finish",
        "Archive",
      ],
      moveNext: [
        "Suspended",
        "IdAssigning",
        "LocationsCreation",
        "ProjectsCreation",
        "InformationRequest",
        "PreparationAndExecution",
        "Acts",
        "WaitingForUnloadingDocuments",
        "Finish",
        "Archive",
        "Terminated",
        "Basket",
      ],
      documents: [],
    },
    project: null,
    location: null,
  },
  comercial_emp: {
    canChange: ["contract"],
    tender: null,
    contract: {
      edit: [],
      trash: [
        "Acts",
        "WaitingForUnloadingDocuments",
        "Finish",
        "Archive",
        "Terminated",
        "Basket",
      ],
      info: [],
      movePrev: [
        "Draft",
        "IdAssigning",
        "LocationsCreation",
        "ProjectsCreation",
        "InformationRequest",
        "PreparationAndExecution",
        "Acts",
        "WaitingForUnloadingDocuments",
        "Finish",
        "Archive",
      ],
      moveNext: [
        "Suspended",
        "IdAssigning",
        "LocationsCreation",
        "ProjectsCreation",
        "InformationRequest",
        "PreparationAndExecution",
        "Acts",
        "WaitingForUnloadingDocuments",
        "Finish",
        "Archive",
        "Terminated",
        "Basket",
      ],
      documents: [],
    },
    project: null,
    location: null,
  },
};
