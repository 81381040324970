import Statistics from "pages/Statistics";
import TenderStatistics from "pages/TenderStatistics";
import Workload from "pages/Workload";
import ListOfAgents from "pages/sout/admin/ListOfAgents";
import ListOfCompetitors from "pages/sout/admin/ListOfCompetitors";
import ListOfSites from "pages/sout/admin/ListOfSites";
import ListOfUsers from "pages/sout/admin/ListOfUsers";

export const StartPageData = {
    admin: [
        {
            title: "Для работы вам доступны",
            items: [
                {
                    link: "/sout/tender/draft",
                    title: "Тендеры",
                    element: null,
                    entity: "tender",
                },
                {
                    link: "/sout/contracts/Draft",
                    title: "Договоры",
                    element: null,
                    entity: "contracts",
                },
                {
                    link: "/sout/projects/organizations-analysis",
                    title: "Проекты",
                    element: null,
                    entity: "projects",
                },
                {
                    link: "/sout/locations/potential",
                    title: "Замерные блоки",
                    element: null,
                    entity: "locations",
                },
            ],
        },
        {
            title: "Списки",
            items: [
                {
                    link: "/ListOfUsers",
                    title: "Список пользователей",
                    element: <ListOfUsers />,
                },
                {
                    link: "/ListOfSites",
                    title: "Список площадок",
                    element: <ListOfSites />,
                },
                {
                    link: "/ListOfCompetitors",
                    title: "Список конкурентов",
                    element: <ListOfCompetitors />,
                },
                {
                    link: "/ListOfAgents",
                    title: "Список агентов",
                    element: <ListOfAgents />,
                },
            ],
        },
        {
            title: "Статистика",
            items: [
                {
                    link: "/Statistics",
                    title: "Общая статистика",
                    element: <Statistics />,
                },
                // {
                //     link: "/TenderStatistics",
                //     title: "Тендерная статистика",
                //     element: <TenderStatistics/>,
                // },
            ],
        },
        {
            title: "Исполнение",
            items: [
                {
                    link: "/Workload",
                    title: "Загруженность сотрудников",
                    element: <Workload />,
                },
                // {
                //     link: "/projectSummary",
                //     title: "Сводка по проектам",
                //     element: null,
                // },
                // {
                //     link: "/locationSummary",
                //     title: "Сводка по замерным блокам",
                //     element: null,
                // },
            ],
        },
        // {
        //   title: "Бухгалтерия",
        //   items: [
        //     {link: '', element: <Statistics/>},
        //   ]
        // },
    ],
    lab_chief: [
        {
            title: "Для работы вам доступны",
            items: [
                {
                    link: "/sout/tender/draft",
                    title: "Тендеры",
                    element: null,
                    entity: "tender",
                },
                {
                    link: "/sout/contracts/IdAssigning",
                    title: "Договоры",
                    element: null,
                    entity: "contracts",
                },
                {
                    link: "/sout/projects/organizations-analysis",
                    title: "Проекты",
                    element: null,
                    entity: "projects",
                },
                {
                    link: "/sout/locations/Compare",
                    title: "Замерные блоки",
                    element: null,
                    entity: "locations",
                },
            ],
        },
        {
            title: "Статистика",
            items: [
                {
                    link: "/Statistics",
                    title: "Общая статистика",
                    element: <Statistics />,
                },
                // {
                //     link: "/AdminStartPage",
                //     title: "Тендерная статистика",
                //     element: null,
                // },
            ],
        },
        {
            title: "Исполнение",
            items: [
                {
                    link: "/Workload",
                    title: "Загруженность сотрудников",
                    element: <Workload />,
                },
                // {
                //     link: "/AdminStartPage",
                //     title: "Сводка по проектам",
                //     element: null,
                // },
                // {
                //     link: "/AdminStartPage",
                //     title: "Сводка по замерным блокам",
                //     element: null,
                // },
            ],
        },
        // {
        //   title: "Бухгалтерия",
        //   items: [
        //     {link: '', element: <Statistics/>},
        //   ]
        // },
    ],
    lab_expert: [
        {
            title: "Для работы вам доступны",
            items: [
                {
                    link: "/sout/projects/organizations-analysis",
                    title: "Проекты",
                    element: null,
                    entity: "projects",
                },
            ],
        },
    ],
    lab_manager: [
        {
            title: "Для работы вам доступны",
            items: [
                {
                    link: "/sout/tender/draft",
                    title: "Тендеры",
                    element: null,
                    entity: "tender",
                },
                {
                    link: "/sout/contracts/IdAssigning",
                    title: "Договоры",
                    element: null,
                    entity: "contracts",
                },
                {
                    link: "/sout/projects/organizations-analysis",
                    title: "Проекты",
                    element: null,
                    entity: "projects",
                },
                {
                    link: "/sout/locations/potential",
                    title: "Замерные блоки",
                    element: null,
                    entity: "locations",
                },
            ],
        },
        {
            title: "Статистика",
            items: [
                {
                    link: "/Statistics",
                    title: "Общая статистика",
                    element: <Statistics />,
                },
                // {
                //     link: "/TenderStatistics",
                //     title: "Тендерная статистика",
                //     element: <TenderStatistics/>,
                // },
            ],
        },
        {
            title: "Исполнение",
            items: [
                {
                    link: "/Workload",
                    title: "Загруженность сотрудников",
                    element: <Workload />,
                },
                // {
                //     link: "/projectSummary",
                //     title: "Сводка по проектам",
                //     element: null,
                // },
                // {
                //     link: "/locationSummary",
                //     title: "Сводка по замерным блокам",
                //     element: null,
                // },
            ],
        },
    ],
    lab_emp: [
        {
            title: "Для работы вам доступны",
            items: [
                {
                    link: "/sout/contracts/DisassemblyPreparationAndExecution",
                    title: "Договоры",
                    element: null,
                    entity: "contracts",
                },
                {
                    link: "/sout/projects/information-collection",
                    title: "Проекты",
                    element: null,
                    entity: "projects",
                },
            ],
        },
    ],
    lab_assist: [
        {
            title: "Для работы вам доступны",
            items: [
                {
                    link: "/sout/contracts/IdAssigning",
                    title: "Договоры",
                    element: null,
                    entity: "contracts",
                },
            ],
        },
    ],
    tender_chief: [
        {
            title: "Для работы вам доступны",
            items: [
                {
                    link: "/sout/tender/draft",
                    title: "Тендеры",
                    element: null,
                    entity: "tender",
                },
                {
                    link: "/sout/contracts/IdAssigning",
                    title: "Договоры",
                    element: null,
                    entity: "contracts",
                },
            ],
        },
        {
            title: "Статистика",
            items: [
                {
                    link: "/Statistics",
                    title: "Общая статистика",
                    element: <Statistics />,
                },
                // {
                //     link: "/TenderStatistics",
                //     title: "Тендерная статистика",
                //     element: null,
                // },
            ],
        },
        {
            title: "Списки",
            items: [
                {
                    link: "/ListOfSites",
                    title: "Список площадок",
                    element: <ListOfSites />,
                },
                {
                    link: "/ListOfCompetitors",
                    title: "Список конкурентов",
                    element: <ListOfCompetitors />,
                },
            ],
        },
    ],
    tender_emp: [
        {
            title: "Для работы вам доступны",
            items: [
                {
                    link: "/sout/tender/draft",
                    title: "Тендеры",
                    element: null,
                    entity: "tender",
                },
                {
                    link: "/sout/contracts/IdAssigning",
                    title: "Договоры",
                    element: null,
                    entity: "contracts",
                },
            ],
        },
        {
            title: "Статистика",
            items: [
                {
                    link: "/Statistics",
                    title: "Общая статистика",
                    element: <Statistics />,
                },
                // {
                //     link: "/TenderStatistics",
                //     title: "Тендерная статистика",
                //     element: null,
                // },
            ],
        },
        {
            title: "Списки",
            items: [
                {
                    link: "/ListOfSites",
                    title: "Список площадок",
                    element: <ListOfSites />,
                },
                {
                    link: "/ListOfCompetitors",
                    title: "Список конкурентов",
                    element: <ListOfCompetitors />,
                },
            ],
        },
    ],
    comercial_chief: [
        {
            title: "Для работы вам доступны",
            items: [
                {
                    link: "/sout/contracts/Draft",
                    title: "Договоры",
                    element: null,
                    entity: "contracts",
                },
            ],
        },
        {
            title: "Статистика",
            items: [
                {
                    link: "/Statistics",
                    title: "Общая статистика",
                    element: <Statistics />,
                },
            ],
        },
    ],
    comercial_emp: [
        {
            title: "Для работы вам доступны",
            items: [
                {
                    link: "/sout/contracts/Draft",
                    title: "Договоры",
                    element: null,
                    entity: "contracts",
                },
            ],
        },
    ]
};
