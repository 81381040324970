import { useEffect, useState, useCallback } from "react";
import { formatDate } from "shared/formatDate";
import { translateText } from "utils/stages";
import styled from "styled-components";
import { Line } from "shared/ui/Line/Line";
import { toDateWithoutTime } from "utils/formatDate";
import { fetchTenderById } from "utils/fetchTenderById";
import { api } from "settings/Api";
import { getOrganizationNameById } from "utils/getOrganizationNameById";
import { getBranchOfficeById } from "utils/getBranchOfficeById";
import { fetchEmployees } from "../service/fetchEmployees";
import {
  FaDocker,
  FaFile,
  FaFileArchive,
  FaFileDownload,
  FaFileUpload,
  FaUpload,
} from "react-icons/fa";
import { getIcon } from "utils/getIcon";

const executionTypes = {
  Preparation: "Подготовка",
  InProgress: " В работе",
  Finish: "Выгрузка",
  None: "Нет",
};

const asessementTypes = {
  ProductionControl: "ПК",
  WorkingConditionsSpecialAssesment: "СОУТ",
  ProfessionalRisks: "ОПР",
};

const ViewModalRow = ({ title, value, color }) => {
  const ViewModalCell = styled.td`
    font-size: 14px;
    color: ${(props) => props.color || ""};
  `;
  return (
    <tr>
      <ViewModalCell color={color}>{title}</ViewModalCell>
      <ViewModalCell>{value || "-"}</ViewModalCell>
    </tr>
  );
};

const ContractViewModal = ({ contractID, modalHandler }) => {
  const [userData, setUserData] = useState();
  const [data, setData] = useState(null);
  const [personInCharge, setPersonInCharge] = useState({
    orgName: null,
    branchOffice: null,
  });
  const [dataTables, setDataTables] = useState({
    common: [],
    tender: [],
    execution: [],
    accounting: [],
    responsible: [],
    dates: [],
  });

  const [departmentsParts, setDepartmentsParts] = useState([]);
  const [ten, setTen] = useState({});

  const generateToken = () => {
    return JSON.parse(localStorage.getItem("authTokens")).data.accessToken;
  };

  // useEffect(() => {
  //   fetchEmployees(data?.contractConclusionDepartmentId).then((res) => {
  //     setWorkers(res);
  //   });
  // }, [data?.contractConclusionDepartmentId]);

  const fetchContractInfo = useCallback(async () => {
    const token = generateToken();
    const url = `/api/sout/admin/contracts/${contractID}`;

    try {
      const response = await api(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const promise = await response.json();

      if (promise.isSuccess) {
        setData(promise.data);
        const [orgId, boId] = [
          promise.data?.personInCharge?.organizationId,
          promise.data?.personInCharge?.branchOfficeId,
        ];

        if (orgId || boId) {
          const orgName = await getOrganizationNameById(orgId);
          const branchOffice = await getBranchOfficeById(orgId, boId);
          setPersonInCharge({
            orgName,
            branchOffice,
          });
          console.log({
            orgName,
            branchOffice,
          });
        }
      }

      if (promise.isFailure) {
        throw new Error();
      }
    } catch (e) {
      alert(`Произошла ошибка: ${e}`);
    }
  }, [contractID]);

  useEffect(() => {
    fetchContractInfo();
  }, [fetchContractInfo]);
  const [tenderId, setTenderId] = useState();
  useEffect(() => {
    if (data) {
      let parts;
      if (data.departments.length % 3 === 0) {
        parts = data.departments.length / 3;
      } else {
        parts = Math.floor(data.departments.length / 3) + 1;
      }
      setDepartmentsParts(
        new Array(parts).fill(0).map((elem, i) => {
          return [0 + i * 3, 3 + i * 3];
        })
      );
      if (data?.tenderId) {
        fetchTenderById(data.tenderId).then((res) =>
          setTenderId(res?.manager.id)
        );
      }

      console.log(data);
      setDataTables({
        common: [
          ["ID договора", data.id, "black"],
          [
            "Отдел заключения договоров",
            data.contractConclusionDepartmentId === "CommercialDepartment"
              ? "Коммерческий"
              : "Тендерный",
            "black",
          ],
          data.contractConclusionDepartmentId === "TenderDepartment"
            ? ["ID тендера", data.tenderId, "darkviolet"]
            : null,
          ["Заказчик", data?.customer?.name, "black"],
          ["Дополнительное наименование", data?.additionalName, "black"],
          ["ИНН заказчика", data?.customer?.inn, "black"],
          ["Номер контракта", data?.contractNumber, "black"],
          ["Внутренний номер", data?.innerNumber, "black"],
          ["Цена", data?.price, "black"],
          ["Сумма по акту", data?.act?.sum, "black"],
          [
            "Этап договора",
            translateText(data?.workFlow?.workFlowStatusId),
            "black",
          ],
          ["Контакные данные", data?.contactData, "black"],
          ["Регионы", data?.regions.map((r) => r.name).join(",\n"), "black"],
          ["Количество подразделений", data.departments.length, "black"],
        ],
        tender: [
          ["Обеспечение контракта", data?.securityReturned, "darkviolet"],
          ["Сумма обеспечения", "-", "red"],
          ["Обеспечение вернулось?", data?.securityReturned, "darkviolet"],
        ],
        execution: [
          ["Работа по заявке", data?.workOnRequest ? "Да" : "Нет"],
          ["Тип оценки", getAsessmentTypes(), "black"],
          [
            "Число РМ ПК",
            !data?.workspaceCount?.pk &&
            data?.assesmentTypes.find(
              (item) => item?.id === "ProductionControl"
            )
              ? "x"
              : data?.workspaceCount?.pk,
            "black",
          ],
          [
            "Число РМ СОУТ",
            !data?.workspaceCount?.sout &&
            data?.assesmentTypes.find(
              (item) => item?.id === "WorkingConditionsSpecialAssesment"
            )
              ? "x"
              : data?.workspaceCount?.sout,
            "black",
          ],
          [
            "Число РМ ОПР",
            !data?.workspaceCount?.opr &&
            data.assesmentTypes.find((item) => item?.id === "ProfessionalRisks")
              ? "x"
              : data?.workspaceCount?.opr,
            "black",
          ],
          ["Комментарий к исполнению", data?.executionDescription, "black"],
        ],
        accounting: [
          ["Акт получен?", data?.act?.isReturned ? "Да" : "Нет", "black"],
          ["Оплата", data?.paymentAmount, "black"],
        ],
        dates: [
          [
            "Дата заключения договора",
            formatDate(data.conclusionContractDate),
            "black",
          ],
          [
            "Плановая дата исполнения",
            formatDate(data.plannedExecutionDate),
            "black",
          ],
          ["Дата акта", toDateWithoutTime(data?.act?.date), "black"],
        ],
        responsible: [
          [
            "Ответственный по заключению",
            [data.personInCharge?.name, data.personInCharge?.lastName]
              .join(" ")
              .trim(),
            "black",
          ],
          ["Организация", personInCharge?.orgName],
          ["Филиал", personInCharge?.branchOffice],
        ],
      });
    }
  }, [data, personInCharge]);

  function getAsessmentTypes() {
    const asessementTypes = {
      ProductionControl: "ПК",
      ProfessionalRisks: "ОПР",
      WorkingConditionsSpecialAssesment: "СОУТ",
    };
    let types = [];
    for (let type of data.assesmentTypes) {
      types.push(asessementTypes[type.id]);
    }
    return types.join("/");
  }
  return (
    data && (
      <>
        <h2
          style={{
            textAlign: "center",
            marginBottom: "20px",
          }}
        >
          Сведения о договоре
        </h2>
        <div className="flex-wrapper__modal__tables">
          <div className="flex-wrapper__modal-column">
            <div>
              <h3 className="table__title">Общие сведения</h3>
              <table
                className="table__table-view-modal"
                style={{
                  width: 500,
                  borderCollapse: "collapse",
                }}
              >
                <tbody className="flex-wrapper__modal-table">
                  {dataTables.common
                    .filter((elem) => elem !== null)
                    .map((elem) => (
                      <ViewModalRow
                        title={elem[0]}
                        value={elem[1]}
                        color={elem[2]}
                      />
                    ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="flex-wrapper__modal-column">
            {/* <div>
							<h3 className="table__title">Тендерные сведения</h3>
							<table className="table__table-view-modal">
								<tbody className="flex-wrapper__modal-table">
									{dataTables.tender.map((elem) => (
										<ViewModalRow
											title={elem[0]}
											value={elem[1]}
											color={elem[2]}
										/>
									))}
								</tbody>
							</table>
						</div> */}
            <div>
              <h3 className="table__title">Ответственный</h3>
              <table className="table__table-view-modal">
                <tbody className="flex-wrapper__modal-table">
                  {dataTables.responsible.map((elem) => (
                    <ViewModalRow
                      title={elem[0]}
                      value={elem[1]}
                      color={elem[2]}
                    />
                  ))}
                </tbody>
              </table>
            </div>
            <div>
              <h3 className="table__title">Исполнение</h3>
              <table className="table__table-view-modal">
                <tbody className="flex-wrapper__modal-table">
                  {dataTables.execution.map((elem) => (
                    <ViewModalRow
                      title={elem[0]}
                      value={elem[1]}
                      color={elem[2]}
                    />
                  ))}
                </tbody>
              </table>
            </div>
            <div>
              <h3 className="table__title">Даты</h3>
              <table className="table__table-view-modal">
                <tbody className="flex-wrapper__modal-table">
                  {dataTables.dates.map((elem) => (
                    <ViewModalRow
                      title={elem[0]}
                      value={elem[1]}
                      color={elem[2]}
                    />
                  ))}
                </tbody>
              </table>
            </div>
            {/* <div>
							<h3 className="table__title">Бухгалтерия</h3>
							<table className="table__table-view-modal">
								<tbody className="flex-wrapper__modal-table">
									{dataTables.accounting.map((elem) => (
										<ViewModalRow
											title={elem[0]}
											value={elem[1]}
											color={elem[2]}
										/>
									))}
								</tbody>
							</table>
						</div> */}
          </div>
          <Line gridColumn="1 / 4" />
          <div className="flex-wrapper__modal-row">
            <div style={{ width: "33%" }}>
              <table className="table__table-view-modal table-info">
                <tbody>
                  <tr>
                    <td style={{ color: "black" }}>#</td>
                    <td style={{ color: "black" }}>КПП</td>
                    <td style={{ color: "black" }}>Идентификационный номер</td>
                  </tr>
                  {data.departments.map((item, idx) => (
                    <tr>
                      <td>{idx + 1}</td>
                      <td>{item.kpp}</td>
                      <td>{item.departmentNumber}</td>
                    </tr>
                  ))}
                  {data?.departments.length === 0 && (
                    <tr>
                      <td>{"-"}</td>
                      <td>{"-"}</td>
                      <td>{"-"}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="flex-wrapper__modal-row">
            <div>
              <h3 className="table__title">
                Сведения о проектах, связанных с данным договором
              </h3>
              <table
                className="table__table-view-modal table-info"
                style={{ width: "80%", marginBottom: "20px" }}
              >
                <tbody>
                  <tr>
                    <td style={{ color: "darkviolet" }}>#</td>
                    <td style={{ color: "darkviolet" }}>ID проекта</td>
                    <td style={{ color: "darkviolet", width: 400 }}>
                      Дополнительное наименование
                    </td>
                    <td style={{ color: "darkviolet", width: 200 }}>
                      Тип оценки
                    </td>
                    <td style={{ color: "darkviolet", minWidth: 250 }}>
                      Сотрудник лаборатории
                    </td>
                    <td style={{ color: "darkviolet", fontSize: 20 }}>
                      <FaFileDownload />
                    </td>
                    <td style={{ color: "darkviolet", fontSize: 20 }}>
                      <FaUpload />
                    </td>
                  </tr>
                  {data?.projects?.map((item, idx) => (
                    <tr>
                      <td>{idx + 1}</td>
                      <td>{item?.id || "-"}</td>
                      <td>{item?.name || "-"}</td>
                      <td>{asessementTypes[item?.assessmentTypeId] || "-"}</td>
                      <td>
                        {item?.personInCharge?.name
                          ? `${item?.personInCharge?.name} ${item?.personInCharge?.lastName}`
                          : "-"}
                      </td>
                      <td>
                        {getIcon(
                          "project",
                          item?.workFlow?.workFlowStatusId,
                          1,
                          null
                        )}
                      </td>
                      <td>
                        {getIcon(
                          "project",
                          item?.workFlow?.workFlowStatusId,
                          2,
                          item?.assessmentTypeId
                        )}
                      </td>
                    </tr>
                  ))}
                  {data?.projects?.length === 0 && (
                    <tr>
                      <td>{"-"}</td>
                      <td>{"-"}</td>
                      <td>{"-"}</td>
                      <td>{"-"}</td>
                      <td>{"-"}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="flex-wrapper__modal-row">
            <div>
              <h3 className="table__title">
                Сведения о замерных блоках , связанных с данным договором
              </h3>
              <table
                className="table__table-view-modal table-info"
                style={{ width: "80%", marginBottom: "20px" }}
              >
                <tbody>
                  <tr>
                    <td style={{ color: "darkviolet", width: 70 }}>#</td>
                    <td style={{ color: "darkviolet", width: 70 }}>ID ЗБ</td>
                    <td style={{ color: "darkviolet", width: 400 }}>Регион</td>
                    <td style={{ color: "darkviolet", width: 200 }}>
                      Замерщик
                    </td>
                    <td style={{ color: "darkviolet", minWidth: 250 }}>
                      Плановый период замеров
                    </td>
                    <td style={{ color: "darkviolet", fontSize: 20 }}>
                      <FaFileDownload />
                    </td>
                  </tr>
                  {data?.measuringBlocks?.map((item, idx) => (
                    <tr>
                      <td>{idx + 1}</td>
                      <td>{item?.id || "-"}</td>
                      <td>
                        {item?.regions.map((r) => r.name).join(",\n") || "-"}
                      </td>
                      <td>
                        {item?.worker
                          ? item?.worker?.name + " " + item?.worker?.lastName
                          : "-"}
                      </td>
                      <td>
                        {item?.fromPlannedMeasurementsDate
                          ? `${toDateWithoutTime(
                              item?.fromPlannedMeasurementsDate
                            )} - ${toDateWithoutTime(
                              item?.toPlannedMeasurementsDate
                            )}`
                          : "-"}
                      </td>
                      <td>
                        {getIcon(
                          "location",
                          item?.workFlow?.workFlowStatusId,
                          1
                        )}
                      </td>
                    </tr>
                  ))}
                  {data?.measuringBlocks?.length === 0 && (
                    <tr>
                      <td>{"-"}</td>
                      <td>{"-"}</td>
                      <td>{"-"}</td>
                      <td>{"-"}</td>
                      <td>{"-"}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <button
          onClick={modalHandler}
          className={`button button--blue`}
          style={{ marginTop: "10px" }}
        >
          Закрыть окно
        </button>
      </>
    )
  );
};

export default ContractViewModal;
