import { useContext, useEffect, useState } from "react";
import ProjectContext from "../../../../../context/ProjectsContext";
import ModalWrapper from "../../../../../components/modals/ModalWrapper";
import moveToNextStageTender from "../../../../../utils/moveToNextStageTender";
import moveToPrevStageTender from "utils/moveToPrevStageTender";
import rejectTender from "utils/rejectTender";
import DraftTenderStage from "./Stage/DraftTenderStage";
import { fetchEmployees } from "entities/contracts/service/fetchEmployees";
import ApprovedTenderStage from "./Stage/ApprovedTenderStage";
import DocumentContext from "context/DocumentsContext";
import FiledTenderStage from "./Stage/FiledTenderStage";
import WonAndSigningTenderStage from "./Stage/WonAndSigningTenderStage";
import { fetchTenderById } from "utils/fetchTenderById";
import postTenderData from "utils/PostTenderData";
import StageBackWithComment from "./Stage/StageBackWithComment";
import ReadyToWorkProjectStage from "./Stage/ReadyToWorkProjectStage";
import postProjectData from "utils/PostProjectData";
import OrganizationAnalysisProjectStage from "./Stage/OrganizationAnalysisProjectStage";
import AcceptedToWorkProjectStage from "./Stage/AcceptedToWorkProjectStage";
import DocWithDateModal from "./Stage/DocWithDateModal";
import { dateToString, dateToStringWithTime } from "shared/formatDate";
import DateModal from "./Stage/DateModal";
import AuthContext from "context/AuthContext";
import { isTenderUser } from "utils/defineUser";
import Asterisk from "components/Asterisk";
import { Select } from "components/Select/Select";
import LocationContext from "context/LocationContext";
import FileInput from "pages/sout/accountant/common/FileInput";
import { showValidateMessage } from "settings/Api";
import { suspendProject } from "utils/suspendProject";

export const modalBtnColor = (type) => {
  switch (type) {
    case "separated-project":
      return "button--orange";
    case "tender":
      return "button--purple";
    default:
      break;
  }
};

function runPromisesSequentially(promises) {
  return promises.reduce((accumulator, currentPromise) => {
    return accumulator.then(() => currentPromise());
  }, Promise.resolve());
}

const rejectTitle = {
  AcceptedToWork: "в не поданные",
  Filed: "в не выигранные",
  WonAndSigning: "в не подписанные",
};

const informationStage = [
  { title: "Информация", link: "Information", require: true },
];

const printedStage = [
  { title: "Архив выгрузки", link: "Unload", require: true },
  { title: "Скан титульного листа", link: "TitleSheet", require: true },
];

const unloadingStage = [
  { title: "Файл выписки", link: "Statement", require: true },
];

const rejectStageArray = [
  "Filed",
  "AcceptedToWork",
  "WonAndSigning",
  "Verification",
  "Basket",
];
const nextStageArray = [
  "Estimated",
  "Draft",
  "AcceptedToWork",
  "Verification",
  "Finish",
];

function ChangeStageModal({
  project,
  move,
  setOpenModal,
  projectId,
  projectName,
  type,
  workFlowStatusId,
}) {
  const { setIsProjectMoved, setValues } = useContext(ProjectContext);
  const { getLocation } = useContext(LocationContext);
  const [entityName, setEntityName] = useState("");
  const [isShowNextStageBtn, setIsShowNextStageBtn] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isUploadError, setIsUploadError] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [tenderPrice, setTenderPrice] = useState(project?.ourPrice || null);
  const [tenderContractPrice, setTenderContractPrice] = useState(
    project?.price || null
  );
  const [projectExecutionProgramId, setProjectExecutionProgramId] = useState(
    project?.executionProgramId || null
  );
  const [tenderWinnerPrice, setTenderWinnerPrice] = useState(
    project?.winnerPrice || null
  );
  const [projectWorkspaceCount, setProjectWorkspaceCount] = useState(
    project?.workspaceCount || 0
  );
  const [tenderEmployee, setTenderEmployee] = useState(
    project?.personInCharge?.id || null
  );
  const [tenderContracts, setTenderContracts] = useState([]);
  const [commentRequest, setCommentRequest] = useState(
    project?.description || ""
  );
  const [comment, setComment] = useState(project?.failureDescription || "");
  const [workers, setWorkers] = useState([]);
  const [doc, setDoc] = useState([]);
  const [docInfo, setDocInfo] = useState([]);

  const [org, setOrg] = useState(project?.organizationId || null);
  const [filial, setFilial] = useState(project?.branchOfficeId || null);

  const [winner, setWinner] = useState(project?.winner);
  const [competitor, setCompetitor] = useState(
    type === "separated-project" ? project?.personInCharge : null
  );

  const { role } = useContext(AuthContext);
  const [acceptedToWorkDate, setAcceptedToWorkDate] = useState(
    project?.acceptedToWorkDate || null
  );
  const [informationReceiptDate, setInformationReceiptDate] = useState(
    project?.informationReceiptDate || null
  );
  const [plannedExecutionDate, setPlannedExecutionDate] = useState(
    project?.plannedExecutionDate || null
  );

  const [sentDate, setSentDate] = useState(
    project?.sendingForApprovalDate || null
  );
  const [dateOfSigning, setDateOfSigning] = useState(
    project?.titleSheetSignDate || null
  );
  const [unloadDate, setUnloadDate] = useState(project?.unloadingDate || null);
  const [performerAppointedDate, setPerformerAppointedDate] = useState(
    project?.performerAppointedDate || null
  );
  const [reportDate, setReportDate] = useState(
    project?.reportSendingDate || null
  );

  const convertDateTime = (date) => {
    return date ? dateToStringWithTime(date) : null;
  };

  const [approvedData, setApprovedData] = useState({});

  const [contactData, setContactData] = useState(project?.manager?.contactData);

  console.log(project);

  const addUser = () => {
    if (tenderEmployee && tenderEmployee != project?.personInCharge?.id) {
      return {
        ...workers?.find((item) => item?.id == tenderEmployee),
        departmentId: "TenderDepartment",
      };
    }
    return project?.personInCharge;
  };

  const [isUploading, setIsUploading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const { uploadDocument } = useContext(DocumentContext);

  const handleUpload = async (type, file, modelId) => {
    const formData = new FormData();
    console.log(file);
    formData.append("file", file?.file);
    await uploadDocument(
      "contracts",
      modelId,
      type,
      formData,
      setIsUploading,
      setIsSuccess
    );
    setTimeout(() => {
      setIsSuccess(false);
    }, []);
  };
  console.log(project);
  console.log(move);
  useEffect(() => {
    switch (type) {
      case "separated-project":
        setEntityName("проект");
        break;
      case "tender":
        setEntityName("тендер");
        break;
      default:
        break;
    }
    switch (move) {
      case "reject":
        setIsDisabled(rejectStageArray.includes(workFlowStatusId));
        if (
          (type === "separated-project" &&
            workFlowStatusId === "AcceptedToWork") ||
          workFlowStatusId === "Basket"
        )
          setIsDisabled(false);
        break;
      case "next":
        if (workers.length === 0)
          fetchEmployees("TenderDepartment").then((res) => setWorkers(res));
        setIsDisabled(!nextStageArray.includes(workFlowStatusId));
        break;
      default:
        setIsDisabled(false);
        break;
    }
  }, [move, type, workFlowStatusId, workers.length]);

  const modalTitle = () => {
    const dict = {
      next: "следующий",
      prev: "предыдущий",
    };
    switch (move) {
      case "reject":
        return (
          <h3 className="modal__description">
            Переместить {entityName}
            <b>&nbsp; {projectName}</b>{" "}
            {rejectTitle[workFlowStatusId] || type === "tender"
              ? "?"
              : workFlowStatusId === "InformationRequest"
              ? "в приостановленные?"
              : workFlowStatusId === "Verification"
              ? "в ошибки?"
              : workFlowStatusId === "PrintedAndSent"
              ? "в зависшие?"
              : "в корзину?"}
          </h3>
        );
      case "prev":
        return (
          <h3 className="modal__description">
            Переместить {entityName}
            <b>&nbsp; {projectName}</b> на предыдущий этап?
          </h3>
        );
      case "suspend":
        return (
          <h3 className="modal__description">
            Переместить {entityName}
            <b>&nbsp; {projectName}</b> в приостановленные?
          </h3>
        );
      default: {
        if (workFlowStatusId === "Finish") {
          return (
            <h3 className="modal__description">
              Перевести {entityName}
              <b>&nbsp;{projectName}&nbsp;</b>в архив?
            </h3>
          );
        } else
          return (
            <h3 className="modal__description">
              Перевести {entityName}
              <b>&nbsp;{projectName}&nbsp;</b>
              на {dict[move]} этап?
            </h3>
          );
      }
    }
  };

  const projectDataHandler = async (e) => {
    e.preventDefault();
    let res, message;
    switch (move) {
      case "next":
        message = `${entityName} не был переведён на следующий этап`;
        setIsLoading(true);
        await postProjectData(
          {
            ...project,
            executionProgramId: projectExecutionProgramId,
            workspaceCount: projectWorkspaceCount,
            organization: null,
            organizationId: Number(org),
            plannedExecutionDate: plannedExecutionDate
              ? dateToString(plannedExecutionDate)
              : null,
            branchOfficeId: Number(filial),
            informationReceiptDate: informationReceiptDate
              ? dateToString(informationReceiptDate)
              : null,
            performerAppointedDate: performerAppointedDate
              ? dateToString(performerAppointedDate)
              : null,
            sendingForApprovalDate: sentDate ? dateToString(sentDate) : null,
            reportSendingDate: reportDate ? dateToString(reportDate) : null,
            titleSheetSignDate: dateOfSigning
              ? dateToString(dateOfSigning)
              : null,
            unloadingDate: unloadDate ? dateToString(unloadDate) : null,
            acceptedToWorkDate: acceptedToWorkDate
              ? dateToString(acceptedToWorkDate)
              : null,
            personInCharge:
              parseInt(project?.branchOfficeId) !== parseInt(filial)
                ? null
                : competitor,
          },
          "edit",
          project?.id
        )
          .then(async (r) => {
            if (r?.status === 400) showValidateMessage(r?.data?.errors);
            res = await moveToNextStageTender(
              projectId,
              "separated-project",
              workFlowStatusId
            );
          })
          .catch((e) => alert("Произошла ошибка при обновлении данных", e))
          .finally(() => setIsLoading(false));
        break;
      case "prev":
        message = `${entityName} не был переведён на предыдущий этап`;
        setIsLoading(true);
        await postProjectData(
          {
            ...project,
            organization: null,
            organizationId:
              workFlowStatusId === "ExecutorsAnalysis" ? null : Number(org),
            branchOfficeId:
              workFlowStatusId === "ExecutorsAnalysis" ? null : Number(filial),
            personInCharge:
              workFlowStatusId === "InformationRequest" ? null : competitor,
          },
          "edit",
          project?.id
        )
          .then(async (r) => {
            if (r?.status === 400) showValidateMessage(r?.data?.errors);
            res = await moveToPrevStageTender(
              projectId,
              "separated-project",
              workFlowStatusId
            ).catch((e) => alert("Произошла ошибка при обновлении данных", e));
          })
          .catch((e) => alert("Произошла ошибка при обновлении данных", e))
          .finally(() => setIsLoading(false));
        break;
      case "reject":
        message = `${entityName} не был удалён`;
        res = await rejectTender(
          workFlowStatusId === "Basket" ? project?.id : projectId,
          type,
          workFlowStatusId
        );
        break;
      case "suspend":
        message = `${entityName} не был приостановлен`;
        res = await suspendProject(projectId);
        break;
      default:
        break;
    }
    if (res.status === "200" || res.ok) {
      setIsProjectMoved((prevState) => !prevState);

      setOpenModal(false);
    } else {
      alert(`Ошибка! ${message}`);
      setOpenModal(false);
    }
  };

  useEffect(() => {
    if (workFlowStatusId === "Verification") setIsDisabled(!isUploadError);
  }, [isUploadError]);

  const tenderDataHandler = async (e) => {
    e.preventDefault();
    let res, message;
    switch (move) {
      case "next":
        message = `${entityName} не был переведён на следующий этап`;
        setIsLoading(true);
        const contractsID = project?.contracts?.map((item) => item?.id);
        await postTenderData(
          {
            ...project,
            ...approvedData,
            auctionDate: approvedData.auctionDate
              ? convertDateTime(approvedData?.auctionDate)
              : project?.auctionDate,
            acceptingOffersDeadline: approvedData.acceptingOffersDeadline
              ? convertDateTime(approvedData?.acceptingOffersDeadline)
              : project?.acceptingOffersDeadline,
            ourPrice: tenderPrice,
            price: tenderContractPrice,
            personInChargeId: addUser()?.id,
            personInCharge: { id: addUser()?.id },
            contracts: [...project?.contracts, ...tenderContracts],
            contractsCount: tenderContracts.length,
            failureDescription: comment,
            description: commentRequest,
            manager: { ...project?.manager, contactData: contactData },
          },
          "edit",
          project?.id
        )
          .then(async (r) => {
            if (r?.status === 400) showValidateMessage(r?.data?.errors);
            res = await moveToNextStageTender(
              projectId,
              type,
              workFlowStatusId
            );
          })
          .finally(() => {
            setIsLoading(false);
            setValues({});
          });

        if (workFlowStatusId === "WonAndSigning") {
          fetchTenderById(project?.id).then(async (res) => {
            if (tenderContracts?.length > 0) {
              const promises = [];
              let i = 0;
              let contracts = res?.contracts?.filter(
                (item) => !contractsID.includes(item?.id)
              );
              for (let contract of contracts) {
                const currentDoc = doc[i];
                promises.push(() =>
                  handleUpload("Contract", currentDoc, contract?.id)
                );
                docInfo.map((item) => {
                  if (item.index === i) {
                    item?.doc?.map((docItem) => {
                      promises.push(() =>
                        handleUpload("Info", docItem, contract?.id)
                      );
                    });
                  }
                });

                i++;
              }
              runPromisesSequentially(promises);
            }
          });
        }
        break;
      case "prev":
        message = `${entityName} не был переведён на предыдущий этап`;
        setIsLoading(true);
        res = await moveToPrevStageTender(projectId, type).finally(() =>
          setIsLoading(false)
        );
        break;
      case "reject":
        message = `${entityName} не был удалён`;
        setIsLoading(true);
        await postTenderData(
          {
            ...project,
            contractPrice:
              workFlowStatusId == "Filed" ? null : project?.contractPrice,
            winnerPrice: tenderWinnerPrice,
            failureDescription: comment,
            description: commentRequest,
            winner: winner,
            contracts: [],
          },
          "edit",
          project?.id
        )
          .then(
            async () =>
              (res = await rejectTender(projectId, type).catch((e) =>
                alert("Произошла ошибка при обновлении данных", e)
              ))
          )
          .finally(() => {
            setIsLoading(false);
          });
        break;
      default:
        break;
    }
    console.log(res);
    if (res?.status === "200" || res?.ok) {
      console.log(546546546);
      setIsProjectMoved((prevState) => !prevState);
      setOpenModal(false);
    } else {
      setOpenModal(false);
    }
  };

  const tenderModalContent = (stage) => {
    switch (stage) {
      case "Draft":
        return (
          <DraftTenderStage
            move={move}
            setIsDisabled={setIsDisabled}
            price={tenderPrice}
            comment={commentRequest}
            setComment={setCommentRequest}
            setPrice={setTenderPrice}
          />
        );
      case "AcceptedToWork":
        return (
          move == "reject" && (
            <StageBackWithComment
              move={move}
              comment={comment}
              setIsDisabled={setIsDisabled}
              setComment={setComment}
            />
          )
        );
      case "Approved":
        return (
          <ApprovedTenderStage
            move={move}
            isDisabled={isDisabled}
            emp={tenderEmployee}
            setIsDisabled={setIsDisabled}
            setEmp={setTenderEmployee}
            project={project}
            data={approvedData}
            setData={setApprovedData}
            contactData={contactData}
            setContactData={setContactData}
          />
        );
      case "Filed":
        return (
          <FiledTenderStage
            winner={winner}
            setWinner={setWinner}
            move={move}
            setIsDisabled={setIsDisabled}
            price={move === "next" ? tenderContractPrice : tenderWinnerPrice}
            setPrice={
              move === "next" ? setTenderContractPrice : setTenderWinnerPrice
            }
          />
        );
      case "WonAndSigning":
        return move === "next" ? (
          <WonAndSigningTenderStage
            move={move}
            setDoc={setDoc}
            docInfo={docInfo}
            setDocInfo={setDocInfo}
            contractCount={project?.contractsCount}
            doc={doc}
            type={type}
            setDisabledNextStageBtn={setIsDisabled}
            isShowBtn={!isShowNextStageBtn}
            setShowBtn={setIsShowNextStageBtn}
            project={project}
            contracts={project?.contracts}
            newContracts={tenderContracts}
            setNewContracts={setTenderContracts}
          />
        ) : move == "reject" ? (
          <StageBackWithComment
            move={move}
            comment={commentRequest}
            setIsDisabled={setIsDisabled}
            setComment={setCommentRequest}
          />
        ) : null;
      default:
        break;
    }
  };

  const projectModalContent = (stage) => {
    switch (stage) {
      case "OrganizationsAnalysis":
        return (
          <ReadyToWorkProjectStage
            setIsDisabled={setIsDisabled}
            org={org}
            filial={filial}
            setFilial={setFilial}
            setOrg={setOrg}
          />
        );
      case "ExecutorsAnalysis":
        return (
          <OrganizationAnalysisProjectStage
            setIsDisabled={setIsDisabled}
            filial={filial}
            setDate={setPerformerAppointedDate}
            date={performerAppointedDate}
            competitor={competitor}
            setCompetitor={setCompetitor}
          />
        );
      case "InformationRequest":
        return (
          <DateModal
            setIsDisabled={setIsDisabled}
            date={informationReceiptDate}
            setDate={setInformationReceiptDate}
            title="Дата получения информации"
          />
        );
      case "WaitingForRequest":
        return (
          <DateModal
            setIsDisabled={setIsDisabled}
            date={plannedExecutionDate}
            setDate={setPlannedExecutionDate}
            title="Плановая дата исполнения проекта"
          />
        );
      case "ReadyToWork":
        return (
          <DateModal
            setIsDisabled={setIsDisabled}
            date={acceptedToWorkDate}
            setDate={setAcceptedToWorkDate}
            title="Дата приёма в работу"
          />
        );
      case "Verification":
        console.log(move);
        return (
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            {move === "reject" ? (
              <FileInput
                modelId={project?.id}
                entity="separated-projects"
                link="ErrorsScreenshot"
                title="Скриншот ошибок"
                setIsUpload={setIsUploadError}
              />
            ) : null}
          </div>
        );
      case "Waiting":
        return (
          <DateModal
            setIsDisabled={setIsDisabled}
            date={sentDate}
            setDate={setSentDate}
            title="Дата отправки на согласование"
          />
        );
      case "Agreement":
        return (
          <>
            <div style={{ maxWidth: 320, margin: "0 auto" }}>
              <label
                className="modal__input-title"
                style={{ maxWidth: 320, margin: "0 auto" }}
              >
                Программа выполнения:
              </label>
              <Select
                onChange={(e) => setProjectExecutionProgramId(e.target.value)}
                dataSrc="local"
                label={""}
                name="executionProgramId"
                value={projectExecutionProgramId}
                data={{
                  values: {
                    None: "Нет",
                    LaborExpert_3_0: "ТрудЭксперт",
                    Attestation_5_1: "Аттестация",
                  },
                }}
              />
            </div>
            <>
              <label
                className="modal__input-title"
                style={{ maxWidth: 200, margin: "0 auto" }}
              >
                Кол-во РМ:
                <Asterisk />
              </label>
              <input
                style={{ maxWidth: 200, margin: "0 auto" }}
                className={"input"}
                type="text"
                name="workspaceCount"
                value={projectWorkspaceCount}
                onChange={(e) => setProjectWorkspaceCount(e.target.value)}
              />
            </>
            <DocWithDateModal
              setIsDisabled={setIsDisabled}
              id={project?.id}
              otherCheck={Boolean(projectWorkspaceCount)}
              dataArr={
                project?.assessmentTypeId ===
                "WorkingConditionsSpecialAssesment"
                  ? [
                      {
                        title: "База",
                        link: "DB",
                        require:
                          projectExecutionProgramId === "LaborExpert_3_0"
                            ? false
                            : true,
                      },
                      {
                        title: "Отчет Word",
                        link: "WordReport",
                        require: true,
                      },
                    ]
                  : [{ title: "Отчет Word", link: "WordReport", require: true }]
              }
              date={reportDate}
              setDate={setReportDate}
              titleDate={"Дата отправки отчета"}
            />
          </>
        );
      case "Errors":
        return move === "prev" ? (
          <DocWithDateModal
            setIsDisabled={setIsDisabled}
            id={project?.id}
            dataArr={printedStage}
            date={dateOfSigning}
            setDate={setDateOfSigning}
            titleDate={"Дата подписания титульного листа"}
          />
        ) : null;
      case "PrintedAndSent":
        return (
          <DocWithDateModal
            setIsDisabled={setIsDisabled}
            id={project?.id}
            dataArr={printedStage}
            date={dateOfSigning}
            setDate={setDateOfSigning}
            titleDate={"Дата подписания титульного листа"}
          />
        );
      case "UnloadingDocuments":
        return (
          <DocWithDateModal
            setIsDisabled={setIsDisabled}
            id={project?.id}
            dataArr={unloadingStage}
            date={unloadDate}
            setDate={setUnloadDate}
            titleDate={"Дата выгрузки"}
          />
        );
      default:
        break;
    }
  };

  return (
    <ModalWrapper>
      <button
        className="modal__close-button close-button"
        onClick={() => setOpenModal(false)}
      >
        &#10005;
      </button>
      {type === "tender"
        ? tenderModalContent(workFlowStatusId)
        : move === "next"
        ? projectModalContent(workFlowStatusId)
        : (move === "reject" && workFlowStatusId === "Verification") ||
          (move === "prev" && workFlowStatusId === "Errors")
        ? projectModalContent(workFlowStatusId)
        : null}
      {move && isShowNextStageBtn && modalTitle()}
      {type && isShowNextStageBtn && (
        <div className="modal__buttons-wrapper">
          <button
            className={`button ${modalBtnColor(type)}`}
            disabled={isDisabled || isLoading}
            onClick={(e) =>
              type === "tender" ? tenderDataHandler(e) : projectDataHandler(e)
            }
          >
            Да
          </button>
          <button
            className={`button ${modalBtnColor(type)}`}
            onClick={() => setOpenModal(false)}
          >
            Нет
          </button>
        </div>
      )}
    </ModalWrapper>
  );
}

export default ChangeStageModal;
