import { useState, useEffect, memo, useContext } from "react";
import Sidebar from "components/Sidebar";
import { useNavigate } from "react-router-dom";
import { isAdmin } from "utils/defineUser";
import { validate } from "shared/validate";
import { buildValidation } from "shared/buildValidation";
import AuthContext from "context/AuthContext";
import { fetchLocationByID } from "entities/locations/service/fetchLocationById";
import LocationContext from "context/LocationContext";
import Spinner from "components/Spinner";
import { addOrEditLocation } from "entities/locations/service/createLocation";
import SelectRegions from "components/common/SelectRegions";
import { TextInput } from "components/TextInput/TextInput";
import { Grommet, Grid, Box } from "grommet";
import { DatePicker } from "components/DateInput/DateInput";
import InputDiapason from "components/InputDiapason/InputDiapason";
import { fetchTenderById } from "utils/fetchTenderById";
import { fetchContractByID } from "entities/contracts/service/fetchContractById";
import fetchWorkers from "utils/fetchWorkers";
import { LabelTitle } from "shared/ui/LabelTitle/LabelTitle";
import { Select } from "components/Select/Select";
import { Checkboxes } from "components/Checkboxes/Checkboxes";
import { Text } from "shared/ui/Text/Text";
import { InputWrapper } from "shared/ui/InputWrapper/InputWrapper";
import { LocationTypeChoiceModal } from "entities/locations/modals/LocationTypeChoiceModal";
import { dateToString } from "shared/formatDate";

const ChangeLocations = memo(({ type, locationID }) => {
  const { updateToken } = useContext(AuthContext);
  const navigate = useNavigate();
  const [workers, setWorkers] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const {
    location,
    setIsCompare,
    setLocation,
    locationType,
    setLocationAll,
    setLocationType,
    updateLocation,
    editPageStatus,
    setEditPageStatus,
  } = useContext(LocationContext);

  const [tenderId, setTenderId] = useState();
  const [isSearching, setIsSearching] = useState(false);
  const [customerNameViaINN, setCustomerNameViaINN] = useState();

  const changeLocations = (location, type) => {
    addOrEditLocation(
      location,
      () => {
        setLocationType("");
        setEditPageStatus("");
        navigate(-1);
      },
      type,
      locationType || editPageStatus
    );
    setIsCompare(false);
    setLocationAll([]);
  };

  const getLocationByID = (locationID) => {
    fetchLocationByID(locationID, editPageStatus).then((res) => {
      if (res === 401 || res === 403) {
        updateToken();
        return;
      }
      setLocation({ ...res });
      setIsLoading(false);
    });
  };

  const getContractByID = (contractId) => {
    updateLocation("contractId", contractId);
    setIsSearching(true);
    fetchContractByID(contractId)
      .then((res) => {
        updateLocation("contract", res);
        setCustomerNameViaINN(res.customer.name);
        setIsSearching(false);
      })
      .catch((e) => {
        updateLocation("contract", null);
        setCustomerNameViaINN("");
        setIsSearching(false);
      });
  };

  const getTenderById = (tenderId) => {
    setIsSearching(true);
    setTenderId(tenderId);
    if (!tenderId) {
      updateLocation("tender", null);
      setCustomerNameViaINN("");
      setIsSearching(false);
      return;
    }
    fetchTenderById(tenderId)
      .then((res) => {
        updateLocation("tender", res);
        setCustomerNameViaINN(res.manager.name);
        setIsSearching(false);
      })
      .catch((e) => {
        updateLocation("tender", null);
        setCustomerNameViaINN("");
        setIsSearching(false);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    fetchWorkers(setWorkers);
    if (type) {
      switch (type) {
        case "edit":
          setLocationType(editPageStatus);
          getLocationByID(locationID);
          break;
        case "add":
          setIsLoading(false);
          break;
        default:
          break;
      }
    }
  }, []);

  const [displayErrors, setDisplayErrors] = useState(false);

  const inputChangeHandler = (e) => {
    const { name, value } = e.target;
    updateLocation(name, value);
  };

  const workersHandler = (e) => {
    updateLocation("worker", { id: e.target.value });
  };

  const handleSubmit = (e) => {
    let conditions = buildValidation({
      assesmentTypes: location.assesmentTypeId,
      customerINN: location.CustomerINN,
      personInChargeId: true,
      regions: location.regions,
      customerNameViaINN: customerNameViaINN,
    });
    validate(e, conditions, () =>
      changeLocations(
        {
          ...location,
          worker: location?.worker?.id ? location?.worker : null,
          fromPlannedMeasurementsDate: location.fromPlannedMeasurementsDate
            ? dateToString(location.fromPlannedMeasurementsDate)
            : null,
          toPlannedMeasurementsDate: location.toPlannedMeasurementsDate
            ? dateToString(location.toPlannedMeasurementsDate)
            : null,
        },
        type
      )
    );
    setLocationAll([]);
  };

  return !locationType ? (
    <LocationTypeChoiceModal
      close={() => navigate("/sout/locations/potential")}
    />
  ) : (
    <>
      <main className="workspace">
        <Sidebar />
        <div className="workspace__content">
          <section className="workspace__wrapper">
            <h2
              className="title workspace__page-title"
              style={{ marginBottom: 15 }}
            >
              <span
                style={{
                  fontSize: "22px",
                  lineHeight: "1px",
                  marginRight: "10px",
                }}
              >
                &#10010;
              </span>
              {type === "add" ? "Добавить" : "Редактировать"}{" "}
              {
                {
                  Contract: "договорной",
                  Potential: "потенциальный",
                }[locationType]
              }{" "}
              замерный блок
            </h2>
            {isLoading ? (
              <Spinner />
            ) : (
              <form
                className={`form contracts__form ${
                  displayErrors ? "form--isValid" : ""
                }`}
                onSubmit={(e) => handleSubmit(e)}
              >
                <fieldset className="workspace__ensure-wrapper contracts__ensure-wrapper">
                  <Grommet>
                    <Grid width="1000px" columns="300px" gap="20px">
                      <Box gap="5px">
                        <InputWrapper>
                          {locationType === "Potential" && (
                            <div style={{ marginBottom: 15 }}>
                              <TextInput
                                label="ID тендера"
                                type="number"
                                alignLabel="left"
                                labelHeight="24px"
                                value={
                                  type === "edit"
                                    ? location?.tender?.id
                                    : tenderId
                                }
                                onChange={(e) => getTenderById(e.target.value)}
                                disabled={type === "edit"}
                                style={{
                                  width: "100px",
                                  marginRight: "20px",
                                }}
                              />
                            </div>
                          )}
                          {locationType === "Contract" && (
                            <div style={{ marginBottom: 10 }}>
                              <TextInput
                                label="ID договора"
                                type="number"
                                alignLabel="left"
                                labelHeight="24px"
                                value={location.contractId}
                                onChange={(e) =>
                                  getContractByID(e.target.value)
                                }
                                disabled={type === "edit"}
                                style={{
                                  width: "100px",
                                  marginRight: "20px",
                                }}
                              />
                            </div>
                          )}
                          {location?.assessmentTypeList && (
                            <Checkboxes
                              label="Тип оценки"
                              marginTop={-15}
                              values={[
                                ["WorkingConditionsSpecialAssesment", "СОУТ"],
                                ["ProductionControl", "ПК"],
                                ["ProfessionalRisks", "ОПР"],
                              ]}
                              data={location.assessmentTypeList}
                              isChecked={(key) =>
                                location.assessmentTypeList.findIndex(
                                  (x) => x.id === key
                                ) !== -1
                              }
                              updateValues={(key) => {
                                if (
                                  location.assessmentTypeList.findIndex(
                                    (x) => x.id === key
                                  ) !== -1
                                ) {
                                  updateLocation(
                                    "assessmentTypeList",
                                    location.assessmentTypeList.filter(
                                      (x) => x.id !== key
                                    )
                                  );
                                } else {
                                  updateLocation("assessmentTypeList", [
                                    ...location.assessmentTypeList,
                                    { id: key },
                                  ]);
                                }
                              }}
                              errorMessage="Выберите хотя бы один тип"
                            />
                          )}
                        </InputWrapper>

                        <label
                          className="workspace__input-title"
                          style={{ maxWidth: "380px", marginBottom: "20px" }}
                        >
                          <p>Наименование заказчика</p>
                          {type === "edit" ? (
                            <p>
                              {
                                {
                                  Contract: location?.contract?.customer?.name,
                                  Potential: location?.tender?.manager?.name,
                                }[locationType]
                              }
                            </p>
                          ) : customerNameViaINN === undefined ? (
                            <p>
                              Введите ID{" "}
                              {
                                {
                                  Contract: "договора",
                                  Potential: "тендера",
                                }[locationType]
                              }
                              ...
                            </p>
                          ) : isSearching ? (
                            <p>Поиск по базе данных...</p>
                          ) : customerNameViaINN ? (
                            <p>{customerNameViaINN}</p>
                          ) : (
                            <Text theme="red">Заказчик не найден!</Text>
                          )}
                          <p style={{ marginTop: "10px" }}>ИНН заказчика</p>
                          {locationType === "Potential" && (
                            <p>{location?.tender?.manager?.inn}</p>
                          )}
                          {locationType === "Contract" && (
                            <p>{location?.contract?.customer?.inn}</p>
                          )}
                        </label>
                        <TextInput
                          alignLabel="left"
                          labelHeight="24px"
                          label="Доп. наименование"
                          type="text"
                          name="additionalName"
                          value={location?.additionalName}
                          onChange={(e) => inputChangeHandler(e)}
                        />
                        <label className="workspace__input-title contracts__input-title">
                          <SelectRegions
                            label={
                              <span
                                style={{ color: "tomato", fontSize: "16px" }}
                              >
                                Примеч.можно ввести только 1 регион
                              </span>
                            }
                            required={true}
                            regions={location?.regions}
                            updateRegions={(updatedRegions) => {
                              if (location.regions.length >= 1) {
                                alert("Можно добавить только 1 регион");
                              } else {
                                updateLocation("regions", updatedRegions);
                              }
                            }}
                            disabled={location?.regions.length >= 1}
                            isSearchBar={false}
                          />
                        </label>
                      </Box>
                      <Box gap="5px">
                        {locationType === "Contract" && (
                          <InputDiapason
                            width="100%"
                            title="Плановые даты замеров"
                            type="date"
                            name={[
                              "fromPlannedMeasurementsDate",
                              "toPlannedMeasurementsDate",
                            ]}
                            value={[
                              location?.fromPlannedMeasurementsDate || null,
                              location?.toPlannedMeasurementsDate || null,
                            ]}
                            onChange={[
                              (date) =>
                                setLocation({
                                  ...location,
                                  fromPlannedMeasurementsDate: date,
                                }),
                              (date) =>
                                setLocation({
                                  ...location,
                                  toPlannedMeasurementsDate: date,
                                }),
                            ]}
                            placeholder="дд.мм.гггг"
                            isRequired={true}
                          />
                        )}
                        {locationType === "Contract" && (
                          <div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginBottom: "5px",
                              }}
                            >
                              <label
                                className="workspace__input-title contracts__input-title required"
                                style={{ width: "45%" }}
                              >
                                <LabelTitle>Дата замеров</LabelTitle>
                                <DatePicker
                                  className="input"
                                  name="measurementsDate"
                                  value={location?.measurementsDate || null}
                                  onChange={(date) =>
                                    updateLocation("measurementsDate", date)
                                  }
                                  min={new Date("01.01.2000")}
                                />
                              </label>
                            </div>
                            <Select
                              label="Замерщики"
                              dataSrc="server"
                              name="worker"
                              data={workers}
                              alignLabel={"left"}
                              onChange={(e) => workersHandler(e)}
                              value={location?.worker?.id || { id: "null" }}
                              // disabled
                            />
                          </div>
                        )}
                      </Box>
                      <Box gap="5px">
                        {locationType === "Contract" && (
                          <>
                            <label className="workspace__input-title contracts__input-title">
                              Комментарий к исполнению
                              <textarea
                                style={{ resize: "none" }}
                                className="textarea"
                                cols="50"
                                rows="5"
                                name="executionDescription"
                                value={location.executionDescription}
                                onChange={(e) => inputChangeHandler(e)}
                                disabled={false}
                              />
                            </label>
                          </>
                        )}
                        {locationType === "Contract" && (
                          <label className="workspace__input-title contracts__input-title">
                            Контактные данные
                            <textarea
                              style={{ resize: "none" }}
                              className="textarea"
                              cols="50"
                              rows="5"
                              name="contactData"
                              value={location.contactData}
                              onChange={(e) => inputChangeHandler(e)}
                            />
                          </label>
                        )}
                      </Box>
                    </Grid>
                  </Grommet>
                </fieldset>
                <div
                  className="line"
                  style={{
                    borderBottom: "2px solid #000",
                    margin: "10px 0 15px 0",
                  }}
                ></div>
                <div
                  className="buttons"
                  style={{
                    display: "flex",
                    width: "300px",
                    justifyContent: "space-between",
                    margin: "auto",
                  }}
                >
                  <button
                    className="button button--olive"
                    type="submit"
                    onClick={() => {
                      setDisplayErrors(true);
                    }}
                  >
                    {type === "add" ? "Добавить" : "Сохранить"}
                  </button>
                  <button
                    className="button button--olive"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(-1);
                    }}
                  >
                    Отмена
                  </button>
                </div>
              </form>
            )}
          </section>
        </div>
      </main>
    </>
  );
});

export default ChangeLocations;
