import GroupButton from "components/common/GroupButton";
import SelectRegions from "components/common/SelectRegions";
import { DatePicker } from "components/DateInput/DateInput";
import EntitiesTable from "components/EntitiesTable/EntitiesTable";
import EntitiesTableItem from "components/EntitiesTable/EntitiesTableItem";
import React, { useState, useEffect } from "react";
import { formatDate } from "shared/formatDate";

const asessementTypes = {
  ProductionControl: "ПК",
  WorkingConditionsSpecialAssesment: "СОУТ",
  ProfessionalRisks: "ОПР",
};

const LocationsCreationContractStage = ({
  mesBlocks,
  contract,
  initCount,
  setMesBlocks,
  setIsDisabled,
  setIsVisible,
}) => {
  const [count, setCount] = useState(1);
  const [disable, setDisable] = useState(true);
  const [measuringBlock, setMeasuringBlock] = useState({});
  const [arr, setArr] = useState([]);
  const [asses, setAsses] = useState([]);
  const [assesmentTypeArray, setAssesmentTypeArray] = useState([
    {
      value: "WorkingConditionsSpecialAssesment",
      label: "СОУТ",
      disabled: false,
    },
    { value: "ProfessionalRisks", label: "ОПР", disabled: false },
    { value: "ProductionControl", label: "ПК", disabled: false },
  ]);

  const updateRegions = (updatedRegions) => setArr(updatedRegions);

  useEffect(() => {
    checkMeasuringBlock();
  });

  useEffect(() => {
    setIsDisabled(!contract?.measuringBlocks?.length > 0);
  }, [contract]);

  const nextMeasuringBlockHandler = () => {
    setMesBlocks([
      ...mesBlocks,
      { ...measuringBlock, regions: arr, assessmentTypeList: asses },
    ]);
    setIsDisabled(false);
    setArr([]);
    setAsses([]);
    setMeasuringBlock({});
  };

  console.log(mesBlocks);

  const checkMeasuringBlock = () => {
    setDisable(
      !(
        arr.length !== 0 &&
        measuringBlock?.fromPlannedMeasurementsDate &&
        measuringBlock?.toPlannedMeasurementsDate
      )
    );
  };

  return (
    <div
      style={{
        width: 700,
        maxHeight: 500,
        overflowY: "scroll",
        overflowX: "hidden",
        scrollbarWidth: "thin",
      }}
    >
      {contract?.measuringBlocks?.length > 0 && (
        <EntitiesTable
          title={"Существующие ЗБ"}
          list={contract?.measuringBlocks}
          headers={["#", "ID", "Регион", "Плановый период замеров"]}
          printFunc={(item, idx) => (
            <EntitiesTableItem
              values={[
                idx + 1,
                item?.id,
                item?.regions[0]?.name || "-",
                `${
                  item?.fromPlannedMeasurementsDate
                    ? formatDate(item?.fromPlannedMeasurementsDate)
                    : ""
                } - ${
                  item?.toPlannedMeasurementsDate
                    ? formatDate(item?.toPlannedMeasurementsDate)
                    : ""
                }`,
              ]}
              key={item?.id}
            />
          )}
        />
      )}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <p className="measuringBlockModalCount">{`Количество проектов: ${Number(
          contract?.measuringBlocks?.length || 0
        )}`}</p>
        <label>
          Без замеров*
          <input
            style={{ marginLeft: 5 }}
            type="checkbox"
            onChange={(e) => {
              if (e.target.checked == true) {
                setIsDisabled(false);
              }
            }}
          />
        </label>
      </div>
      <div className="LocationsCreatModalContent">
        <div style={{ width: 250, marginTop: -25 }}>
          {/* <GroupButton
            entity={"locations"}
            onChangeHandler={handleCheckboxChange}
            type={"checkbox"}
            values={asses}
            title={"Тип оценки"}
            info={assesmentTypeArray}
          /> */}
          <SelectRegions regions={arr} updateRegions={updateRegions} />
        </div>
        <div className="dataPickLocationsCreation">
          <label className="locDate">С</label>
          <DatePicker
            className="input"
            name="fromPlannedMeasurementsDate"
            value={measuringBlock.fromPlannedMeasurementsDate || null}
            onChange={(date) =>
              setMeasuringBlock({
                ...measuringBlock,
                fromPlannedMeasurementsDate: date,
              })
            }
            required={true}
            min={new Date("01.01.2000")}
          />
          <label className="locDate">ПО</label>
          <DatePicker
            className="input"
            name="toPlannedMeasurementsDate"
            value={measuringBlock.toPlannedMeasurementsDate || null}
            onChange={(date) =>
              setMeasuringBlock({
                ...measuringBlock,
                toPlannedMeasurementsDate: date,
              })
            }
            required={true}
            min={new Date("01.01.2000")}
          />
        </div>
      </div>
      <button
        className={"button--olive button mesBtn"}
        onClick={nextMeasuringBlockHandler}
        disabled={disable}
      >
        Сoздать замерный блок
      </button>
      {mesBlocks?.length > 0 && (
        <EntitiesTable
          title={"Созданные ЗБ"}
          list={mesBlocks}
          headers={["#", "ID", "Регион", "Плановый период замеров"]}
          printFunc={(item, idx) => (
            <EntitiesTableItem
              values={[
                idx + 1,
                item?.id,
                item?.regions[0]?.name || "-",
                `${
                  item?.fromPlannedMeasurementsDate
                    ? formatDate(item?.fromPlannedMeasurementsDate)
                    : ""
                } - ${
                  item?.toPlannedMeasurementsDate
                    ? formatDate(item?.toPlannedMeasurementsDate)
                    : ""
                }`,
              ]}
              key={item?.id}
            />
          )}
        />
      )}
    </div>
  );
};

export default LocationsCreationContractStage;
