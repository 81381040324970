import React from "react";

const EntitiesTableItem = ({ values, canDelete = false, deleteFunc }) => {
  return (
    <tr className="entitiesTableItemRow">
      {values?.map((item, id) => (
        <td className="entitiesTableItem" key={id}>
          {item}
        </td>
      ))}
      {canDelete && (
        <span
          style={{
            color: "red",
            position: "absolute",
            cursor: "pointer",
            marginLeft: 3,
            marginTop: 3,
          }}
          onClick={deleteFunc}
        >
          ✖
        </span>
      )}
    </tr>
  );
};

export default EntitiesTableItem;
