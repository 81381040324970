const config = {
    devURL: 'https://apidev.erptest.ru',
    testURL: 'https://api.erptest.ru',
    testedURL: 'https://apitested.erptest.ru',
    prodURL: 'https://apierp.prcot.ru'
};

export const api = (url, options) => {
    const fullURL = `${config.testURL}${url}`;
    
    return fetch(fullURL, options);
};

export const showValidateMessage = (errors) => {
    let message = 'ОШИБКА ВАЛИДАЦИИ!!! \n\n';

    errors?.map((err) => {
        message += `● ${err?.errorMessage} \n`
    })

    alert(message)
};