import { valueHandler } from "shared/valueHandler";
import ShowDeadlines from "components/ShowDedlines/ShowDeadlines";
import { workspaceCountView } from "./WorkspaceCountView";
import { projectsSlashWorkspaces } from "./projectsSlashWorkspaces";

const WorkloadCell = ({ project, workloadData, isGeneral, isTotal }) => {
  console.log("aboba", project);
  console.log(workloadData);

  if (isTotal) {
    if (project) {
      return (
        <table style={{ width: "100%" }}>
          {workspaceCountView(
            [
              project?.acceptedToWork?.allSummed?.workspaceCount,
              project?.informationCollection?.allSummed?.workspaceCount,
              project?.readyToWork?.allSummed?.workspaceCount,
              project?.waitingAndAgreement?.allSummed?.workspaceCount,
              project?.finish?.allSummed?.workspaceCount,
              project?.waitingForRequest?.allSummed?.workspaceCount,
            ].reduce((acc, i) => acc + valueHandler(i), 0),
            project?.acceptedToWork?.allSummed?.withX ||
              project?.informationCollection?.allSummed?.withX ||
              project?.readyToWork?.allSummed?.withX ||
              project?.waitingAndAgreement?.allSummed?.withX ||
              project?.finish?.allSummed?.withX ||
              project?.waitingForRequest?.allSummed?.withX
          )}
        </table>
      );
    }
  }

  if (isGeneral) {
    return (
      project && (
        <table style={{ width: "100%" }}>
          {workspaceCountView(
            [
              project?.acceptedToWork?.allSummed?.workspaceCount,
              project?.informationCollection?.allSummed?.workspaceCount,
              project?.readyToWork?.allSummed?.workspaceCount,
              project?.waitingForRequest?.allSummed?.workspaceCount,
            ].reduce((acc, i) => acc + valueHandler(i), 0),
            project?.acceptedToWork?.allSummed?.withX ||
              project?.informationCollection?.allSummed?.withX ||
              project?.readyToWork?.allSummed?.withX ||
              project?.waitingForRequest?.allSummed?.withX
          )}
        </table>
      )
    );
  }

  return (
    workloadData && (
      <table style={{ width: "100%" }}>
        <tr>
          <td colSpan={2} style={{ border: "1px solid transparent" }}>
            {projectsSlashWorkspaces(
              workloadData?.total?.projectCount || 0,
              workloadData?.total?.workspaceCount || "x"
            )}
          </td>
        </tr>
        <tr>
          <td style={{ border: "1px solid transparent" }}>
            <p>
              СОУТ:{" "}
              {projectsSlashWorkspaces(
                valueHandler(workloadData?.sout?.projectsCount),
                workspaceCountView(
                  valueHandler(workloadData?.sout?.workspaceCount),
                  workloadData?.containsX?.sout
                )
              )}
            </p>
            <p>
              <ShowDeadlines
                data={workloadData?.datesCount}
                assessmentType="sout"
              />
            </p>
            <p>
              ПК:{" "}
              {projectsSlashWorkspaces(
                valueHandler(workloadData?.pk?.projectsCount),
                workspaceCountView(
                  valueHandler(workloadData?.pk?.workspaceCount),
                  workloadData?.pk?.withX
                )
              )}
            </p>
            <p>
              <ShowDeadlines
                data={workloadData?.datesCount}
                assessmentType="pk"
              />
            </p>
            <p>
              ОПР:{" "}
              {projectsSlashWorkspaces(
                valueHandler(workloadData?.opr?.projectsCount),
                workspaceCountView(
                  valueHandler(workloadData?.opr?.workspaceCount),
                  workloadData?.containsX?.opr
                )
              )}
            </p>
            <p>
              <ShowDeadlines
                data={workloadData?.datesCount}
                assessmentType="opr"
              />
            </p>
          </td>
        </tr>
      </table>
    )
  );
};

export default WorkloadCell;
