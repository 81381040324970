import React, { useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Select } from "components/Select/Select";
import InputDiapason from "components/InputDiapason/InputDiapason";
import Spinner from "components/Spinner";
import { Pagination } from "components/Pagination/Pagination";
import { RecordsShowTable } from "components/RecordsShowTable/RecordsShowTable";
import { FaSearch, FaSort } from "react-icons/fa";
import { GoPlus } from "react-icons/go";
import LocationContext from "context/LocationContext";
import LocationsViewModal from "../modals/LocationsViewModal";
import { moveStage } from "utils/moveStage";
import DocumentContext from "context/DocumentsContext";
import { Button } from "shared/ui/Button/Button";
import { LocationTypeChoiceModal } from "../modals/LocationTypeChoiceModal";
import { LocationActionButtons } from "components/LocationActionButton";
import SearchSelectRegions from "components/common/SearchSelectRegions";
import { toDateWithoutTime } from "utils/formatDate";
import AuthContext from "context/AuthContext";
import { LocationSortMap } from "./locationSortMap";
import ProjectContext from "context/ProjectsContext";

const SearchLocations = ({ className, isCompare, stage, locType }) => {
  const {
    initialSearchParams,
    initialSortParams,
    isLoading,
    setIsLoading,
    sortFields,
    additionalFields,
    values,
    setValues,
    sortParams,
    setSortParams,
    totalRowCount,
    selectedSortField,
    setSelectedSortField,
    selectedAdditionalField,
    setSelectedAdditionalField,
    page,
    setPage,
    workers,
    getWorkers,
    totalCountPages,
    locations,
    getLocations,
    setEditPageStatus,
  } = useContext(LocationContext);

  const { tenderMoved } = useContext(ProjectContext);

  const { role, accessRights } = useContext(AuthContext);

  const [sortIsShowed, setSortIsShowed] = useState(false);
  const [searchIsShowed, setSearchIsShowed] = useState(false);

  const [locationTypeIdModalOpen, setLocationTypeIdModalOpen] = useState(false);

  const { entities } = useContext(DocumentContext);

  const navigate = useNavigate();
  const [regions, setRegions] = useState([]);

  const [resetButtonClicked, setResetButtonClicked] = useState(false);

  useEffect(() => {
    setPage(1);
  }, [stage, isCompare]);

  useEffect(() => {
    setIsLoading(true);
    getLocations(stage, locType, null, isCompare);
    getWorkers();
    setResetButtonClicked(false);
  }, [locType, page, setIsLoading, stage, isCompare, resetButtonClicked]);

  const inputChangeHandler = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const updateRegions = (updatedRegions) => {
    if (updatedRegions.length >= 1) {
      const regionId = updatedRegions[0].id;
      setRegions(updatedRegions);
      setValues({ ...values, Regions: regionId });
    } else if (updatedRegions.length === 0) {
      setRegions([]);
      setValues({ ...values, Regions: null });
    }
  };

  const handleSearchClick = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setPage(1);
    getLocations(stage, locType);
  };

  const handleSortClick = (e) => {
    e.preventDefault();

    if (!sortParams.SortField || sortParams.SortField === "null") {
      alert("Выберите параметр сортировки");
      return;
    }
    setIsLoading(true);
    setPage(1);
    getLocations(stage, locType);
  };

  const handleReset = () => {
    setValues(initialSearchParams);
    setSortParams(initialSortParams);
    setSelectedSortField(null);
    setSelectedAdditionalField(null);
    setResetButtonClicked(true);
  };

  const handleDepartmentSelect = async (e) => {
    setValues({ ...values, ContractConclusionDepartmentId: e.target.value });
    // getEmployees();
  };

  const toggleSortButton = () => {
    if (sortIsShowed) {
      setSortIsShowed(false);
      return;
    }
    setSearchIsShowed(false);
    setSortIsShowed(true);
  };

  const toggleSearchButton = () => {
    if (searchIsShowed) {
      setSearchIsShowed(false);
      return;
    }
    setSortIsShowed(false);
    setSearchIsShowed(true);
  };

  const returnFullName = (location) => {
    let result = "";
    if (location?.contract) result += location?.contract?.customer?.name;
    if (location?.tender) result += location?.tender?.manager?.name;
    if (location.additionalName) result += ` (${location.additionalName})`;
    return result;
  };

  console.log(locType);

  return (
    <div className={className}>
      {locationTypeIdModalOpen && (
        <LocationTypeChoiceModal
          close={() => setLocationTypeIdModalOpen(false)}
        />
      )}
      <section className="workspace__action-panel">
        <div
          style={{
            width: "90%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div className="contracts__buttons">
            {accessRights[role].canChange.includes("location") && (
              <Button
                color="olive"
                onClick={() => setLocationTypeIdModalOpen(true)}
                style={{
                  minWidth: "260px",
                  marginRight: "30px",
                }}
              >
                <GoPlus />
                <div>Добавить замерный блок</div>
              </Button>
            )}
            <button
              className={`button search-area__search-button button--olive contracts__search-button ${
                searchIsShowed ? "button--olive--active" : ""
              }`}
              onClick={toggleSearchButton}
            >
              <FaSearch className="search-button__icon" />
              <span className="search-button__text">Поиск</span>
            </button>
            <button
              className={`button search-area__search-button button--olive ${
                sortIsShowed ? "button--olive--active" : ""
              }`}
              onClick={toggleSortButton}
            >
              <FaSort className="search-button__icon icon-sort" />
              <spam className="search-button__text">Сортировка</spam>
            </button>
          </div>
        </div>

        {sortIsShowed || searchIsShowed ? (
          <form className={`form contracts__form`} onReset={handleReset}>
            {sortIsShowed && (
              <>
                <div style={{ width: 650 }}>
                  <div className={`search-area__fieldset`}>
                    <fieldset className="search-area__filters search-area__filters--select-area">
                      <h4 className="workspace__title">Сортировка</h4>

                      <Select
                        className="workspace__input-title"
                        label="Сортировать по:"
                        name="SortMethod"
                        dataSrc="local"
                        value={
                          sortParams.SortMethod === null
                            ? "null"
                            : sortParams.SortMethod
                        }
                        onChange={(e) =>
                          setSortParams({
                            ...sortParams,
                            SortMethod: e.target.value,
                          })
                        }
                        data={{
                          values: {
                            desc: "по убыванию",
                            "": "по возрастанию",
                          },
                        }}
                      />

                      <Select
                        className="workspace__input-title"
                        label="Параметры сортировки:"
                        name="SortField"
                        dataSrc="local"
                        value={
                          sortParams.SortField === null
                            ? "null"
                            : sortParams.SortField
                        }
                        onChange={(e) =>
                          setSortParams({
                            ...sortParams,
                            SortField: e.target.value,
                          })
                        }
                        data={{
                          values: sortFields,
                        }}
                      />
                    </fieldset>
                    <fieldset
                      className="search-area__filters search-area__filters--select-area"
                      style={{ marginLeft: "40px" }}
                    >
                      <h4 className="workspace__title">
                        Дополнительная информация
                      </h4>

                      <Select
                        className="workspace__input-title"
                        label="Дополнительная информация"
                        name="AdditionalInfo"
                        dataSrc="local"
                        onChange={(e) =>
                          setSelectedAdditionalField(e.target.value)
                        }
                        data={{
                          values: additionalFields,
                        }}
                      />
                    </fieldset>
                  </div>
                </div>
                <div
                  className="buttons form__buttons"
                  style={{
                    width: "25%",
                    marginTop: "40px",
                  }}
                >
                  <button
                    className="button button--olive"
                    onClick={(e) => handleSortClick(e)}
                  >
                    <div>Сортировать</div>
                  </button>
                  <button className="button button--olive" type="reset">
                    <div>Сбросить</div>
                  </button>
                </div>
              </>
            )}
            {searchIsShowed && (
              <>
                <h4
                  className="subtitle"
                  style={{ margin: "20px 0 0 0", fontSize: "18px" }}
                >
                  Поиск
                </h4>

                <fieldset className="workspace__ensure-wrapper contracts__ensure-wrapper">
                  <div
                    className="grid-line"
                    style={{
                      gridTemplateColumns: "150px 3fr",
                      columnGap: "15px",
                      marginTop: 3,
                    }}
                  >
                    <label className="workspace__input-title contracts__input-title">
                      ID замерного блока
                      <input
                        className="input"
                        type="text"
                        name="Id"
                        value={values.Id}
                        onChange={(e) => inputChangeHandler(e)}
                      />
                    </label>
                    <label className="workspace__input-title contracts__input-title">
                      Поиск по номеру договора, заказчика и др.
                      <input
                        className="input"
                        type="text"
                        name="SearchString"
                        value={values.SearchString}
                        onChange={(e) => inputChangeHandler(e)}
                      />
                    </label>
                    {/* <Select
                      className="workspace__input-title"
                      label="Тип оценки"
                      name="AssessmentTypeIdList"
                      dataSrc="local"
                      data={{
                        values: {
                          ProductionControl: "ПК",
                          WorkingConditionsSpecialAssesment: "СОУТ",
                          ProfessionalRisks: "ОПР",
                        },
                      }}
                      onChange={(e) =>
                        setValues({
                          ...values,
                          AssessmentTypeIdList: e.target.value,
                        })
                      }
                    /> */}
                  </div>

                  <div
                    className="grid-line"
                    style={{
                      gridTemplateColumns: "2fr 2fr 2fr 2fr",
                      columnGap: "15px",
                      marginTop: 3,
                    }}
                  >
                    <label className="workspace__input-title contracts__input-title">
                      ИНН заказчика
                      <input
                        className="input"
                        type="text"
                        minLength="10"
                        maxLength="12"
                        name="INN"
                        value={values.INN}
                        onChange={(e) => inputChangeHandler(e)}
                        onPaste={(e) => inputChangeHandler(e)}
                        pattern="^(\d{10}|\d{12})$"
                      />
                    </label>
                    <InputDiapason
                      title="Дата заключения договора"
                      type="date"
                      name={[
                        "FromConclusionContractDate",
                        "ToConclusionContractDate",
                      ]}
                      value={[
                        values.FromConclusionContractDate,
                        values.ToConclusionContractDate,
                      ]}
                      onChange={[
                        (date) =>
                          setValues({
                            ...values,
                            FromConclusionContractDate: date,
                          }),
                        (date) =>
                          setValues({
                            ...values,
                            ToConclusionContractDate: date,
                          }),
                      ]}
                      placeholder="дд.мм.гггг"
                      isRequired={false}
                    />
                    <InputDiapason
                      title="Плановая дата исполнения"
                      type="date"
                      name={[
                        "FromPlannedExecutionDate",
                        "ToPlannedExecutionDate",
                      ]}
                      value={[
                        values.FromPlannedExecutionDate,
                        values.ToPlannedExecutionDate,
                      ]}
                      onChange={[
                        (date) =>
                          setValues({
                            ...values,
                            FromPlannedExecutionDate: date,
                          }),
                        (date) =>
                          setValues({
                            ...values,
                            ToPlannedExecutionDate: date,
                          }),
                      ]}
                      placeholder="дд.мм.гггг"
                      isRequired={false}
                    />
                    <Select
                      className="workspace__input-title"
                      label="Замерщики"
                      name="Worker"
                      dataSrc="server"
                      data={workers}
                      onChange={(e) =>
                        setValues({ ...values, Worker: e.target.value })
                      }
                    />
                  </div>
                  <div
                    className="line"
                    style={{
                      borderBottom: "2px solid #000",
                      margin: "15px 0",
                    }}
                  ></div>
                  <div className="grid-line">
                    <SearchSelectRegions
                      isSearchBar
                      regions={regions}
                      updateRegions={updateRegions}
                      color={"olive"}
                    />
                  </div>
                  <div
                    className="grid-line"
                    style={{
                      gridTemplateColumns: "2fr 2fr 2fr 2fr",
                      columnGap: "15px",
                      marginTop: 3,
                    }}
                  >
                    <InputDiapason
                      title="Дата замеров"
                      type="date"
                      name={["FromMeasurementsDate", "ToMeasurementsDate"]}
                      value={[
                        values.FromMeasurementsDate,
                        values.ToMeasurementsDate,
                      ]}
                      onChange={[
                        (date) =>
                          setValues({
                            ...values,
                            FromMeasurementsDate: date,
                          }),
                        (date) =>
                          setValues({ ...values, ToMeasurementsDate: date }),
                      ]}
                      placeholder="дд.мм.гггг"
                      isRequired={false}
                    />
                  </div>
                  <div className="grid-line"></div>
                  <div
                    className="grid-line"
                    style={{
                      gridTemplateColumns: "215px repeat(2, 1fr)",
                      marginTop: 15,
                    }}
                  >
                    <div className="buttons form__buttons">
                      <button
                        className="button button--olive"
                        onClick={(e) => handleSearchClick(e)}
                      >
                        Найти
                      </button>
                      <button className="button button--olive" type="reset">
                        Сбросить
                      </button>
                    </div>
                  </div>
                </fieldset>
              </>
            )}
          </form>
        ) : null}
      </section>
      <h3
        style={{
          marginBottom: "20px",
        }}
      >
        {stage ? stage.title : "Загрузка..."}
      </h3>
      {totalCountPages > 1 && (
        <Pagination
          color={"olive"}
          type={"location"}
          currentPage={page}
          totalPagesCount={totalCountPages}
          totalRowCount={totalRowCount}
          toStart={() => setPage(1)}
          toEnd={() => setPage(totalCountPages)}
          forward={() => setPage(page + 1)}
          back={() => setPage(page - 1)}
          change={(to) => setPage(to)}
        />
      )}

      {isLoading ? (
        <Spinner />
      ) : locations && locations.length !== 0 ? (
        <RecordsShowTable
          page={page}
          isCompare={isCompare}
          records={locations}
          actionButtons={(location) => (
            <LocationActionButtons
              isCompare={isCompare}
              location={location}
              locType={locType}
              entityTypeName="замерный блок"
              onEdit={() => {
                setEditPageStatus(locType);
                navigate(`/sout/locations/edit/${location.id}`);
              }}
              getInfoModal={(modalHandler) => (
                <LocationsViewModal
                  locationID={location.id}
                  modalHandler={modalHandler}
                  locType={
                    location.hasOwnProperty("tender") ? "Potential" : "Contract"
                  }
                />
              )}
              documentsInfo={{
                id: location.id,
                entity:
                  locType === "Potential"
                    ? entities.potentialLocations
                    : entities.contractLocations,
                documents: location.documents,
              }}
              moveToNextStage={async () => {
                await moveStage(
                  locType === "Potential"
                    ? "potential-measuring-block"
                    : "contract-measuring-block",
                  "continue",
                  location.workFlow.id
                );
                setIsLoading(true);
                getLocations(stage, locType);
              }}
              moveToPrevStage={async () => {
                await moveStage(
                  locType === "Potential"
                    ? "potential-measuring-block"
                    : "contract-measuring-block",
                  "return",
                  location.workFlow.id
                );
                setIsLoading(true);
                getLocations(stage, locType);
              }}
              reject={async () => {
                await moveStage(
                  locType === "Potential"
                    ? "potential-measuring-block"
                    : "contract-measuring-block",
                  "reject",
                  location.workFlow.id
                );
                setIsLoading(true);
                getLocations(stage, locType);
              }}
              disabledButtons={accessRights[role].location}
              colorTheme="olive"
            />
          )}
          sortFields={sortFields}
          additionalFields={additionalFields}
          selectedAdditionalField={selectedAdditionalField}
          selectedSortField={selectedSortField}
          headers={[
            {
              value: "id",
              text: "ID",
            },
            {
              value: "customerName",
              text: "Заказчик",
            },
            {
              value: "contractNumber",
              text: "Номер договора",
            },
            {
              value: "regions",
              text: "Регион",
            },
          ]}
          type={"location"}
          getDataFns={{
            id: (location) => location.id,
            customerName: (location) => returnFullName(location),
            contractNumber: (location) =>
              location.contract?.contractNumber || "-",
            regionName: (location) =>
              location?.regions?.map((region) => region.name).join(" "),
          }}
          sortMap={LocationSortMap}
        />
      ) : (
        <p style={{ marginTop: "20px" }}>Нет замерных блоков...</p>
      )}
    </div>
  );
};
export default SearchLocations;
