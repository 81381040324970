import { useEffect, useState } from "react";
import { Line } from "shared/ui/Line/Line";
import { buildEmployeeName } from "utils/buildEmplyeeName";
import { toDateWithoutTime } from "utils/formatDate";
import { toDateWithTime } from "utils/formatDate";
import getContract from "utils/getContractById";
import { translateText } from "utils/stages";

const InfoTableModalProjects = ({ setOpenModal, project }) => {
  const [departmentsParts, setDepartmentsParts] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [contract, setContract] = useState();

  useEffect(() => {
    console.log(project?.contract);
    getContract(project?.contract?.id).then(({ data }) => {
      setContract(data);
      let parts;
      setDepartments(data.departments);
      if (data.departments.length % 3 === 0) {
        parts = data.departments.length / 3;
      } else {
        parts = Math.floor(data.departments.length / 3) + 1;
      }
      setDepartmentsParts(
        new Array(parts).fill(0).map((elem, i) => {
          return [0 + i * 3, 3 + i * 3];
        })
      );
    });
  }, []);

  return contract ? (
    <section className="modal">
      <div className="modal__content">
        <h2 className="modal__title" style={{ textAlign: "center" }}>
          Информация о проекте
        </h2>
        <div className="flex-wrapper__modal__tables table-info">
          <div className="flex-wrapper__modal-column">
            <div>
              <h3 className="table__title">Общие сведения</h3>
              <table className="table__table-view-modal">
                <tbody>
                  <tr>
                    <td>ID проекта</td>
                    <td className="table__id">{project?.id || "-"}</td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        color: "darkviolet",
                      }}
                    >
                      Отдел заключения договоров
                    </td>
                    <td
                      style={{
                        color: "darkviolet",
                      }}
                    >
                      {
                        {
                          TenderDepartment: "Тендерный",
                          CommercialDepartment: "Коммерческий",
                        }[project?.contract?.contractConclusionDepartmentId]
                      }
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        color: "darkviolet",
                      }}
                    >
                      ID договора
                    </td>
                    <td
                      className="table__id"
                      style={{
                        color: "darkviolet",
                      }}
                    >
                      {project?.contract?.id || "-"}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        color: "darkviolet",
                      }}
                    >
                      Ответственный по заключению договора
                    </td>
                    <td
                      className="table__id"
                      style={{
                        color: "darkviolet",
                      }}
                    >
                      {[
                        contract?.personInCharge?.name,
                        contract?.personInCharge?.lastName,
                      ].join(" ")}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        color: "darkviolet",
                      }}
                    >
                      Заказчик
                    </td>
                    <td
                      style={{
                        color: "darkviolet",
                      }}
                    >
                      {project?.contract?.customer?.name || " - "}
                    </td>
                  </tr>
                  <tr>
                    <td>Дополнительное наименование</td>
                    <td>{project?.name || " - "}</td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        color: "darkviolet",
                      }}
                    >
                      ИНН заказчика
                    </td>
                    <td
                      style={{
                        color: "darkviolet",
                      }}
                    >
                      {project?.contract?.customer?.inn || " - "}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        color: "darkviolet",
                      }}
                    >
                      Номер договора
                    </td>
                    <td
                      style={{
                        color: "darkviolet",
                      }}
                    >
                      {project?.contract?.contractNumber || "-"}
                    </td>
                  </tr>
                  <tr
                    style={{
                      color: "darkviolet",
                    }}
                  >
                    <td>Идентификационный номер</td>
                    <td>{project?.contractDepartmentNumber || "-"}</td>
                  </tr>
                  <tr>
                    <td>Этап проекта</td>
                    <td>
                      {translateText(project?.workFlow?.workFlowStatusId) ||
                        " - "}
                    </td>
                  </tr>
                  <tr>
                    <td>Регионы</td>
                    <td>
                      {project?.regions && project.regions.length > 0
                        ? project.regions
                            .map((region) => region.name)
                            .join(", ")
                        : " - "}
                    </td>
                  </tr>
                  <tr>
                    <td>Контактные данные</td>
                    <td>{project?.contactData || "-"}</td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        color: "darkviolet",
                      }}
                    >
                      Контактные данные договора
                    </td>
                    <td
                      style={{
                        color: "darkviolet",
                      }}
                    >
                      {project?.contract?.contactData || "-"}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="flex-wrapper__modal-column">
            <div>
              <h3 className="table__title">Ответственный</h3>
              <table className="table__table-view-modal">
                <tbody>
                  <tr>
                    <td>Организация</td>
                    <td>{project?.organization?.name || "-"}</td>
                  </tr>
                  <tr>
                    <td>Филиал</td>
                    <td>{project?.branchOffice?.name || "-"}</td>
                  </tr>
                  <tr>
                    <td>Лаборатория</td>
                    <td>
                      {project?.personInCharge?.name
                        ? `${project?.personInCharge?.name} ${project?.personInCharge?.lastName}`
                        : "-"}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div>
              <h4 style={{ marginBottom: 7 }}>Исполнение</h4>
              <table className="table__table-view-modal">
                <tbody>
                  <tr>
                    <td>Работа по заявке</td>
                    <td>{project?.workOnRequest ? "Да" : "Нет"}</td>
                  </tr>
                  <tr>
                    <td>Тип оценки</td>
                    <td>{translateText(project?.assessmentTypeId) || " - "}</td>
                  </tr>
                  <tr>
                    <td>Число РМ</td>
                    <td>{project?.workspaceCount || " х "}</td>
                  </tr>
                  <tr>
                    <td style={{ color: "black" }}>Программа выполнения</td>
                    <td>{translateText(project?.executionProgramId) || "-"}</td>
                  </tr>
                  <tr>
                    <td>Рабочая тетрадь</td>
                    <td>{translateText(project?.haveWorkbook) || " - "}</td>
                  </tr>
                  <tr>
                    <td>Комментарии к исполнению</td>
                    <td>{project?.executionDescription}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="flex-wrapper__modal-column">
            <div>
              <h3 className="table__title">Даты</h3>
              <table className="table__table-view-modal">
                <tr>
                  <td
                    style={{
                      color: "darkviolet",
                    }}
                  >
                    Дата заключения договора
                  </td>
                  <td
                    style={{
                      color: "darkviolet",
                    }}
                  >
                    {toDateWithoutTime(
                      project?.contract?.conclusionContractDate
                    ) || "-"}
                  </td>
                </tr>
                <tr>
                  <td>Дата назначения исполнителя</td>
                  <td>
                    {toDateWithoutTime(project?.performerAppointedDate) || "-"}
                  </td>
                </tr>
                <tr>
                  <td>Дата получения информации</td>
                  <td>
                    {toDateWithoutTime(project?.informationReceiptDate) || "-"}
                  </td>
                </tr>

                <tr>
                  <td>Дата приема в работу</td>
                  <td>
                    {toDateWithoutTime(project?.acceptedToWorkDate) || "-"}
                  </td>
                </tr>
                <tr>
                  <td>Дата отправки на согласование</td>
                  <td>
                    {toDateWithoutTime(project?.sendingForApprovalDate) || "-"}
                  </td>
                </tr>
                <tr>
                  <td>Дата отправки отчета</td>
                  <td>
                    {toDateWithoutTime(project?.reportSendingDate) || "-"}
                  </td>
                </tr>
                <tr>
                  <td>Плановая дата исполнения проекта</td>
                  <td>
                    {toDateWithoutTime(project?.plannedExecutionDate) || "-"}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      color: "darkviolet",
                    }}
                  >
                    Плановая дата исполнения договора
                  </td>
                  <td
                    style={{
                      color: "darkviolet",
                    }}
                  >
                    {toDateWithoutTime(
                      project?.contract?.plannedExecutionDate
                    ) || "-"}
                  </td>
                </tr>

                <tr>
                  <td>Дата подписания титульного листа</td>
                  <td>
                    {toDateWithoutTime(project?.titleSheetSignDate) || "-"}
                  </td>
                </tr>
                <tr>
                  <td>Дата выгрузки</td>
                  <td>{toDateWithoutTime(project?.unloadingDate) || "-"}</td>
                </tr>
              </table>
            </div>
          </div>
          {/* {departments?.length !== 0 && (
            <>
              <Line gridColumn="1 / 4" />
              <div className="flex-wrapper__modal-row">
                {departmentsParts &&
                  departmentsParts.map((i) => (
                    <div style={{ width: "33%" }}>
                      <table className="table__table-view-modal table-info">
                        <tbody>
                          <tr>
                            <td style={{ color: "black" }}>КПП</td>
                            <td style={{ color: "black" }}>
                              Идентификационный номер
                            </td>
                          </tr>
                          {departments.slice(i[0], i[1]).map(
                            (item) =>
                              item?.departmentNumber ===
                                project?.contractDepartmentNumber && (
                                <tr>
                                  <td>{item.kpp}</td>
                                  <td>{item.departmentNumber}</td>
                                </tr>
                              )
                          )}
                        </tbody>
                      </table>
                    </div>
                  ))}
              </div>
            </>
          )} */}
        </div>
        <button
          onClick={() => setOpenModal(false)}
          className="button button--orange close-button modal__close-button--bottom"
        >
          Закрыть окно
        </button>
      </div>
    </section>
  ) : null;
};
export default InfoTableModalProjects;
