import { formatDate } from "shared/formatDate";
import { translateText } from "utils/stages";

export const ContractSortMap = {
  conclusionContractDate: (contract) => contract.conclusionContractDate,
  plannedExecutionDate: (contract) => contract.plannedExecutionDate,
  actDate: (contract) => {
    if (contract?.act?.date) {
      return formatDate(contract?.act?.date);
    }
    return null;
  },
  price: (contract) => contract.price,
  personInCharge: (contract) => {
    return [contract?.personInCharge.name, contract?.personInCharge.lastName].join(" ");
  },
  executionDescription: (contract) => contract.executionDescription,
  innerNumber: (contract) => contract.innerNumber,
  contactData: (contract) => {
    return contract.customer.contactData;
  },
  contractConclusionDepartmentId: (contract) =>
    translateText(contract.contractConclusionDepartmentId),
  assesmentTypes: (contract) => {
    const arr = contract.assesmentTypes[0];
    return arr
      .map((el) =>
        (function (el) {
          if (el === "ProfessionalRisks") {
            return "ОПР";
          }
          return translateText(el);
        })(el.id)
      )
      .join(", ");
  },
};
