import { DatePicker } from "components/DateInput/DateInput";
import React, { useState, useContext, useEffect, useCallback } from "react";
import { fetchEmployees } from "entities/contracts/service/fetchEmployees";
import DocumentContext from "context/DocumentsContext";
import { getCustomerNameByINN } from "utils/getCustomerNameByINN";
import { generateToken } from "shared/generateToken";
import { Contract } from "entities/contracts/models/Contract/Contract";
import TenderModalFileInput from "pages/sout/accountant/common/TenderModalFileInput";
import SelectRegions from "components/common/SelectRegions";
import { dateToString } from "shared/formatDate";
import AuthContext from "context/AuthContext";
import { isTenderUser } from "utils/defineUser";
import GroupButton from "components/common/GroupButton";
import { Checkbox } from "components/Checkboxes/Checkboxes";
import EntitiesTable from "components/EntitiesTable/EntitiesTable";
import EntitiesTableItem from "components/EntitiesTable/EntitiesTableItem";

const assesmentTypeArray = [
  { value: "WorkingConditionsSpecialAssesment", label: "СОУТ" },
  { value: "ProfessionalRisks", label: "ОПР" },
  { value: "ProductionControl", label: "ПК" },
];

const WonAndSigningTenderStage = ({
  contracts,
  setNewContracts,
  newContracts,
  project,
  doc,
  setDoc,
  docInfo,
  setDocInfo,
  type,
  setShowBtn,
  contractsLn,
  isShowBtn,
  setDisabledNextStageBtn,
  contractCount,
}) => {
  const [contractId, setContractId] = useState(null);
  const [isDisabled, setIsDisabled] = useState(true);
  const [arr, setArr] = useState([]);
  const [customerNameViaINN, setCustomerNameViaINN] = useState();
  const [contactInfo, setContactInfo] = useState(null);
  const [isNotariusChecked, setIsNotariusChecked] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [files, setFiles] = useState([]);
  const [files1, setFiles1] = useState([]);
  const [contract, setContract] = useState(new Contract({}));
  const [tenderEmployees, setTenderEmployees] = useState([]);
  const { entities } = useContext(DocumentContext);
  const [regions, setRegions] = useState([]);
  const { role } = useContext(AuthContext);
  const [isNullPK, setIsNullPK] = useState(false);
  const [isNullSOUT, setIsNullSOUT] = useState(false);
  const [isNullOPR, setIsNullOPR] = useState(false);
  const [emp, setEmp] = useState(
    isTenderUser() ? localStorage.getItem("__user-id") : null
  );

  const [workOnRequest, setWorkOnRequest] = useState(false);

  useEffect(() => {
    const inn = checkInn();
    if (tenderEmployees.length === 0) {
      fetchEmployees("TenderDepartment").then((res) => setTenderEmployees(res));
    }
    setIsDisabled(
      !(
        contract?.customer?.inn &&
        inn &&
        contract?.customer?.name &&
        arr.length &&
        contract?.contractNumber &&
        contract?.price &&
        contract?.conclusionContractDate &&
        contract?.plannedExecutionDate &&
        emp &&
        regions?.length > 0 &&
        files.length > 0 &&
        checkWorkspaceCount()
      )
    );
  });
  const selectHandler = (e) => {
    setEmp(e.target.value);
  };

  const checkInn = () => {
    return (
      contract?.customer?.inn?.length === 12 ||
      contract?.customer?.inn?.length === 10
    );
  };

  const checkWorkspaceCount = () => {
    if (
      arr.find((item) => item?.id === "ProfessionalRisks") &&
      !isNullOPR &&
      !contract?.workspaceCount?.opr
    )
      return false;
    if (
      arr.find((item) => item?.id === "WorkingConditionsSpecialAssesment") &&
      !isNullSOUT &&
      !contract?.workspaceCount?.sout
    )
      return false;
    if (
      arr.find((item) => item?.id === "ProductionControl") &&
      !isNullPK &&
      !contract?.workspaceCount?.pk
    )
      return false;
    return true;
  };

  const customerNameHandler = (name, inn) => {
    setContract({
      ...contract,
      customer: {
        inn: inn,
        name: name,
      },
    });
  };

  const updateRegions = (updatedRegions) => setRegions(updatedRegions);

  const fetchData = (inn) => {
    setIsSearching(true);
    setContract({ ...contract, customer: { ...contract?.customer, inn: inn } });
    if (inn.length === 10 || inn.length === 12) {
      getCustomerNameByINN(inn).then((res) => {
        setCustomerNameViaINN(res);
        customerNameHandler(res, inn);
        setIsSearching(false);
      });
    }
  };

  const handleCheckboxChange = (e) => {
    const { name, value, checked } = e.target;
    if (checked) {
      const newItem = {
        id: value,
      };
      setArr(
        arr.includes(value)
          ? arr.filter((item) => item != value)
          : [...arr, newItem]
      );
    } else {
      const newItems = arr.filter((item) => item.id != value);
      setArr(newItems);
    }
    setContract({ ...contract, assesmentTypes: [...arr] });
  };

  const contractNull = () => {
    setContract(
      new Contract({
        contractNumber: "",
        innerNumber: "",
        price: "",
        workspaceCount: {
          pk: "",
          sout: "",
          opr: "",
        },
        customer: {
          inn: "",
          name: "",
        },
        workOnRequest: false,
      })
    );
    setCustomerNameViaINN("");
    setIsNotariusChecked(false);
    setContractId(0);
    setContactInfo(null);
    setArr([]);
    setEmp(isTenderUser() ? localStorage.getItem("__user-id") : null);
    setFiles([]);
    setFiles1([]);
    setRegions([]);
  };

  const nextContractBtnHandler = () => {
    const newContract = {
      customer: contract?.customer,
      assesmentTypes: arr,
      TenderId: project?.id,
      regions: regions,
      additionalName: contract?.additionalName,
      workspaceCount: {
        sout: contract?.workspaceCount?.sout
          ? parseInt(contract?.workspaceCount?.sout)
          : null,
        pk: contract?.workspaceCount?.pk
          ? parseInt(contract?.workspaceCount?.pk)
          : null,
        opr: contract?.workspaceCount?.opr
          ? parseInt(contract?.workspaceCount?.opr)
          : null,
      },
      personInChargeId: emp,
      contactData: contactInfo,
      contractConclusionDepartmentId: "TenderDepartment",
      contractNumber: contract?.contractNumber,
      conclusionContractDate: dateToString(contract?.conclusionContractDate),
      plannedExecutionDate: dateToString(contract?.plannedExecutionDate),
      innerNumber: null,
      workOnRequest: workOnRequest,
      price: contract?.price,
    };

    setNewContracts([...newContracts, newContract]);
    setDoc([...doc, ...files]);
    setDocInfo([...docInfo, { doc: files1, index: newContracts?.length }]);
    contractNull();
  };

  useEffect(() => {
    if (contracts?.length > 0 || newContracts?.length > 0) {
      setDisabledNextStageBtn(false);
      setShowBtn(true);
    }
  });

  return (
    <div
      style={{
        width: 880,
        height: 450,
        overflowY: "scroll",
        scrollbarWidth: "thin",
      }}
    >
      {project?.contracts?.length > 0 && (
        <EntitiesTable
          title={"Существующие договоры"}
          list={project?.contracts}
          headers={["#", "ID", "Наименование заказчика", "Номер", "Цена"]}
          printFunc={(item, idx) => (
            <EntitiesTableItem
              values={[
                idx + 1,
                item?.id,
                item?.customer?.name || "-",
                item?.contractNumber || "-",
                item?.price || "-",
              ]}
              key={item?.id}
            />
          )}
        />
      )}
      <div className="modal__tenderContractHead">
        <p className="contractsCount">{`Количество договоров: ${Number(
          contracts?.length
        )}`}</p>
      </div>
      <section className="modal__WaS-stage">
        <div style={{ width: 270 }}>
          <label className="modal__input-title">ИНН заказчика:</label>
          <input
            className={!contracts ? "input modal__input-err" : "input "}
            type="text"
            maxLength="12"
            pattern="^(\d{10}|\d{12})$"
            onChange={(e) => fetchData(e.target.value)}
            onPaste={(e) => fetchData(e.target.value)}
            name="qtyContract"
            value={contract?.customer?.inn}
          />
          <span>Наименование заказчика</span>
          <input
            style={{
              marginRight: "5px",
              marginLeft: "10px",
              cursor: "pointer",
            }}
            type="checkbox"
            name="isNotarius"
            onChange={(e) => setIsNotariusChecked(e.target.checked)}
            checked={isNotariusChecked}
          />
          <div className="columnModal">
            {isNotariusChecked ? (
              <input
                className="input contracts__input"
                style={{ width: 270 }}
                type="text"
                name="contractNumber"
                value={contract?.customer?.name}
                onChange={(e) =>
                  setContract({
                    ...contract,
                    customer: { ...contract.customer, name: e.target.value },
                  })
                }
                required
              />
            ) : customerNameViaINN === undefined ? (
              <p>Введите ИНН заказчика...</p>
            ) : isSearching ? (
              <p>Поиск по базе данных...</p>
            ) : customerNameViaINN !== null ? (
              <p style={{ marginBottom: 5 }}>{customerNameViaINN}</p>
            ) : (
              <span
                style={{ marginBottom: 5 }}
                className="error-message visible modal__input-title"
              >
                Заказчик не найден!
              </span>
            )}
          </div>
        </div>
        <div style={{ width: 395 }}>
          {!isTenderUser() && (
            <div>
              <label className="modal__input-title">
                Ответственный сотрудник:
              </label>
              <select
                value={emp}
                name="tenderEmp"
                id="tenderEmp"
                className="select"
                onChange={selectHandler}
              >
                <option value="null" selected={emp == null}>
                  -
                </option>
                {tenderEmployees.map((item) => (
                  <option
                    selected={item.id === emp}
                    key={item.id}
                    value={item.id}
                  >{`${item.name}`}</option>
                ))}
              </select>
            </div>
          )}
          <div style={{ display: "flex", marginTop: 5 }}>
            <div style={{ width: 180, marginRight: 15 }}>
              <label className="modal__input-title">Номер договора:</label>
              <input
                style={{ maxWidth: 180 }}
                className={!contracts ? "input modal__input-err" : "input "}
                type="text"
                name="contractNumber"
                value={contract?.contractNumber}
                onChange={(e) =>
                  setContract({ ...contract, contractNumber: e.target.value })
                }
              />
            </div>
            <div style={{ width: 200 }}>
              <label className="modal__input-title">Цена договора:</label>
              <input
                style={{ maxWidth: 200 }}
                className={!contracts ? "input modal__input-err" : "input "}
                type="number"
                name="price"
                value={contract?.price}
                onChange={(e) =>
                  setContract({ ...contract, price: e.target.value })
                }
              />
            </div>
          </div>
          <label
            style={{ marginTop: 5 }}
            className="modal__input-title"
          >{`Контактные данные тендера: ${
            project?.manager?.contactData ? project?.manager?.contactData : "-"
          }`}</label>
          <label className="modal__input-title" style={{ marginTop: 5 }}>
            Контактные данные:
          </label>
          <textarea
            className="textarea"
            name="managerContactData"
            value={contactInfo}
            onChange={(e) => setContactInfo(e.target.value)}
          />
        </div>
      </section>
      <div style={{ width: "100%", marginBottom: 10 }}>
        <label className="modal__input-title">Доп.наименование:</label>
        <input
          className={"input"}
          type="text"
          name="additionalName"
          value={contract?.additionalName}
          onChange={(e) =>
            setContract({ ...contract, additionalName: e.target.value })
          }
        />
      </div>
      <section
        className="modal__WaS-stage"
        style={{ position: "relative", zIndex: 5, top: 0 }}
      >
        <div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <GroupButton
              onChangeHandler={handleCheckboxChange}
              type={"checkbox"}
              title={"Тип оценки"}
              values={arr}
              info={assesmentTypeArray}
              isDraftContract={true}
            />
            <div style={{ width: 150, paddingTop: 12 }}>
              <div style={{ position: "inherit", zIndex: 2, display: "flex" }}>
                <div style={{ position: "inherit", zIndex: 2, width: 100 }}>
                  <label htmlFor="" className="modal_display_start">
                    Кол-во РМ
                  </label>
                  <input
                    type="text"
                    disabled={
                      !arr.find(
                        (item) =>
                          item?.id === "WorkingConditionsSpecialAssesment"
                      )
                    }
                    className="input"
                    value={contract?.workspaceCount?.sout}
                    onChange={(e) =>
                      setContract({
                        ...contract,
                        workspaceCount: {
                          ...contract?.workspaceCount,
                          sout: e.target.value,
                        },
                      })
                    }
                  />
                </div>
                <label style={{ margin: "30px 0 0 5px" }}>
                  <input
                    type="checkbox"
                    onClick={() => setIsNullSOUT(!isNullSOUT)}
                  />
                  <span
                    style={{
                      fontFamily: "monospace",
                      marginLeft: 5,
                      fontSize: 16,
                    }}
                  >
                    X
                  </span>
                </label>
              </div>
              <div style={{ position: "inherit", zIndex: 2, display: "flex" }}>
                <div style={{ position: "inherit", zIndex: 2, width: 100 }}>
                  <label htmlFor="" className="modal_display_start">
                    Кол-во РМ
                  </label>
                  <input
                    type="text"
                    disabled={
                      !arr.find((item) => item?.id === "ProfessionalRisks")
                    }
                    className="input"
                    value={contract?.workspaceCount?.opr}
                    onChange={(e) =>
                      setContract({
                        ...contract,
                        workspaceCount: {
                          ...contract?.workspaceCount,
                          opr: e.target.value,
                        },
                      })
                    }
                  />
                </div>
                <label style={{ margin: "30px 0 0 5px" }}>
                  <input
                    type="checkbox"
                    onClick={() => setIsNullOPR(!isNullOPR)}
                  />
                  <span
                    style={{
                      fontFamily: "monospace",
                      marginLeft: 5,
                      fontSize: 16,
                    }}
                  >
                    X
                  </span>
                </label>
              </div>
              <div style={{ position: "inherit", zIndex: 2, display: "flex" }}>
                <div style={{ position: "inherit", zIndex: 2, width: 100 }}>
                  <label htmlFor="" className="modal_display_start">
                    Кол-во РМ
                  </label>
                  <input
                    type="text"
                    disabled={
                      !arr.find((item) => item?.id === "ProductionControl")
                    }
                    className="input"
                    value={contract?.workspaceCount?.pk}
                    onChange={(e) =>
                      setContract({
                        ...contract,
                        workspaceCount: {
                          ...contract?.workspaceCount,
                          pk: e.target.value,
                        },
                      })
                    }
                  />
                </div>
                <label style={{ margin: "30px 0 0 5px" }}>
                  <input
                    type="checkbox"
                    onClick={() => setIsNullPK(!isNullPK)}
                  />
                  <span
                    style={{
                      fontFamily: "monospace",
                      marginLeft: 5,
                      fontSize: 16,
                    }}
                  >
                    X
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            width: 405,
            justifyContent: "space-between",
            paddingTop: 7,
          }}
        >
          <div style={{ width: 240, marginBottom: 20, marginLeft: 15 }}>
            <label className="modal__input-title">
              Дата заключения договора:
            </label>
            <DatePicker
              className="input"
              name="conclusionContractDate"
              value={contract.conclusionContractDate || null}
              onChange={(date) =>
                setContract({ ...contract, conclusionContractDate: date })
              }
              required={true}
              min={new Date("01.01.2000")}
            />
            <div className="columnModal" style={{ width: 240 }}>
              <label className="modal__input-title">
                Дата исполнения договора:
              </label>
              <DatePicker
                className="input"
                name="plannedExecutionDate"
                value={contract.plannedExecutionDate || null}
                onChange={(date) =>
                  setContract({ ...contract, plannedExecutionDate: date })
                }
                required={true}
                min={new Date("01.01.2000")}
              />
            </div>
            <div
              style={{
                margin: "10px 0 10px 0",
                textAlign: "start",
                fontSize: 16,
              }}
            >
              <Checkbox
                label={"Работа по заявке"}
                name={"workOnRequest"}
                onChange={() => setWorkOnRequest(!workOnRequest)}
                checked={workOnRequest}
              />
            </div>
          </div>
        </div>
        <div style={{ width: 350 }}>
          <div style={{ marginTop: 10, marginRight: 10 }}>
            <>
              <TenderModalFileInput
                entity={entities.contracts}
                modelId={contractId}
                doc={files}
                title={"Загрузите договор"}
                setDoc={setFiles}
                docType={"Contract"}
              />
              <div style={{ marginTop: 10 }}>
                <TenderModalFileInput
                  entity={entities.contracts}
                  modelId={contractId}
                  isOneDoc={false}
                  title={"Загрузите информацию"}
                  doc={files1}
                  setDoc={setFiles1}
                  docType={"Info"}
                />
              </div>
            </>
          </div>
        </div>
      </section>
      <div style={{ width: 450, position: "relative", top: -30, zIndex: 1 }}>
        <SelectRegions regions={regions} updateRegions={updateRegions} />
      </div>
      <button
        className={"button--blue button"}
        onClick={nextContractBtnHandler}
        disabled={isDisabled}
      >
        Сoздать договор
      </button>
      {newContracts?.length > 0 && (
        <EntitiesTable
          title={"Созданные договоры"}
          list={newContracts}
          headers={["#", "Наименование заказчика", "Номер", "Цена"]}
          printFunc={(item, idx) => (
            <EntitiesTableItem
              values={[
                idx + 1,
                item?.customer?.name || "-",
                item?.contractNumber || "-",
                item?.price || "-",
              ]}
              canDelete={true}
              deleteFunc={() =>
                setNewContracts(
                  newContracts?.filter(
                    (i) => i?.contractNumber !== item?.contractNumber
                  )
                )
              }
              key={item?.id}
            />
          )}
        />
      )}
    </div>
  );
};

export default WonAndSigningTenderStage;
