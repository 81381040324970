import React, { memo, useEffect, useState } from "react";
import { calculateWorkspaceCount } from "utils/calculateWorkspaceCount";
import { valueHandler } from "shared/valueHandler";
import { checkObject } from "shared/checkObject";
import { StatisticInnerTable } from "./StatisticTableRow";
import { workspaceCountView } from "./WorkspaceCountView";

const StatisticTableTotalRow = memo(({ projects, tableType, type }) => {
  const [result, setResult] = useState();

  const getTotal = () => {
    setResult(
      projects?.reduce(
        (totalValue, currentValue) => {
          return {
            total: {
              total:
                totalValue?.total?.total +
                valueHandler(currentValue?.total?.total),
              tender:
                totalValue?.total?.tender +
                valueHandler(currentValue?.total?.tender),
              commercial:
                totalValue?.total?.commercial +
                valueHandler(currentValue?.total?.commercial),
            },
            acts: {
              total:
                totalValue?.acts?.total +
                valueHandler(currentValue?.acts?.total),
              tender:
                totalValue?.acts?.tender +
                valueHandler(currentValue?.acts?.tender),
              commercial:
                totalValue?.acts?.commercial +
                valueHandler(currentValue?.acts?.commercial),
            },
            fulfilled: {
              total:
                totalValue?.fulfilled?.total +
                valueHandler(currentValue?.fulfilled?.total),
              tender:
                totalValue?.fulfilled?.tender +
                valueHandler(currentValue?.fulfilled?.tender),
              commercial:
                totalValue?.fulfilled?.commercial +
                valueHandler(currentValue?.fulfilled?.commercial),
            },
            fulfilment: {
              total:
                totalValue?.fulfilment.total +
                valueHandler(currentValue?.fulfilment?.total),
              tender:
                totalValue?.fulfilment?.tender +
                valueHandler(currentValue?.fulfilment?.tender),
              commercial:
                totalValue?.fulfilment?.commercial +
                valueHandler(currentValue?.fulfilment?.commercial),
            },
            terminated: {
              total:
                totalValue?.terminated?.total +
                valueHandler(currentValue?.terminated?.total),
              tender:
                totalValue?.terminated?.tender +
                valueHandler(currentValue?.terminated?.tender),
              commercial:
                totalValue?.terminated?.commercial +
                valueHandler(currentValue?.terminated?.commercial),
            },
          };
        },
        {
          total: { total: 0, tender: 0, commercial: 0 },
          acts: { total: 0, tender: 0, commercial: 0 },
          fulfilled: { total: 0, tender: 0, commercial: 0 },
          fulfilment: { total: 0, tender: 0, commercial: 0 },
          terminated: { total: 0, tender: 0, commercial: 0 },
        }
      )
    );
  };

  useEffect(() => {
    getTotal();
  }, [projects]);

  return (
    <>
      {tableType === "workload" && (
        <tr>
          <td>
            <h3>Итого</h3>
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <StatisticTotalInnerTable
            projects={projects}
            tableType={tableType}
            isTotalWorkspaceCount
          />
          <StatisticTotalInnerTable
            projects={projects}
            tableType={tableType}
            isAgreementCount
          />
          <StatisticTotalInnerTable
            projects={projects}
            tableType={tableType}
            isFinishedCount
          />
        </tr>
      )}
      {tableType === "income" && (
        <tr>
          <td>
            <h3>Итого</h3>
          </td>
          <StatisticInnerTable
            tableType={tableType}
            total={result?.total?.total}
            com={result?.total?.commercial}
            ten={result?.total?.tender}
          />
          {type !== "plannedDate" && (
            <>
              <StatisticInnerTable
                tableType={tableType}
                total={result?.fulfilment?.total}
                com={result?.fulfilment?.commercial}
                ten={result?.fulfilment?.tender}
              />
              <StatisticInnerTable
                tableType={tableType}
                total={result?.terminated?.total}
                com={result?.terminated?.commercial}
                ten={result?.terminated?.tender}
              />
              <StatisticInnerTable
                tableType={tableType}
                total={result?.fulfilled?.total}
                com={result?.fulfilled?.commercial}
                ten={result?.fulfilled?.tender}
              />
              <StatisticInnerTable
                tableType={tableType}
                total={result?.acts?.total}
                com={result?.acts?.commercial}
                ten={result?.acts?.tender}
              />
            </>
          )}
        </tr>
      )}
    </>
  );
});

function StatisticTotalInnerTable({
  tableType,
  isTotalWorkspaceCount,
  isAgreementCount,
  isFinishedCount,
  projects,
}) {
  return (
    <td>
      {tableType === "workload" && isFinishedCount && (
        <p>
          {workspaceCountView(
            projects.reduce((res, project) => {
              res += [project?.finish?.allSummed?.workspaceCount].reduce(
                (acc, i) => acc + valueHandler(i),
                0
              );
              return res;
            }, 0),
            projects.some(
              (project) =>
                checkObject(project?.containsX?.Finished) ||
                checkObject(project?.containsX?.UnloadingDocuments)
            )
          )}
        </p>
      )}
      {tableType === "workload" && isTotalWorkspaceCount && (
        <p>
          {workspaceCountView(
            projects.reduce((res, project) => {
              res += [
                project?.informationCollection?.allSummed?.workspaceCount,
                project?.readyToWork?.allSummed?.workspaceCount,
                project?.waitingForRequest?.allSummed?.workspaceCount,
                project?.acceptedToWork?.allSummed?.workspaceCount,
              ].reduce((acc, i) => acc + valueHandler(i), 0);
              return res;
            }, 0),
            projects.some(
              (project) =>
                project?.informationCollection?.allSummed?.withX ||
                project?.readyToWork?.allSummed?.withX ||
                project?.acceptedToWork?.allSummed?.withX
            )
          )}
        </p>
      )}
      {tableType === "workload" && isAgreementCount && (
        <p>
          {workspaceCountView(
            projects.reduce((res, project) => {
              res += [
                project?.waitingAndAgreement?.allSummed?.workspaceCount,
              ].reduce((acc, i) => acc + valueHandler(i), 0);
              return res;
            }, 0),
            projects.some((project) =>
              checkObject(project?.containsX?.Agreement)
            )
          )}
        </p>
      )}
    </td>
  );
}
export default StatisticTableTotalRow;
