import Sidebar from "../components/Sidebar";
import { useEffect, useState } from "react";
import StatisticTable from "../components/common/StatisticTable";
import { fetchWorkloadData } from "utils/fetchWorkloadData";
import { Select } from "components/Select/Select";
import Spinner from "components/Spinner";
import { checkObject } from "shared/checkObject";
import { workspaceCountView } from "components/common/WorkspaceCountView";
import { projectsSlashWorkspaces } from "components/common/projectsSlashWorkspaces";

function Workload() {
  // Получаем текущий год
  const currentYear = new Date().getFullYear();

  // Создаем объект values
  const years = {
    values: {
      [currentYear - 1]: (currentYear - 1).toString(),
      [currentYear]: currentYear.toString(),
      [currentYear + 1]: (currentYear + 1).toString(),
    },
  };
  const [isLoading, setIsLoading] = useState(false);
  const [workloadData, setWorkloadData] = useState([]);
  const [finalWorkspaceCount, setFinalWorkspaceCount] = useState(0);
  const [totalWorkspaceCount, setTotalWorkspaceCount] = useState(0);
  const [inProgressWorkspaceCount, setInProgressWorkspaceCount] = useState(0);
  const [agreementWorkspaceCount, setAgreementWorkspaceCount] = useState(0);
  const [inProgressPercentage, setInProgressPercentage] = useState(0);
  const [agreementPercentage, setAgreementPercentage] = useState(0);
  const [finishedPercentage, setFinishedPercentage] = useState(0);
  const [unassignedTotalWorkspaceCount, setUnassignedTotalWorkspaceCount] =
    useState(0);
  const [selectedYear, setSelectedYear] = useState(
    years.values[currentYear.toString()]
  );

  const getWorkloadData = async () => {
    setIsLoading(true);
    await fetchWorkloadData(selectedYear, setWorkloadData);
    setIsLoading(false);
  };

  useEffect(() => {
    getWorkloadData();
  }, [selectedYear]);

  console.log(workloadData);

  useEffect(() => {
    setUnassignedTotalWorkspaceCount(
      valueHandler(
        workloadData[
          workloadData?.findIndex((x) => x.title === "Неназначенное")
        ]?.pkUnassignedCount?.workspaceCount
      ) +
        valueHandler(
          workloadData[
            workloadData.findIndex((x) => x.title === "Неназначенное")
          ]?.soutUnassignedCount?.workspaceCount
        ) +
        valueHandler(
          workloadData[
            workloadData.findIndex((x) => x.title === "Неназначенное")
          ]?.oprUnassignedCount?.workspaceCount
        )
    );
    console.log(workloadData);
    setFinalWorkspaceCount(
      workloadData.reduce((res, project) => {
        return (res += valueHandler(
          project?.finish?.allSummed?.workspaceCount
        ));
      }, 0)
    );
    setInProgressWorkspaceCount(
      workloadData.reduce((res, project) => {
        return (res +=
          valueHandler(
            project?.informationCollection?.allSummed?.workspaceCount
          ) +
          valueHandler(project?.readyToWork?.allSummed?.workspaceCount) +
          valueHandler(project?.waitingForRequest?.allSummed?.workspaceCount) +
          valueHandler(project?.acceptedToWork?.allSummed?.workspaceCount));
      }, 0)
    );
    setAgreementWorkspaceCount(
      workloadData.reduce((res, project) => {
        return (res += valueHandler(
          project?.waitingAndAgreement?.allSummed?.workspaceCount
        ));
      }, 0)
    );
    setTotalWorkspaceCount(
      inProgressWorkspaceCount +
        finalWorkspaceCount +
        agreementWorkspaceCount +
        unassignedTotalWorkspaceCount
    );
    setInProgressPercentage(
      Math.round((inProgressWorkspaceCount / totalWorkspaceCount) * 100)
    );
    setAgreementPercentage(
      Math.round((agreementWorkspaceCount / totalWorkspaceCount) * 100)
    );
    setFinishedPercentage(
      Math.round((finalWorkspaceCount / totalWorkspaceCount) * 100)
    );
  }, [
    workloadData,
    finalWorkspaceCount,
    inProgressWorkspaceCount,
    agreementWorkspaceCount,
    totalWorkspaceCount,
    unassignedTotalWorkspaceCount,
  ]);

  useEffect(() => {
    console.log(workloadData);
  }, [workloadData]);

  const valueHandler = (value) => (isNaN(value) ? 0 : value);

  return (
    <main className="workspace">
      <Sidebar />
      <div className="workspace__content">
        <section className="workspace__wrapper">
          <h4 className="workspace__title workspace__page-title">
            Загруженность сотрудников &nbsp;
            <div
              style={{
                width: 250,
                marginTop: 30,
              }}
            >
              <Select
                label="Год заключения договора"
                alignLabel="left"
                dataSrc="local"
                value={selectedYear}
                data={years}
                onChange={(e) => setSelectedYear(years.values[e.target.value])}
              />
            </div>
            {workloadData &&
              workloadData
                .filter((data) => data.title === "Неназначенное")
                .map((data) => {
                  return (
                    <div style={{ marginTop: 20 }}>
                      <p>{data.title}:</p>
                      <p>
                        СОУТ [
                        {projectsSlashWorkspaces(
                          valueHandler(
                            data?.unassigned?.all?.sout?.projectsCount
                          ),
                          workspaceCountView(
                            valueHandler(
                              data?.unassigned?.all?.sout?.workspaceCount
                            ),
                            data?.unassigned?.all?.sout?.withX
                          )
                        )}
                        ]
                      </p>
                      <p>
                        ПК [
                        {projectsSlashWorkspaces(
                          valueHandler(
                            data?.unassigned?.all?.pk?.projectsCount
                          ),
                          workspaceCountView(
                            valueHandler(
                              data?.unassigned?.all?.pk?.workspaceCount
                            ),
                            data?.unassigned?.all?.pk?.withX
                          )
                        )}
                        ]
                      </p>
                      <p>
                        ОПР [
                        {projectsSlashWorkspaces(
                          valueHandler(
                            data?.unassigned?.all?.opr?.workspaceCount
                          ),
                          workspaceCountView(
                            valueHandler(
                              data?.unassigned?.all?.opr?.workspaceCount
                            ),
                            data?.unassigned?.all?.opr?.withX
                          )
                        )}
                        ]
                      </p>
                      <p>
                        Общее [
                        {projectsSlashWorkspaces(
                          valueHandler(
                            data?.unassigned?.allSummed?.projectsCount
                          ),
                          workspaceCountView(
                            data?.unassigned?.allSummed?.workspaceCount
                          )
                        )}
                        ]
                      </p>
                    </div>
                  );
                })}
          </h4>
          {isLoading ? (
            <Spinner />
          ) : (
            <StatisticTable tableType="workload" projects={workloadData} />
          )}
          {!isLoading && (
            <>
              <h4>
                В исполнении: {valueHandler(inProgressWorkspaceCount)} (
                {inProgressPercentage}%){" "}
                {workloadData
                  .map(
                    (el) =>
                      checkObject(el.containsX?.InformationRequest) ||
                      checkObject(el.containsX?.ReadyToWork) ||
                      checkObject(el.containsX?.AcceptedToWork)
                  )
                  .some((i) => i) && " + x"}
              </h4>
              <h4>
                В согласовании: {valueHandler(agreementWorkspaceCount)} (
                {agreementPercentage}%){" "}
                {workloadData
                  .map((el) => el.containsX?.Agreement)
                  .some((i) => (i ? checkObject(i) : false)) && " + x"}
              </h4>
              <h4>
                Завершённые: {valueHandler(finalWorkspaceCount)} (
                {finishedPercentage}
                %)
              </h4>
              <h4>
                Всего:{" "}
                {workspaceCountView(
                  valueHandler(totalWorkspaceCount),
                  workloadData.some((el) => checkObject(el.containsX))
                )}
              </h4>
            </>
          )}
        </section>
      </div>
    </main>
  );
}

export default Workload;
