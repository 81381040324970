import { api } from 'settings/Api';

export const rejectStage = async (contractID, isFlag = false) => {
	const url = isFlag ?`/api/sout/admin/contracts/${contractID}`:`/api/sout/workflow/contract/reject/${contractID}` ;

	const token = JSON.parse(localStorage.getItem("authTokens")).data.accessToken;
	let res = await api(url, {
		method: isFlag?"DELETE":"GET",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		},
	});
	let data = await res.json();
	return res;
};