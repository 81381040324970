const QUERIES = {
  id: 'id',
  ArchiveId: 'ArchiveId',
  AssesmentTypeId:
  {
    key: 'AssesmentTypeId',
    title: 'Тип оценки',
    ProfessionalRisks: {
      key: 'ProfessionalRisks',
      value: 'Нет'
    },
    WorkingConditionsSpecialAssesment: {
      key: 'WorkingConditionsSpecialAssesment',
      value: 'Специальная оценка условий труда'
    },
    ProductionControl: {
      key: 'ProductionControl',
      value: 'Производственный контроль'
    }
  },
  ExecutionProgramId:
  {
    key: 'ExecutionProgramId',
    title: 'Программа выполнения',
    None: {
      key: 'None',
      value: 'Нет'
    },
    LaborExpert_3_0: {
      key: 'LaborExpert_3_0',
      value: 'ТрудЭксперт'
    },
    Attestation_5_1: {
      key: 'Attestation_5_1',
      value: 'Аттестация'
    }
  },
  INN: {
    key: 'INN',
    title: 'ИНН'
  },
  TenderId: {
    key: 'TenderId',
    title: 'ID тендера'
  },
  ProjectStatusId: {
    key: 'ProjectStatusId',
    title: 'Этап проекта',
    stages: [
      { Draft: 'Черновик' },
      { Filed: 'Заявка на тендер подана' },
      { Won: 'Победа в тендере' },
      { Signing: 'Подписание договора' },
      { InformationRequest: 'Сбор информации' },
      { WaitingForRequest : 'Ожидание заявки' },
      { Measurements: 'Замеры' },
      { PerformerAppointed: 'Исполнитель назначен' },
      { AcceptedToWork: 'Принято в работу' },
      { Agreement: 'Согласовано' },
      { Printed: 'Распечатано' },
      { Sent: 'Отправлено заказчику' },
      { Finish: 'Проект завершен' },
      { Archive: 'Архивирован' },
      { NotFiled: 'Заявка на тендер не подана' },
      { Lost: 'Тендер проигран' },
      { Terminated: 'Договор расторгнут' },
      { NotSigned: 'Договор не подписан' },
      { Basket: 'В списке на удаление' },
      { Payment: 'Оплата' },
      { ReturnOfSecurity: 'Возврат обеспечения' },
      { UnloadingDocuments: 'Выгрузка' },
      { Verification: 'Проверка'},
      { Errors: 'Ошибки',}
    ]
  },
  SearchString: {
    key: 'searchstring'
  },
  SortMethod: {
    key: 'SortMethod',
    title: 'Сортировать по:',
    Asc: {
      key: 'Asc',
      value: 'По возрастанию'
    },
    Desc: {
      key: 'Desc',
      value: 'По убыванию'
    }
  },
  HaveWorkbook: {
    key: 'HaveWorkbook',
  },
  UnitCount: {
    key: 'UnitCount',
  },
  WorkspaceCount: {
    key: 'WorkspaceCount'
  },
  OfferSecuringMethodId: {
    key: 'OfferSecuringMethodId'
  },
  ContractSecuringMethodId: {
    key: 'ContractSecuringMethodId'
  },
  TradingFloorCommisionId: {
    key: 'TradingFloorCommisionId'
  },
  TypeId: {
    key: 'TypeId'
  },
  SortField: {
    key: 'SortField'
  },


  FromAcceptingOffersDeadline: {
    key: 'FromAcceptingOffersDeadline',
  },
  ToAcceptingOffersDeadline: {
    key: 'ToAcceptingOffersDeadline'
  },

  FromPlannedExecutionDate: {
    key: 'FromPlannedExecutionDate'
  },
  ToPlannedExecutionDate: {
    key: 'ToPlannedExecutionDate'
  },

  FromPublicationDate: {
    key: 'FromPublicationDate'
  },
  ToPublicationDate: {
    key: 'ToPublicationDate'
  },

  FromConclusionContractDate: {
    key: 'FromConclusionContractDate'
  },
  ToConclusionContractDate: {
    key: 'ToConclusionContractDate'
  },

  FromContractPrice: {
    key: 'FromContractPrice'
  },
  ToContractPrice: {
    key: 'ToContractPrice'
  },

  FromAuctionDate: {
    key: 'FromAuctionDate'
  },
  ToAuctionDate: {
    key: 'ToAuctionDate'
  },


  TenderDepartmentEmployeeId: {
    key: 'TenderDepartmentEmployeeId'
  },
  CommercialDepartmentEmployeeId: {
    key: 'CommercialDepartmentEmployeeId'
  },
  LaboratoryDepartmentEmployeeId: {
    key: 'LaboratoryDepartmentEmployeeId'
  },
  WorkerId: {
    key: 'WorkerId'
  }


};
export default QUERIES;

