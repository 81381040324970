import { getBaseUrl, departmentTypeKey } from "../utils/BaseUrl";
import { useNavigate } from "react-router-dom";
import { api } from "settings/Api";

function BlockModal({ closeModal, user, action, isChange, setIsChange }) {
  const ADMIN_ID = 1;
  const navigate = useNavigate();

  const blockUser = async () => {
    const token = JSON.parse(localStorage.getItem("authTokens")).data
      .accessToken;

    api("/api/sout/admin/users?", {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: user.state.id,
        name: user.state.name,
        lastName: user.state.lastName,
        branchOfficeId: user.state.branchOfficeId,
        userName: user.state.userName,
        password: user.state.password,
        departmentId: user.state.departmentId,
        organizationId: user.state.organizationId,
        email: user.state.email,
        positionId: user.state.positionId,
        isActive: false,
      }),
    }).then((res) => {
      if (res.status === 200 || res.ok) {
        alert("Пользователь заблокирован!");
        closeModal(false);
        setIsChange(!isChange);
        navigate("/ListOfUsers");
      } else if (res.status === 500 || !res.ok) {
        alert("Пользователь не заблокирован!");
        closeModal(false);
      }
    });
  };

  return (
    <>
      <section className="modal" data-action="modal-exit">
        <div className="modal__content">
          <button
            onClick={() => closeModal(false)}
            className="modal__close-button close-button"
          >
            &#10005;
          </button>
          <h3 className="modal__description">
            Заблокировать пользователя
            <b>
              &nbsp;{user.state.name}&nbsp;{user.state.lastName}
            </b>
            . Вы уверены?
          </h3>
          <div className="modal__buttons-wrapper">
            <button
              onClick={blockUser}
              className="button button--red"
              disabled={ADMIN_ID === user.state.id ? true : false}
            >
              Да
            </button>
            <button
              onClick={() => closeModal(false)}
              className="button close-button"
            >
              Нет
            </button>
          </div>
        </div>
      </section>
    </>
  );
}
export default BlockModal;
