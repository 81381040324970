import React, { useEffect, useContext, useState, useCallback } from "react";
import { Select } from "components/Select/Select";
import InputDiapason from "components/InputDiapason/InputDiapason";
import Spinner from "components/Spinner";
import { ActionButtons } from "components/ActionButtons";
import ContractViewModal from "entities/contracts/modals/ContractViewModal";
import { Pagination } from "components/Pagination/Pagination";
import ContractContext from "context/ContractContext";
import { RecordsShowTable } from "components/RecordsShowTable/RecordsShowTable";
import { moveToNextStage } from "../service/moveToNextStage";
import { moveToPrevStage } from "../service/moveToPrevStage";
import { rejectStage } from "../service/rejectStage";
import { FaSearch, FaSort } from "react-icons/fa";
import { GoPlus } from "react-icons/go";
import DocumentContext from "context/DocumentsContext";
import { Button } from "shared/ui/Button/Button";
import { ContractConclusionDepartmentChoiceModal } from "../modals/ContractConclusionDepartmentChoiceModal";
import { useNavigate, useParams } from "react-router-dom";
import SearchSelectRegions from "components/common/SearchSelectRegions";
import AuthContext from "context/AuthContext";
import { isCommercialUser, isTenderUser } from "utils/defineUser";
import { ContractSortMap } from "./contractSortMap";
import { fetchAccount } from "utils/fetchAccount";

const SearchContracts = ({ className, stage }) => {
  const {
    isLoading,
    setIsLoading,
    commercialEmployees,
    tenderEmployees,
    labEmployees,
    workers,
    selectedSortField,
    setSelectedSortField,
    selectedAdditionalField,
    setSelectedAdditionalField,
    sortFields,
    additionalFields,
    initialSortParams,
    initialSearchParams,
    sortParams,
    setSortParams,
    page,
    setPage,
    totalCountPages,
    totalRowCount,
    contracts,
    values,
    setValues,
    toggleAllContractCheck,
    account,
    getContracts,
    setAccount,
    getWorkers,
    getEmployees,
  } = useContext(ContractContext);

  const { accessRights, role } = useContext(AuthContext);

  const [sortIsShowed, setSortIsShowed] = useState(false);
  const [searchIsShowed, setSearchIsShowed] = useState(false);
  const [resetButtonClicked, setResetButtonClicked] = useState(false);

  const [
    contractConclusionDepartmentOpenModal,
    setContractConclusionDepartmentOpenModal,
  ] = useState(false);

  const { entities } = useContext(DocumentContext);
  const [regions, setRegions] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setPage(1);
  }, [stage]);

  useEffect(() => {
    setIsLoading(true);
    getContracts(stage);
    getEmployees();
    getWorkers();
    setResetButtonClicked(false);
  }, [page, stage, resetButtonClicked, account]);

  const inputChangeHandler = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  useEffect(() => {
    console.log("change account");
    fetchAccount().then((res) => {
      setAccount(res);
    });
  }, []);

  const updateRegions = (updatedRegions) => {
    if (updatedRegions.length >= 1) {
      const regionId = updatedRegions[0].id;
      setRegions(updatedRegions);
      setValues({ ...values, Regions: regionId });
    } else if (updatedRegions.length === 0) {
      setRegions([]);
      setValues({ ...values, Regions: null });
    }
  };

  const handleSearchClick = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setPage(1);
    getContracts(stage);
  };

  const handleSortClick = (e) => {
    e.preventDefault();

    if (!sortParams.SortField || sortParams.SortField === "null") {
      alert("Выберите параметр сортировки");
      return;
    }
    setIsLoading(true);
    setPage(1);
    getContracts(stage);
  };

  const handleReset = () => {
    setValues({});
    setSortParams({});
    setSelectedSortField(null);
    setSelectedAdditionalField(null);
    setRegions([]);
    setResetButtonClicked(true);
  };

  const handleDepartmentSelect = async (e) => {
    setValues({ ...values, ContractConclusionDepartmentId: e.target.value });
    getEmployees();
  };

  console.log(stage);

  const toggleSortButton = () => {
    if (sortIsShowed) {
      setSortIsShowed(false);
      return;
    }
    setSearchIsShowed(false);
    setSortIsShowed(true);
  };

  const toggleSearchButton = () => {
    if (searchIsShowed) {
      setSearchIsShowed(false);
      return;
    }
    setSortIsShowed(false);
    setSearchIsShowed(true);
  };

  const returnFullName = (contract) => {
    let result = contract.customer.name;
    if (contract.additionalName) result += ` (${contract.additionalName})`;
    return result;
  };
  return (
    <div className={className}>
      {contractConclusionDepartmentOpenModal && (
        <ContractConclusionDepartmentChoiceModal
          close={() => setContractConclusionDepartmentOpenModal(false)}
        />
      )}
      <section className="workspace__action-panel">
        <div
          style={{
            width: "90%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div className="contracts__buttons">
            {accessRights[role].canChange.includes("contract") && (
              <Button
                color="blue"
                onClick={() => {
                  if (isCommercialUser() || isTenderUser()) {
                    navigate(`/sout/contracts/add/new`);
                    return;
                  }
                  setContractConclusionDepartmentOpenModal(true);
                }}
                style={{
                  minWidth: "210px",
                  marginRight: "60px",
                }}
              >
                <GoPlus />
                <div>Добавить договор</div>
              </Button>
            )}

            <button
              className={`button search-area__search-button button--blue contracts__search-button ${
                searchIsShowed ? "button--blue--active" : ""
              }`}
              onClick={toggleSearchButton}
            >
              <FaSearch className="search-button__icon" />
              <span className="search-button__text">Поиск</span>
            </button>
            <button
              className={`button search-area__search-button button--blue ${
                sortIsShowed ? "button--blue--active" : ""
              }`}
              onClick={toggleSortButton}
            >
              <FaSort className="search-button__icon icon-sort" />
              <span className="search-button__text">Сортировка</span>
            </button>
          </div>
          {(stage.title === "Подготовка и исполнение" ||
            stage.title === "Ожидание выгрузки") &&
            role === "admin" && (
              <button
                className={`button search-area__search-button button--blue`}
                onClick={() => toggleAllContractCheck(stage)}
              >
                {`Перевести на этап ${
                  stage.title === "Подготовка и исполнение"
                    ? "актов"
                    : "завершенные"
                }`}
              </button>
            )}
        </div>
        {sortIsShowed || searchIsShowed ? (
          <form className={`form contracts__form`} onReset={handleReset}>
            {sortIsShowed && (
              <>
                <div style={{ width: 650 }}>
                  <div className={`search-area__fieldset`}>
                    <fieldset className="search-area__filters search-area__filters--select-area">
                      <h4 className="workspace__title">Сортировка</h4>
                      <Select
                        className="workspace__input-title"
                        label="Сортировать по:"
                        name="SortMethod"
                        dataSrc="local"
                        value={
                          sortParams.SortMethod === null
                            ? "null"
                            : sortParams.SortMethod
                        }
                        onChange={(e) =>
                          setSortParams({
                            ...sortParams,
                            SortMethod: e.target.value,
                          })
                        }
                        data={{
                          values: {
                            desc: "по убыванию",
                            "": "по возрастанию",
                          },
                        }}
                      />

                      <Select
                        className="workspace__input-title"
                        label="Параметры сортировки:"
                        name="SortField"
                        dataSrc="local"
                        value={
                          sortParams.SortField === null
                            ? "null"
                            : sortParams.SortField
                        }
                        onChange={(e) =>
                          setSortParams({
                            ...sortParams,
                            SortField: e.target.value,
                          })
                        }
                        data={{
                          values: sortFields,
                        }}
                      />
                    </fieldset>
                    <fieldset
                      className="search-area__filters search-area__filters--select-area"
                      style={{ marginLeft: "40px" }}
                    >
                      <h4 className="workspace__title">
                        Дополнительная информация
                      </h4>

                      <Select
                        className="workspace__input-title"
                        label="Дополнительная информация"
                        name="AdditionalInfo"
                        dataSrc="local"
                        onChange={(e) =>
                          setSelectedAdditionalField(e.target.value)
                        }
                        value={
                          selectedAdditionalField === null
                            ? "null"
                            : selectedAdditionalField
                        }
                        data={{
                          values: additionalFields,
                        }}
                      />
                    </fieldset>
                  </div>
                </div>
                <div
                  className="buttons form__buttons"
                  style={{
                    width: "25%",
                    marginTop: "40px",
                  }}
                >
                  <button
                    className="button button--blue"
                    onClick={(e) => handleSortClick(e)}
                  >
                    <div>Сортировать</div>
                  </button>
                  <button
                    className="button button--blue"
                    type="reset"
                    // onClick={(e) => handleReset(e)}
                  >
                    <div>Сбросить</div>
                  </button>
                </div>
              </>
            )}
            {searchIsShowed && (
              <>
                <h4
                  className="subtitle"
                  style={{ margin: "20px 0 0 0", fontSize: "18px" }}
                >
                  Поиск
                </h4>

                <fieldset className="workspace__ensure-wrapper contracts__ensure-wrapper">
                  <div
                    className="grid-line"
                    style={{
                      gridTemplateColumns: "1fr 5fr 3fr",
                      columnGap: "15px",
                    }}
                  >
                    <label className="workspace__input-title contracts__input-title">
                      ID договора
                      <input
                        className="input contracts__input"
                        type="text"
                        name="id"
                        value={values.id}
                        onChange={(e) => inputChangeHandler(e)}
                      />
                    </label>
                    <label className="workspace__input-title contracts__input-title ">
                      Поиск по номеру договора, заказчика и др.
                      <input
                        className="input contracts__input"
                        type="text"
                        name="SearchString"
                        value={values.SearchString}
                        onChange={(e) => inputChangeHandler(e)}
                      />
                    </label>
                    <Select
                      className="workspace__input-title"
                      label="Отдел заключения договоров"
                      name="ContractConclusionDepartmentId"
                      data={{
                        values: {
                          TenderDepartment: "Тендерный отдел",
                          CommercialDepartment: "Коммерческий отдел",
                        },
                      }}
                      value={
                        values.ContractConclusionDepartmentId === null
                          ? "null"
                          : values.ContractConclusionDepartmentId
                      }
                      dataSrc="local"
                      onChange={(e) => handleDepartmentSelect(e)}
                    />
                  </div>

                  <div className="grid-line">
                    <label className="workspace__input-title contracts__input-title">
                      ИНН заказчика
                      <input
                        className="input contracts__input"
                        type="text"
                        maxLength="12"
                        name="CustomerINN"
                        value={values.CustomerINN}
                        onChange={(e) => inputChangeHandler(e)}
                        onPaste={(e) => inputChangeHandler(e)}
                        pattern="^(\d{10}|\d{12})$"
                      />
                    </label>

                    <InputDiapason
                      title="Цена договора"
                      type="number"
                      name={["FromPrice", "ToPrice"]}
                      value={[values.FromPrice, values.ToPrice]}
                      onChange={[
                        (e) =>
                          setValues({ ...values, FromPrice: e.target.value }),
                        (e) =>
                          setValues({ ...values, ToPrice: e.target.value }),
                      ]}
                      placeholder={["от", "до"]}
                      className="contracts__input"
                    />
                    <InputDiapason
                      title="Дата заключения договора"
                      type="date"
                      name={[
                        "FromConclusionContractDate",
                        "ToConclusionContractDate",
                      ]}
                      value={[
                        values.FromConclusionContractDate,
                        values.ToConclusionContractDate,
                      ]}
                      onChange={[
                        (date) =>
                          setValues({
                            ...values,
                            FromConclusionContractDate: date,
                          }),
                        (date) =>
                          setValues({
                            ...values,
                            ToConclusionContractDate: date,
                          }),
                      ]}
                      className="contracts__input"
                      placeholder="дд.мм.гггг"
                      isRequired={false}
                    />
                  </div>

                  <div className="grid-line">
                    <label className="workspace__input-title contracts__input-title">
                      КПП
                      <input
                        className="input contracts__input"
                        type="text"
                        value={values.KPP}
                        onChange={(e) =>
                          setValues({ ...values, KPP: e.target.value })
                        }
                        name="KPP"
                      />
                    </label>
                    <InputDiapason
                      title="Сумма по акту"
                      type="number"
                      value={[values.FromActSum, values.ToActSum]}
                      name={["FromActSum", "ToActSum"]}
                      placeholder={["от", "до"]}
                      className="contracts__input"
                      onChange={[
                        (e) =>
                          setValues({
                            ...values,
                            FromActSum: e.target.value,
                          }),
                        (e) =>
                          setValues({ ...values, ToActSum: e.target.value }),
                      ]}
                    />
                    <InputDiapason
                      title="Плановая дата исполнения"
                      type="date"
                      name={[
                        "FromPlannedExecutionDate",
                        "ToPlannedExecutionDate",
                      ]}
                      value={[
                        values.FromPlannedExecutionDate,
                        values.ToPlannedExecutionDate,
                      ]}
                      onChange={[
                        (date) =>
                          setValues({
                            ...values,
                            FromPlannedExecutionDate: date,
                          }),
                        (date) =>
                          setValues({
                            ...values,
                            ToPlannedExecutionDate: date,
                          }),
                      ]}
                      className="contracts__input"
                      placeholder="дд.мм.гггг"
                      isRequired={false}
                    />
                  </div>
                  <div
                    className="grid-line"
                    style={{
                      gridTemplateColumns: "2fr 3fr 1fr",
                      marginBottom: "15px",
                    }}
                  >
                    {/* <SelectRegions isSearchBar={true}/> */}
                    <div style={{ marginTop: 10 }}>
                      <SearchSelectRegions
                        isSearchBar
                        regions={regions}
                        updateRegions={updateRegions}
                        color={"blue"}
                      />
                    </div>
                  </div>
                </fieldset>
                <div
                  className="line"
                  style={{
                    borderBottom: "2px solid #000",
                    marginBottom: "15px",
                  }}
                ></div>
                <div className="grid-line">
                  <label className="workspace__input-title contracts__input-title">
                    Идентификационный номер
                    <input
                      className="input contracts__input"
                      type="text"
                      name="DepartmentNumber"
                      value={values?.DepartmentNumber}
                      onChange={(e) => inputChangeHandler(e)}
                    />
                  </label>
                  <Select
                    className="workspace__input-title"
                    label="Тендерный отдел"
                    name="TenderDepartmentUserId"
                    dataSrc="server"
                    data={tenderEmployees}
                    value={
                      sortParams.TenderDepartmentUserId === null
                        ? "null"
                        : sortParams.TenderDepartmentUserId
                    }
                    onChange={(e) =>
                      setValues({
                        ...values,
                        TenderDepartmentUserId: e.target.value,
                      })
                    }
                    disabled={false}
                  />
                  <Select
                    className="workspace__input-title"
                    label="Коммерческий отдел"
                    name="CommercialDepartmentUserId"
                    dataSrc="server"
                    data={commercialEmployees}
                    value={
                      sortParams.CommercialDepartmentUserId === null
                        ? "null"
                        : sortParams.CommercialDepartmentUserId
                    }
                    onChange={(e) =>
                      setValues({
                        ...values,
                        CommercialDepartmentUserId: e.target.value,
                      })
                    }
                    disabled={false}
                  />
                </div>

                <div className="grid-line">
                  <InputDiapason
                    title="Число РМ"
                    type="number"
                    name={["FromWorkspaceCount", "ToWorkspaceCount"]}
                    value={[values.FromWorkspaceCount, values.ToWorkspaceCount]}
                    onChange={[
                      (e) =>
                        setValues({
                          ...values,
                          FromWorkspaceCount: e.target.value,
                        }),
                      (e) =>
                        setValues({
                          ...values,
                          ToWorkspaceCount: e.target.value,
                        }),
                    ]}
                    placeholder={["от", "до"]}
                    className="contracts__input"
                  />
                  <InputDiapason
                    placeholder="дд.мм.гггг"
                    title="Дата акта"
                    type="date"
                    name={["FromActDate", "ToActDate"]}
                    value={[values.FromActDate, values.ToActDate]}
                    className="contracts__input"
                    onChange={[
                      (date) => setValues({ ...values, FromActDate: date }),
                      (date) => setValues({ ...values, ToActDate: date }),
                    ]}
                  />
                  <label className="workspace__input-title contracts__input-title">
                    Внутренний номер
                    <input
                      className="input contracts__input"
                      type="text"
                      value={values.innerNumber}
                      onChange={(e) =>
                        setValues({ ...values, innerNumber: e.target.value })
                      }
                      name="innerNumber"
                    />
                  </label>
                </div>
                <div
                  className="grid-line"
                  style={{
                    gridTemplateColumns: "1fr 1.5fr 1.5fr 2fr",
                    marginBottom: "30px",
                  }}
                ></div>
                <div
                  className="grid-line"
                  style={{ gridTemplateColumns: "215px repeat(2, 1fr)" }}
                >
                  <div className="buttons form__buttons">
                    <button
                      className="button button--blue"
                      onClick={(e) => handleSearchClick(e)}
                    >
                      Найти
                    </button>
                    <button
                      className="button button--blue"
                      type="reset"
                      // onClick={(e) => handleReset(e)}
                    >
                      Сбросить
                    </button>
                  </div>
                </div>
              </>
            )}
          </form>
        ) : null}
      </section>
      <h3
        style={{
          marginBottom: "20px",
        }}
      >
        {stage ? stage.title : "Загрузка..."}
      </h3>
      {totalCountPages > 1 && (
        <Pagination
          color={"blue"}
          totalRowCount={totalRowCount}
          currentPage={page}
          totalPagesCount={totalCountPages}
          toStart={() => setPage(1)}
          toEnd={() => setPage(totalCountPages)}
          forward={() => setPage(page + 1)}
          back={() => setPage(page - 1)}
          change={(to) => setPage(to)}
          type={"contract"}
        />
      )}

      {isLoading ? (
        <Spinner />
      ) : contracts && contracts.length !== 0 ? (
        <RecordsShowTable
          page={page}
          type={"contract"}
          records={contracts}
          sortMap={ContractSortMap}
          actionButtons={(contract) => (
            <ActionButtons
              onEdit={() => {
                navigate(`/sout/contracts/edit/${contract.id}`);
              }}
              getInfoModal={(modalHandler) => (
                <ContractViewModal
                  contractID={contract.id}
                  modalHandler={modalHandler}
                />
              )}
              documentsInfo={{
                id: contract.id,
                entity: entities.contracts,
                documents: contract.documents,
              }}
              contract={contract}
              stage={contract.workflow.workFlowStatusId}
              moveToNextStage={async () => {
                await moveToNextStage(contract.workflow.id);
                setIsLoading(true);
                getContracts(stage);
              }}
              moveToPrevStage={async () => {
                await moveToPrevStage(contract.workflow.id);
                setIsLoading(true);
                getContracts(stage);
              }}
              // suspendContract={async () => {
              //   await suspendContraсt(contract.workflow.id);
              //   setIsLoading(true);
              //   getContracts(stage);
              // }}
              reject={async () => {
                await rejectStage(
                  stage?.title === "Корзина"
                    ? contract.id
                    : contract.workflow.id,
                  stage?.title === "Корзина"
                );
                setIsLoading(true);
                getContracts(stage);
              }}
              disabledButtons={accessRights[role]["contract"]}
              colorTheme="blue"
            />
          )}
          sortFields={sortFields}
          additionalFields={additionalFields}
          selectedAdditionalField={selectedAdditionalField}
          selectedSortField={selectedSortField}
          headers={[
            {
              value: "id",
              text: "ID",
            },
            {
              value: "customerName",
              text: "Заказчик",
            },
            {
              value: "customerINN",
              text: "ИНН",
            },
            {
              value: "contractNumber",
              text: "Номер договора",
            },
          ]}
          getDataFns={{
            id: (contract) => contract.id,
            customerName: (contract) => returnFullName(contract),
            customerINN: (contract) => contract.customer.inn,
            contractNumber: (contract) => contract.contractNumber,
          }}
        />
      ) : (
        <p style={{ marginTop: "20px" }}>Нет договоров...</p>
      )}
    </div>
  );
};
export default SearchContracts;
