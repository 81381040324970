import { SidebarList } from "components/SidebarList";
import React from "react";
import { FaBars, FaSearch } from "react-icons/fa";
import {
  isAdmin,
  isCommercialUser,
  isExpert,
  isLabAssistant,
  isLabEmployee,
  isLabExpert,
  isLabHead,
  isLabHeadDeputy,
  isTenderUser,
  isСhieftain,
} from "utils/defineUser";

const ContractSidebar = ({
  user,
  role,
  isMenuShown,
  toggleSidebar,
  isOpen,
}) => {
  return (
    <>
      {isCommercialUser() || isAdmin() ? (
        <SidebarList
          title="Коммерческий отдел"
          titleIsOpen={isOpen.commercial}
          toggleSidebar={() => toggleSidebar("commercial")}
          cantShowed={isAdmin() && !isOpen.commercial}
          color="blue"
          items={[
            {
              link: "/sout/contracts/GlobalSearch",
              content: (
                <>
                  <FaSearch className="sidebar__icon-search" />{" "}
                  <b className="sidebar__search-span">Общий поиск</b>
                </>
              ),
              type: "default",
            },
            {
              link: "/sout/contracts/Actual",
              content: (
                <>
                  <FaBars className="sidebar__icon-search" />{" "}
                  <b className="sidebar__search-span">Все актуальные </b>
                </>
              ),
              type: "default",
            },
            {
              link: "/sout/contracts/Draft",
              content: "Черновик",
              type: "default",
            },
            {
              link: "/sout/contracts/IdAssigning",
              content: "Присвоение ID",
              type: "default",
            },
            {
              link: "/sout/contracts/SigningAndCollectingInformation",
              content: "Подписание и сбор информации",
              type: "default",
            },
            {
              link: "/sout/contracts/InExecutionForCommercials",
              content: "В исполнении",
              type: "default",
            },
            {
              link: "/sout/contracts/Acts",
              content: "Акты",
              type: "default",
            },
            {
              link: "/sout/contracts/WaitingForUnloadingDocuments",
              content: "Ожидание выгрузки",
              type: "default",
            },
            {
              link: "/sout/contracts/Finish",
              content: "Завершенные",
              type: "default",
            },
            {
              link: "/sout/contracts/Archive",
              content: "Архив",
              type: "archive",
            },
            {
              link: "/sout/contracts/Suspended",
              content: "Приостановленные",
              type: "no-result-space",
            },
            {
              link: "/sout/contracts/Basket",
              content: "Корзина",
              type: "no-result",
            },
            {
              link: "/sout/contracts/Terminated",
              content: "Расторгнутые",
              type: "no-result",
            },
          ]}
        />
      ) : null}
      {isTenderUser() || isAdmin() ? (
        <SidebarList
          title="Тендерный отдел"
          titleIsOpen={isOpen.tender}
          toggleSidebar={() => toggleSidebar("tender")}
          cantShowed={isAdmin() && !isOpen.tender}
          color="blue"
          items={[
            {
              link: "/sout/contracts/GlobalSearch",
              content: (
                <>
                  <FaSearch className="sidebar__icon-search" />{" "}
                  <b className="sidebar__search-span">Общий поиск</b>
                </>
              ),
              type: "default",
            },
            {
              link: "/sout/contracts/Actual",
              content: (
                <>
                  <FaBars className="sidebar__icon-search" />{" "}
                  <b className="sidebar__search-span">Все актуальные </b>
                </>
              ),
              type: "default",
            },
            {
              link: "/sout/contracts/Draft",
              content: "Черновик",
              type: "default",
            },
            {
              link: "/sout/contracts/IdAssigning",
              content: "Присвоение ID",
              type: "default",
            },
            {
              link: "/sout/contracts/InExecutionForTenders",
              content: "В исполнении",
              type: "default",
            },
            {
              link: "/sout/contracts/Finish",
              content: "Завершенные",
              type: "default",
            },
            {
              link: "/sout/contracts/Archive",
              content: "Архив",
              type: "archive",
            },
            {
              link: "/sout/contracts/Basket",
              content: "Корзина",
              type: "no-result-space",
            },
            {
              link: "/sout/contracts/Terminated",
              content: "Расторгнутые",
              type: "no-result",
            },
          ]}
        />
      ) : null}
      {isLabHead() || isAdmin() ? (
        <SidebarList
          title="Начальник лаборатории"
          titleIsOpen={isOpen.lab_chief}
          toggleSidebar={() => toggleSidebar("lab_chief")}
          cantShowed={isAdmin() && !isOpen.lab_chief}
          color="blue"
          items={[
            {
              link: "/sout/contracts/GlobalSearch",
              content: (
                <>
                  <FaSearch className="sidebar__icon-search" />{" "}
                  <b className="sidebar__search-span">Общий поиск</b>
                </>
              ),
              type: "default",
            },
            {
              link: "/sout/contracts/Actual",
              content: (
                <>
                  <FaBars className="sidebar__icon-search" />{" "}
                  <b className="sidebar__search-span">Все актуальные </b>
                </>
              ),
              type: "default",
            },
            {
              link: "/sout/contracts/IdAssigning",
              content: "Присвоение ID",
              type: "default",
            },
            {
              link: "/sout/contracts/SigningAndCollectingInformation",
              content: "Подписание и сбор информации",
              type: "default",
            },
            {
              link: "/sout/contracts/InformationRequest",
              content: "Запрос информации",
              type: "default",
            },
            {
              link: "/sout/contracts/LocationsCreation",
              content: "Создание замерных блоков",
              type: "default",
            },
            {
              link: "/sout/contracts/ProjectsCreation",
              content: "Создание проектов",
              type: "default",
            },
            {
              link: "/sout/contracts/PreparationAndExecution",
              content: "Подготовка и исполнение",
              type: "default",
            },
            {
              link: "/sout/contracts/Acts",
              content: "Акты",
              type: "default",
            },

            {
              link: "/sout/contracts/WaitingForUnloadingDocuments",
              content: "Ожидание выгрузки",
              type: "default",
            },
            {
              link: "/sout/contracts/Finish",
              content: "Завершенные",
              type: "default",
            },
            {
              link: "/sout/contracts/Archive",
              content: "Архив",
              type: "archive",
            },
            {
              link: "/sout/contracts/Suspended",
              content: "Приостановленные",
              type: "no-result-space",
            },
            {
              link: "/sout/contracts/Basket",
              content: "Корзина",
              type: "no-result",
            },
            {
              link: "/sout/contracts/Terminated",
              content: "Расторгнутые",
              type: "no-result",
            },
          ]}
        />
      ) : null}
      {isLabHeadDeputy() || isAdmin() ? (
        <SidebarList
          title="Заведующий лабораторией"
          titleIsOpen={isOpen.lab_manager}
          toggleSidebar={() => toggleSidebar("lab_manager")}
          cantShowed={isAdmin() && !isOpen.lab_manager}
          color="blue"
          items={[
            {
              link: "/sout/contracts/GlobalSearch",
              content: (
                <>
                  <FaSearch className="sidebar__icon-search" />{" "}
                  <b className="sidebar__search-span">Общий поиск</b>
                </>
              ),
              type: "default",
            },
            {
              link: "/sout/contracts/Actual",
              content: (
                <>
                  <FaBars className="sidebar__icon-search" />{" "}
                  <b className="sidebar__search-span">Все актуальные </b>
                </>
              ),
              type: "default",
            },
            {
              link: "/sout/contracts/IdAssigning",
              content: "Присвоение ID",
              type: "default",
            },
            {
              link: "/sout/contracts/SigningAndCollectingInformation",
              content: "Подписание и сбор информации",
              type: "default",
            },
            {
              link: "/sout/contracts/InformationRequest",
              content: "Запрос информации",
              type: "default",
            },
            {
              link: "/sout/contracts/LocationsCreation",
              content: "Создание замерных блоков",
              type: "default",
            },
            {
              link: "/sout/contracts/ProjectsCreation",
              content: "Создание проектов",
              type: "default",
            },
            {
              link: "/sout/contracts/PreparationAndExecution",
              content: "Подготовка и исполнение",
              type: "default",
            },
            {
              link: "/sout/contracts/Acts",
              content: "Акты",
              type: "default",
            },

            {
              link: "/sout/contracts/WaitingForUnloadingDocuments",
              content: "Ожидание выгрузки",
              type: "default",
            },
            {
              link: "/sout/contracts/Finish",
              content: "Завершенные",
              type: "default",
            },
            {
              link: "/sout/contracts/Archive",
              content: "Архив",
              type: "archive",
            },
            {
              link: "/sout/contracts/Suspended",
              content: "Приостановленные",
              type: "no-result-space",
            },
            {
              link: "/sout/contracts/Hung",
              content: "Зависшие",
              type: "no-result",
            },
            {
              link: "/sout/contracts/Basket",
              content: "Корзина",
              type: "no-result",
            },
            {
              link: "/sout/contracts/Terminated",
              content: "Расторгнутые",
              type: "no-result",
            },
          ]}
        />
      ) : null}
      {isLabExpert() || isAdmin() ? (
        <SidebarList
          title="Эксперт лаборатории"
          titleIsOpen={isOpen.lab_expert}
          toggleSidebar={() => toggleSidebar("lab_expert")}
          cantShowed={isAdmin() && !isOpen.lab_expert}
          color="blue"
          items={[
            {
              link: "/sout/contracts/GlobalSearch",
              content: (
                <>
                  <FaSearch className="sidebar__icon-search" />{" "}
                  <b className="sidebar__search-span">Общий поиск</b>
                </>
              ),
              type: "default",
            },
            {
              link: "/sout/contracts/Actual",
              content: (
                <>
                  <FaBars className="sidebar__icon-search" />{" "}
                  <b className="sidebar__search-span">Все актуальные </b>
                </>
              ),
              type: "default",
            },
            {
              link: "/sout/contracts/DisassemblyPreparationAndExecution",
              content: "Разборка, подготовка и исполнение",
              type: "default",
            },
            {
              link: "/sout/contracts/WaitingForUnloadingDocuments",
              content: "Ожидание выгрузки",
              type: "default",
            },
            {
              link: "/sout/contracts/Finish",
              content: "Завершенные",
              type: "default",
            },
            {
              link: "/sout/contracts/Archive",
              content: "Архив",
              type: "archive",
            },
            {
              link: "/sout/contracts/Terminated",
              content: "Расторгнутые",
              type: "no-result-space",
            },
            {
              link: "/sout/contracts/Hung",
              content: "Зависшие",
              type: "no-result",
            },
          ]}
        />
      ) : null}
      {isLabEmployee() || isAdmin() ? (
        <SidebarList
          title="Лаборатория"
          titleIsOpen={isOpen.lab_emp}
          toggleSidebar={() => toggleSidebar("lab_emp")}
          color="blue"
          items={[
            {
              link: "/sout/contracts/GlobalSearch",
              content: (
                <>
                  <FaSearch className="sidebar__icon-search" />{" "}
                  <b className="sidebar__search-span">Общий поиск</b>
                </>
              ),
              type: "default",
            },
            {
              link: "/sout/contracts/Actual",
              content: (
                <>
                  <FaBars className="sidebar__icon-search" />{" "}
                  <b className="sidebar__search-span">Все актуальные </b>
                </>
              ),
              type: "default",
            },
            {
              link: "/sout/contracts/DisassemblyPreparationAndExecution",
              content: "Разборка, подготовка и исполнение",
              type: "default",
            },
            {
              link: "/sout/contracts/WaitingForUnloadingDocuments",
              content: "Ожидание выгрузки",
              type: "default",
            },
            {
              link: "/sout/contracts/Finish",
              content: "Завершенные",
              type: "default",
            },
            {
              link: "/sout/contracts/Archive",
              content: "Архив",
              type: "archive",
            },

            {
              link: "/sout/contracts/Terminated",
              content: "Расторгнутые",
              type: "no-result-space",
            },
            {
              link: "/sout/contracts/Hung",
              content: "Зависшие",
              type: "no-result",
            },
          ]}
        />
      ) : null}
      {isLabAssistant() || isAdmin() ? (
        <SidebarList
          title="Помощник заведующего лабораторией"
          titleIsOpen={isOpen.lab_assist}
          toggleSidebar={() => toggleSidebar("lab_assist")}
          color="blue"
          items={[
            {
              link: "/sout/contracts/GlobalSearch",
              content: (
                <>
                  <FaSearch className="sidebar__icon-search" />{" "}
                  <b className="sidebar__search-span">Общий поиск</b>
                </>
              ),
              type: "default",
            },
            {
              link: "/sout/contracts/Actual",
              content: (
                <>
                  <FaBars className="sidebar__icon-search" />{" "}
                  <b className="sidebar__search-span">Все актуальные </b>
                </>
              ),
              type: "default",
            },
            {
              link: "/sout/contracts/IdAssigning",
              content: "Присвоение ID",
              type: "default",
            },
            {
              link: "/sout/contracts/SigningAndCollectingInformation",
              content: "Подписание и сбор информации",
              type: "default",
            },
            {
              link: "/sout/contracts/InformationRequest",
              content: "Запрос информации",
              type: "default",
            },
            {
              link: "/sout/contracts/InExecutionForLabAsistant",
              content: "В исполнении",
              type: "default",
            },

            {
              link: "/sout/contracts/Acts",
              content: "Акты",
              type: "default",
            },

            {
              link: "/sout/contracts/WaitingForUnloadingDocuments",
              content: "Ожидание выгрузки",
              type: "default",
            },
            {
              link: "/sout/contracts/Finish",
              content: "Завершенные",
              type: "default",
            },
            {
              link: "/sout/contracts/Archive",
              content: "Архив",
              type: "archive",
            },
            {
              link: "/sout/contracts/Terminated",
              content: "Расторгнутые",
              type: "no-result-space",
            },
          ]}
        />
      ) : null}
      {isAdmin() ? (
        <SidebarList
          title="Админ"
          titleIsOpen={isOpen.admin}
          toggleSidebar={() => toggleSidebar("admin")}
          cantShowed={isAdmin() && !isOpen.admin}
          color="blue"
          items={[
            {
              link: "/sout/contracts/GlobalSearch",
              content: (
                <>
                  <FaSearch className="sidebar__icon-search" />{" "}
                  <b className="sidebar__search-span">Общий поиск</b>
                </>
              ),
              type: "default",
            },
            {
              link: "/sout/contracts/Actual",
              content: (
                <>
                  <FaBars className="sidebar__icon-search" />{" "}
                  <b className="sidebar__search-span">Все актуальные </b>
                </>
              ),
              type: "default",
            },
            {
              link: "/sout/contracts/Draft",
              content: "Черновик",
              type: "default",
            },
            {
              link: "/sout/contracts/IdAssigning",
              content: "Присвоение ID",
              type: "default",
            },
            {
              link: "/sout/contracts/SigningAndCollectingInformation",
              content: "Подписание и сбор информации",
              type: "default",
            },
            {
              link: "/sout/contracts/InformationRequest",
              content: "Запрос информации",
              type: "default",
            },
            {
              link: "/sout/contracts/LocationsCreation",
              content: "Создание замерных блоков",
              type: "default",
            },
            {
              link: "/sout/contracts/ProjectsCreation",
              content: "Создание проектов",
              type: "default",
            },
            {
              link: "/sout/contracts/PreparationAndExecution",
              content: "Подготовка и исполнение",
              type: "default",
            },
            {
              link: "/sout/contracts/Acts",
              content: "Акты",
              type: "default",
            },

            {
              link: "/sout/contracts/WaitingForUnloadingDocuments",
              content: "Ожидание выгрузки",
              type: "default",
            },
            {
              link: "/sout/contracts/Finish",
              content: "Завершенные",
              type: "default",
            },
            {
              link: "/sout/contracts/Archive",
              content: "Архив",
              type: "archive",
            },
            {
              link: "/sout/contracts/Suspended",
              content: "Приостановленные",
              type: "no-result-space",
            },
            {
              link: "/sout/contracts/Hung",
              content: "Зависшие",
              type: "no-result",
            },
            {
              link: "/sout/contracts/Basket",
              content: "Корзина",
              type: "no-result",
            },
            {
              link: "/sout/contracts/Terminated",
              content: "Расторгнутые",
              type: "no-result",
            },
          ]}
        />
      ) : null}
    </>
  );
};

export default ContractSidebar;
