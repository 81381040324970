import { FaCheck, FaMinus, FaPause, FaPlay } from "react-icons/fa";
import { ImCross } from "react-icons/im";

const ICONS = {
    PAUSE: <FaPause/>,
    NOTHING: <FaMinus/>,
    COMPLETED: <FaCheck/>,
    REJECTED: <ImCross/>,
    INPROGRESS: <FaPlay/>,
}


export const getIcon = (entitytype, stage, colunmNum, assessmentTypeId) => {
    console.log(stage)
    switch(entitytype){
        case "project":
            return getProjectIcon(stage, colunmNum, assessmentTypeId);
        case "location":
            return getLocationIcon(stage);
        default:
            break;
    }
}

const getProjectIcon = (stage, colunmNum, assessmentTypeId) => {
    
    if(colunmNum === 2 && assessmentTypeId !== 'WorkingConditionsSpecialAssesment'){
        return ICONS.NOTHING;
    } 

    switch(stage){
        case "OrganizationsAnalysis":
            return colunmNum === 1?ICONS.INPROGRESS:ICONS.NOTHING;
        case "ExecutorsAnalysis":
            return colunmNum === 1?ICONS.INPROGRESS:ICONS.NOTHING;
        case "InformationRequest":
            return colunmNum === 1?ICONS.INPROGRESS:ICONS.NOTHING;
        case "ReadyToWork":
            return colunmNum === 1?ICONS.INPROGRESS:ICONS.NOTHING;
        case "AcceptedToWork":
            return colunmNum === 1?ICONS.INPROGRESS:ICONS.NOTHING;
        case "Agreement":
            return colunmNum === 1?ICONS.INPROGRESS:ICONS.NOTHING;
        case "PrintedAndSent":
            return colunmNum === 1?ICONS.COMPLETED:ICONS.INPROGRESS;
        case "Verification":
            return colunmNum === 1?ICONS.COMPLETED:ICONS.INPROGRESS;
        case "UnloadingDocuments":
            return colunmNum === 1?ICONS.COMPLETED:ICONS.INPROGRESS;
        case "Finish":
            return colunmNum === 1?ICONS.COMPLETED:ICONS.COMPLETED;
        case "Archive":
            return colunmNum === 1?ICONS.COMPLETED:ICONS.COMPLETED;
        case "Errors":
            return colunmNum === 1?ICONS.COMPLETED:ICONS.INPROGRESS;
        case "Suspended":
            return colunmNum === 1?ICONS.PAUSE:ICONS.NOTHING;
        case "Terminated":
            return colunmNum === 1?ICONS.REJECTED:ICONS.NOTHING;
        default:
            break;
    }
}

const getLocationIcon = (stage) => {
    switch(stage){
        case "NotAssigned":
            return ICONS.INPROGRESS;
        case "Planned":
            return ICONS.INPROGRESS;
        case "InLeaving":
            return ICONS.INPROGRESS;
        case "Finish":
            return ICONS.COMPLETED;
        case "Archive":
            return ICONS.COMPLETED;
        case "Basket":
            return ICONS.REJECTED;
        default:
            break;
    }
}