import { useState, useContext, useEffect } from "react";
import ProjectContext from "../context/ProjectsContext";
import { FaSearch } from "react-icons/fa";
import { FaSort } from "react-icons/fa";
import { Input } from "./Input/Input";
import { Select } from "./Select/Select";
import INPUTS from "../constants/inputsData";
import InputDiapason from "./InputDiapason/InputDiapason";
import fetchUsers from "utils/fetchUsers";
import fetchWorkers from "utils/fetchWorkers";
import SearchSelectRegions from "./common/SearchSelectRegions";
import { useParams } from "react-router-dom";
import { fetchAgents } from "utils/fetchAgents";
import { generateQueriesForSearchRequest } from "shared/generateQueriesForSearchRequest";
import AuthContext from "context/AuthContext";
import fetchBranchOffices from "utils/fetchBranchOffices";
import { isAdmin } from "utils/defineUser";

const SearchSortTabsProject = () => {
  const {
    setQueryStrings,
    setSortParam,
    setPaginationQuery,
    setAdditionalInfoValue,
    setSubmitButtonPressed,
    currentEntity,
    setCurrentEntity,
    setAdditionalInfoValue2,
  } = useContext(ProjectContext);

  const params = useParams();

  const [organizations, setOrganizations] = useState();
  const [filials, setFilials] = useState([]);
  const [openSearch, setOpenSearch] = useState(false);
  const [openSort, setOpenSort] = useState(false);
  const [values, setValues] = useState({});
  const [regions, setRegions] = useState([]);
  const [tenderUsers, setTenderUsers] = useState("");
  const [commercialUsers, setCommercialUsers] = useState("");
  const [laboratoryUsers, setLaboratoryUsers] = useState("");
  const [workers, setWorkers] = useState("");

  useEffect(() => {
    fetchUsers(
      "TenderDepartment",
      setTenderUsers,
      setCommercialUsers,
      setLaboratoryUsers
    );
    fetchUsers(
      "CommercialDepartment",
      setTenderUsers,
      setCommercialUsers,
      setLaboratoryUsers
    );
    fetchUsers(
      "Laboratory",
      setTenderUsers,
      setCommercialUsers,
      setLaboratoryUsers,
      null,
      !isAdmin()
    );
    fetchWorkers(setWorkers);
    fetchAgents().then((res) => {
      setOrganizations(res);
    });
  }, []);

  const { role, accessRights } = useContext(AuthContext);

  const updateRegions = (updatedRegions) => {
    if (updatedRegions.length >= 1) {
      const regionId = updatedRegions[0].id;
      setRegions(updatedRegions);
      setValues({ ...values, Regions: regionId });
    } else if (updatedRegions.length === 0) {
      setRegions([]);
      setValues({ ...values, Regions: null });
    }
  };

  const [isPasted, setIsPasted] = useState(false);

  const onChange = (e) => {
    if (!isPasted) {
      setValues((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
      }));
    }
    setIsPasted(false);
  };

  const updateValues = (fieldName, value) => {
    setValues((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const onPasteCaptureHandler = (e) => {
    setIsPasted(true);

    setValues((prevState) => ({
      ...prevState,
      [e.target.name]: e.clipboardData.getData("Text"),
    }));
  };

  const onSubmit = (e) => {
    generateQueriesForSearchRequest({
      e,
      openSort,
      values,
      setQueryStrings,
      setSortParam,
      setPaginationQuery,
      setSubmitButtonPressed,
    });
  };

  const onReset = (e) => {
    setValues({});
    setQueryStrings("");
    setRegions([]);
    setAdditionalInfoValue("");
    setAdditionalInfoValue2("");

    setSortParam("");
    setSubmitButtonPressed(true);
  };

  useEffect(() => {
    if (currentEntity === "tender") {
      setCurrentEntity("project");
      onReset();
    }
    if (!currentEntity) {
      setCurrentEntity("project");
    }
  }, []);

  const displayAdditionalInfo = (e) => {
    const value = e.target.value;
    setAdditionalInfoValue(value);
  };

  const displayAdditionalInfo2 = (e) => {
    const value = e.target.value;
    setAdditionalInfoValue2(value);
  };

  useEffect(() => {
    if (values?.organizationId)
      fetchBranchOffices(values?.organizationId).then((res) => {
        setFilials(res);
      });
  }, [values?.organizationId]);

  return (
    <section className="workspace__search-area">
      <div
        className="search-area__buttons-wrapper"
        style={{
          marginLeft: `${
            accessRights[role].canChange.includes("project") ? "" : "0"
          }`,
        }}
      >
        <button
          className="button search-area__search-button button--orange"
          onClick={() => {
            setOpenSearch(!openSearch);
            setOpenSort(false);
          }}
        >
          <FaSearch className="search-button__icon" />
          <span className="search-button__text">Поиск</span>
        </button>
        <button
          className="button search-area__search-button button--orange"
          onClick={() => {
            setOpenSort(!openSort);
            setOpenSearch(false);
          }}
        >
          <FaSort className="search-button__icon icon-sort" />
          <span className="search-button__text">Сортировка</span>
        </button>
      </div>
      <form
        onReset={onReset}
        onSubmit={onSubmit}
        style={{ marginBottom: "70px" }}
      >
        <div className={`search-area__panel ${!openSearch && "collapse"}`}>
          <fieldset className="search-area__fieldset">
            <legend className="subtitle search-area__subtitle">Общие</legend>
            <Input
              type="text"
              onChange={onChange}
              value={values.id}
              data={{
                name: "id",
                label: "ID проекта",
              }}
            />
            <Input
              type="text"
              onChange={onChange}
              onPasteCapture={onPasteCaptureHandler}
              value={values.SearchString}
              data={INPUTS.SearchString}
            />

            <Select
              onChange={onChange}
              dataSrc="local"
              data={{
                name: "ContractConclusionDepartmentId",
                label: "Отдел заключения договоров",
                values: {
                  TenderDepartment: "Тендерный отдел",
                  CommercialDepartment: "Коммерческий отдел",
                },
              }}
            />
          </fieldset>
          <fieldset className="search-area__fieldset">
            <Input
              type="number"
              onChange={onChange}
              value={values.INN}
              data={{
                name: "INN",
                label: "ИНН заказчика",
              }}
              min={10}
              max={12}
            />
            <InputDiapason
              title="Число РМ"
              type="number"
              name={[
                INPUTS.FromWorkspaceCount.name,
                INPUTS.ToWorkspaceCount.name,
              ]}
              value={[
                values[INPUTS.FromWorkspaceCount.name],
                values[INPUTS.ToWorkspaceCount.name],
              ]}
              onChange={[(e) => onChange(e), (e) => onChange(e)]}
              placeholder={["от", "до"]}
            />
            <Select
              label="Организация"
              dataSrc="server"
              name="organizationId"
              value={values.organizationId}
              onChange={onChange}
              data={organizations}
            />
            {/* <InputDiapason
              title="Дата заключения договора"
              type="date"
              value={[
                values[INPUTS.FromConclusionContractDate.name],
                values[INPUTS.ToConclusionContractDate.name],
              ]}
              name={[
                INPUTS.FromConclusionContractDate.name,
                INPUTS.ToConclusionContractDate.name,
              ]}
              onChange={[
                (date) =>
                  updateValues(INPUTS.FromConclusionContractDate.name, date),
                (date) =>
                  updateValues(INPUTS.ToConclusionContractDate.name, date),
              ]}
              placeholder="дд.мм.гггг"
            /> */}
          </fieldset>
          <fieldset className="search-area__fieldset">
            <InputDiapason
              title="Плановая дата исполнения проекта"
              type="date"
              name={[
                INPUTS.FromPlannedExecutionDate.name,
                INPUTS.ToPlannedExecutionDate.name,
              ]}
              value={[
                values[INPUTS.FromPlannedExecutionDate.name],
                values[INPUTS.ToPlannedExecutionDate.name],
              ]}
              onChange={[
                (date) =>
                  updateValues(INPUTS.FromPlannedExecutionDate.name, date),
                (date) =>
                  updateValues(INPUTS.ToPlannedExecutionDate.name, date),
              ]}
              placeholder="дд.мм.гггг"
            />
            <Select
              label="Филиал"
              dataSrc="server"
              name="branchOfficeId"
              value={values.branchOfficeId}
              onChange={onChange}
              data={filials}
            />
          </fieldset>
          <div style={{ marginTop: 10, width: "830px", marginBottom: "20px" }}>
            <SearchSelectRegions
              isSearchBar
              regions={regions}
              updateRegions={updateRegions}
              color={"orange"}
            />
          </div>
          <div
            className="line"
            style={{ borderBottom: "2px solid #000", marginBottom: "15px" }}
          ></div>
          <fieldset className="search-area__fieldset-diapason">
            <div className="three-column-wrapper">
              <Select
                onChange={onChange}
                dataSrc="server"
                name={INPUTS.LaboratoryDepartmentEmployeeId.name}
                label={INPUTS.LaboratoryDepartmentEmployeeId.label}
                data={laboratoryUsers}
              />
              <Select
                onChange={onChange}
                dataSrc="local"
                label={INPUTS.AssessmentTypeId.label}
                name={INPUTS.AssessmentTypeId.name}
                data={INPUTS.AssessmentTypeId}
              />

              <InputDiapason
                title="Дата получения информации"
                type="date"
                name={[
                  INPUTS.FromInformationReceiptDate.name,
                  INPUTS.ToInformationReceiptDate.name,
                ]}
                value={[
                  values[INPUTS.FromInformationReceiptDate.name],
                  values[INPUTS.ToInformationReceiptDate.name],
                ]}
                onChange={[
                  (date) =>
                    updateValues(INPUTS.FromInformationReceiptDate.name, date),
                  (date) =>
                    updateValues(INPUTS.ToInformationReceiptDate.name, date),
                ]}
                placeholder="дд.мм.гггг"
              />

              <InputDiapason
                title="Дата назначения исполнителя"
                type="date"
                name={[
                  INPUTS.FromPerformerAppointedDate.name,
                  INPUTS.ToPerformerAppointedDate.name,
                ]}
                value={[
                  values[INPUTS.FromPerformerAppointedDate.name],
                  values[INPUTS.ToPerformerAppointedDate.name],
                ]}
                onChange={[
                  (date) =>
                    updateValues(INPUTS.FromPerformerAppointedDate.name, date),
                  (date) =>
                    updateValues(INPUTS.ToPerformerAppointedDate.name, date),
                ]}
                placeholder="дд.мм.гггг"
              />

              <InputDiapason
                title="Дата отправления на согласование"
                type="date"
                name={[
                  INPUTS.FromSendingForApprovalDate.name,
                  INPUTS.ToSendingForApprovalDate.name,
                ]}
                value={[
                  values[INPUTS.FromSendingForApprovalDate.name],
                  values[INPUTS.ToSendingForApprovalDate.name],
                ]}
                onChange={[
                  (date) =>
                    updateValues(INPUTS.FromSendingForApprovalDate.name, date),
                  (date) =>
                    updateValues(INPUTS.ToSendingForApprovalDate.name, date),
                ]}
                placeholder="дд.мм.гггг"
              />

              <InputDiapason
                title="Дата замеров"
                type="date"
                name={[
                  INPUTS.FromMeasurementsDate.name,
                  INPUTS.ToMeasurementsDate.name,
                ]}
                value={[
                  values[INPUTS.FromMeasurementsDate.name],
                  values[INPUTS.ToMeasurementsDate.name],
                ]}
                onChange={[
                  (date) =>
                    updateValues(INPUTS.FromMeasurementsDate.name, date),
                  (date) => updateValues(INPUTS.ToMeasurementsDate.name, date),
                ]}
                placeholder="дд.мм.гггг"
              />

              <InputDiapason
                title="Дата приема в работу"
                type="date"
                name={[
                  INPUTS.FromAcceptedToWorkDate.name,
                  INPUTS.ToAcceptedToWorkDate.name,
                ]}
                value={[
                  values[INPUTS.FromAcceptedToWorkDate.name],
                  values[INPUTS.ToAcceptedToWorkDate.name],
                ]}
                onChange={[
                  (date) =>
                    updateValues(INPUTS.FromAcceptedToWorkDate.name, date),
                  (date) =>
                    updateValues(INPUTS.ToAcceptedToWorkDate.name, date),
                ]}
                placeholder="дд.мм.гггг"
              />

              <InputDiapason
                title="Дата отправки отчета"
                type="date"
                name={[
                  INPUTS.FromReportSendingDate.name,
                  INPUTS.ToReportSendingDate.name,
                ]}
                value={[
                  values[INPUTS.FromReportSendingDate.name],
                  values[INPUTS.ToReportSendingDate.name],
                ]}
                onChange={[
                  (date) =>
                    updateValues(INPUTS.FromReportSendingDate.name, date),
                  (date) => updateValues(INPUTS.ToReportSendingDate.name, date),
                ]}
                placeholder="дд.мм.гггг"
              />
              <Select
                onChange={onChange}
                dataSrc="local"
                label={INPUTS.ExecutionProgramId.label}
                name={INPUTS.ExecutionProgramId.name}
                data={INPUTS.ExecutionProgramId}
              />
              <Select
                onChange={onChange}
                dataSrc="local"
                label={INPUTS.HaveWorkbook.label}
                name={INPUTS.HaveWorkbook.name}
                data={INPUTS.HaveWorkbook}
              />
              <InputDiapason
                title="Дата подписания титульного листа"
                type="date"
                name={[
                  INPUTS.FromTitleSheetSignDate.name,
                  INPUTS.ToTitleSheetSignDate.name,
                ]}
                value={[
                  values[INPUTS.FromTitleSheetSignDate.name],
                  values[INPUTS.ToTitleSheetSignDate.name],
                ]}
                onChange={[
                  (date) =>
                    updateValues(INPUTS.FromTitleSheetSignDate.name, date),
                  (date) =>
                    updateValues(INPUTS.ToTitleSheetSignDate.name, date),
                ]}
                placeholder="дд.мм.гггг"
              />
              <InputDiapason
                title="Дата выгрузки"
                type="date"
                name={[
                  INPUTS.FromUnloadingDate.name,
                  INPUTS.ToUnloadingDate.name,
                ]}
                value={[
                  values[INPUTS.FromUnloadingDate.name],
                  values[INPUTS.ToUnloadingDate.name],
                ]}
                onChange={[
                  (date) => updateValues(INPUTS.FromUnloadingDate.name, date),
                  (date) => updateValues(INPUTS.ToUnloadingDate.name, date),
                ]}
                placeholder="дд.мм.гггг"
              />
            </div>
          </fieldset>
        </div>

        {/* //!Sort Tab */}
        <div
          className={`search-area__fieldset ${openSort ? null : "collapse"}`}
        >
          <fieldset className="search-area__filters search-area__filters--select-area">
            <h4 className="workspace__title">Сортировка</h4>
            <Select
              onChange={onChange}
              dataSrc="local"
              name="SortMethod"
              data={INPUTS.SortMethod}
            />
            <Select
              onChange={onChange}
              dataSrc="local"
              data={INPUTS.SortFieldProject}
            />
          </fieldset>
          <fieldset
            className="search-area__filters search-area__filters--select-area"
            style={{ marginLeft: "40px" }}
          >
            <h4 className="workspace__title">Дополнительная информация</h4>
            <Select
              onChange={displayAdditionalInfo}
              dataSrc="local"
              data={INPUTS.AdditionalInfoFieldProject}
            />

            <Select
              onChange={displayAdditionalInfo2}
              dataSrc="local"
              data={{
                ...INPUTS.AdditionalInfoFieldProject,
                label: "Дополнительная информация 2:",
              }}
            />
          </fieldset>
        </div>

        <fieldset
          style={{
            marginTop: "40px",
          }}
          className={`${openSearch || openSort ? null : "collapse"}`}
        >
          <button type="submit" className="button button--orange">
            {" "}
            {openSearch && "Найти"}
            {openSort && "Сортировать"}{" "}
          </button>
          <button
            type="reset"
            className="button button--orange"
            style={{ marginLeft: "15px" }}
          >
            {" "}
            Сбросить{" "}
          </button>
        </fieldset>
      </form>
    </section>
  );
};
export default SearchSortTabsProject;
