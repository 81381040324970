import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Handbook from "./Handbook";
import AuthContext from "../../context/AuthContext";

import SwitchButton from "../common/SwitchButton";
import { FaUserSecret } from "react-icons/fa";
import { FaUser } from "react-icons/fa";
import { FaUserMd } from "react-icons/fa";
import { ReactComponent as SwitchOffIcon } from "../../assets/images/switch-off.svg";

import { isLabHeadDeputy, isСhieftain } from "../../utils/defineUser";
import { useLocation } from "react-router-dom";
import { isAdmin } from "../../utils/defineUser";

const color = {
  tender: "purple",
  contracts: "blue",
  projects: "orange",
  locations: "olive",
};

const Header = () => {
  const { user, username, logoutUser, role, startPageData } =
    useContext(AuthContext);
  const location = useLocation();

  const [currentEntity, setCurrentEntity] = useState();

  useEffect(() => {
    const defineEntity = () => {
      setCurrentEntity("");
      ["contracts", "tender", "projects", "locations"].forEach((item) => {
        if (location.pathname.includes(item)) {
          setCurrentEntity(item);
        }
      });
    };
    defineEntity();
  }, [location.pathname]);

  const getUserIcon = () => {
    if (isСhieftain()) {
      return <FaUserSecret className="user-panel__icon icon-chieftain" />;
    }

    switch (user.Department) {
      case "None":
        return <FaUserSecret className="user-panel__icon icon-admin" />;
      case "MedicalCenter":
        return <FaUserMd className="user-panel__icon icon-user" />;
      default:
        return <FaUser className="user-panel__icon icon-user" />;
    }
  };

  return (
    <header className="main-header">
      <div className="container main-header__container">
        <Link to="/" className="icon-link">
          <img src="/logo.png" className="icon-logo" alt="Логотип ПРЦОТ" />
          <span className="erp-version">v2.2</span>
        </Link>
        {user ? (
          <nav className="main-header__user-panel">
            <div className="header__admin-buttons">
              {startPageData[role][0]?.items?.map((item, id) => (
                <Link
                  to={item?.link}
                  key={item?.entity}
                  style={{ marginRight: 20 }}
                >
                  <button
                    className={`button ${
                      currentEntity === item?.entity
                        ? `button--${color[item?.entity]}`
                        : "button--active"
                    }`}
                  >
                    {item?.title}
                  </button>
                </Link>
              ))}
            </div>

            <ul className="user-panel__icons-list">
              {user && <Handbook />}

              <li className="user-panel__info-item">
                {getUserIcon()}
                <span className="user-panel__user-info">{username}</span>
              </li>
              <li onClick={logoutUser} title="Выйти из аккаунта">
                <SwitchOffIcon className="user-panel__icon icon-switch-off" />
              </li>
            </ul>
          </nav>
        ) : null}
      </div>
    </header>
  );
};

export default Header;
