import { generateToken } from 'shared/generateToken'
import { api } from 'settings/Api';

export const fetchLocationByTenderId = async (tenderId) => {
  const token = generateToken();
  try {
    let response = await api(`/api/sout/admin/potential-measuring-blocks?TenderId=${tenderId}`, {
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    });
    let data = await response.json();
    if(!data) throw new Error('Data is empty');
    return data?.data?.list;
  }
  catch(e) {
    alert(`Не удалось найти тендер: ${e.message}`);
  }
}