import Asterisk from "components/Asterisk";
import { DatePicker } from "components/DateInput/DateInput";
import EntitiesTable from "components/EntitiesTable/EntitiesTable";
import EntitiesTableItem from "components/EntitiesTable/EntitiesTableItem";
import { Select } from "components/Select/Select";
import GroupButton from "components/common/GroupButton";
import SelectRegions from "components/common/SelectRegions";
import React, { useEffect, useState } from "react";
import { dateToString, dateToStringWithTime } from "shared/formatDate";
import { LabelTitle } from "shared/ui/LabelTitle/LabelTitle";
import { toDateWithoutTime } from "utils/formatDate";

const convertDate = (date) => {
  return date ? dateToString(date) : null;
};

const asessementTypes = {
  ProductionControl: "ПК",
  WorkingConditionsSpecialAssesment: "СОУТ",
  ProfessionalRisks: "ОПР",
};

const ProjectsCreationContractStage = ({
  projects,
  initCount,
  contract,
  contractAsses,
  setProjects,
  setIsDisabled,
  setIsVisible,
}) => {
  const [count, setCount] = useState(1);
  const [dep, setDep] = useState(null);
  const [disable, setDisable] = useState(true);
  const [maxCount, setMaxCount] = useState(initCount || 1);
  const [name, setName] = useState("");
  const [isCheckAsses, setIsCheckAsses] = useState(false);
  const [isNullPK, setIsNullPK] = useState(false);
  const [plannedExecutionDate, setPlannedExecutionDate] = useState(null);

  const [workspaceCount, setWorkspaceCount] = useState(null);
  const [asses, setAsses] = useState(null);
  const [regions, setRegions] = useState([]);
  const [assesmentTypeArray, setAssesmentTypeArray] = useState([
    {
      value: "WorkingConditionsSpecialAssesment",
      label: "СОУТ",
      disabled: false,
    },
    { value: "ProfessionalRisks", label: "ОПР", disabled: false },
    { value: "ProductionControl", label: "ПК", disabled: false },
  ]);
  const updateRegions = (updatedRegions) => setRegions(updatedRegions);
  // const [createdProjectsCount, setCreatedProjectsCount] = useState(0);
  useEffect(() => {
    if (!isCheckAsses && contractAsses?.length) {
      setAssesmentTypeArray(
        assesmentTypeArray.map((item) =>
          contractAsses?.includes(item.value)
            ? item
            : { ...item, disabled: true }
        )
      );
      setIsCheckAsses(true);
    }
    setIsVisible(count == maxCount);

    if (count > maxCount && maxCount !== "") setMaxCount(Number(count));
    checkProjects();
  });

  useEffect(() => {
    checkProject();
  }, [contract, projects]);

  const checkProject = () => {
    if (contract?.projects?.length > 0 || projects?.length > 0) {
      const allProjects = [...contract?.projects, ...projects];

      if (allProjects?.length > 1) {
        setIsDisabled(!allProjects.every((elem) => elem?.name?.length > 0));
      } else {
        setIsDisabled(false);
      }
    } else {
      setIsDisabled(true);
    }
  };

  const nextProjectHandler = () => {
    if (count != maxCount) {
      setCount((prev) => Number(prev) + 1);
    }
    checkProject();
    setProjects([
      ...projects,
      {
        name: name,
        workspaceCount: parseInt(workspaceCount) || 0,
        assessmentTypeId: asses,
        workOnRequest: contract?.workOnRequest,
        regions: regions,
        contractDepartmentNumber:
          asses === "WorkingConditionsSpecialAssesment"
            ? contract?.departments?.length === 1
              ? contract?.departments[0]?.departmentNumber
              : parseInt(dep)
            : null,
        plannedExecutionDate: convertDate(plannedExecutionDate),
      },
    ]);

    setAsses("");
    setName("");
    setDep(null);
    setIsNullPK(false);
    setPlannedExecutionDate(null);
    setRegions([]);
    setWorkspaceCount("");
    // setCreatedProjectsCount((prev) => prev + 1);
  };

  const checkProjects = () => {
    setDisable(
      !(
        name &&
        asses &&
        (workspaceCount || isNullPK) &&
        regions.length !== 0 &&
        (plannedExecutionDate || contract?.workOnRequest)
      )
    );
  };

  const handleRadioChange = (e) => {
    const { name, value, checked } = e.target;
    setAsses(value);
  };

  function getAsessmentTypes() {
    const asessementTypes = {
      ProductionControl: "ПК",
      ProfessionalRisks: "ОПР",
      WorkingConditionsSpecialAssesment: "СОУТ",
    };
    let types = [];
    if (contract?.assesmentTypes) {
      for (let type of contract?.assesmentTypes) {
        types.push(asessementTypes[type.id]);
      }
    }
    return types.join(", ");
  }

  const validateNumber = (value) => {
    return value.replace(/[^0-9]/g, "");
  };

  return (
    <div
      style={{
        width: 650,
        height: 450,
        margin: "0 auto",
        overflowY: "scroll",
        position: "relative",
        overflowX: "hidden",
        scrollbarWidth: "thin",
      }}
    >
      {contract?.id && (
        <div
          style={{
            width: "100%",
            fontWeight: 400,
            fontSize: 18,
            textAlign: "left",
          }}
        >
          <p>Информация из договора</p>
          <div
            style={{
              width: "100%",
              display: "flex",
              marginTop: 10,
              justifyContent: "space-between",
            }}
          >
            <div style={{ width: "35%" }}>
              <p
                style={{ marginBottom: 10 }}
              >{`Тип оценки: ${getAsessmentTypes()}`}</p>
              {contract?.workspaceCount?.sout && (
                <p>{`Число РМ СОУТ: ${
                  contract?.workspaceCount?.sout
                    ? contract?.workspaceCount?.sout
                    : "x"
                }`}</p>
              )}
              {contract?.workspaceCount?.pk && (
                <p style={{ margin: "5px 0" }}>
                  {`Число РМ ПК: ${
                    contract?.workspaceCount?.pk
                      ? contract?.workspaceCount?.pk
                      : "x"
                  }`}
                </p>
              )}
              {contract?.workspaceCount?.opr && (
                <p>
                  {`Число РМ ОПР: ${
                    contract?.workspaceCount?.opr
                      ? contract?.workspaceCount?.opr
                      : "x"
                  }`}
                </p>
              )}
            </div>
            <div style={{ width: "60%" }}>
              <p>{`Плановая дата исполнения: ${toDateWithoutTime(
                contract?.plannedExecutionDate
              )}`}</p>
              <p style={{ marginTop: 5 }}>
                {`Регионы: ${contract?.regions?.map(
                  (region, index) =>
                    `${region?.name} ${
                      index === contract?.regions?.length ? " , " : ""
                    } `
                )}`}
              </p>
            </div>
          </div>
        </div>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 10,
          position: "relative",
        }}
      >
        <p className="projectModalCount">{`Количество проектов: ${Number(
          contract?.projects?.length
        )}`}</p>
      </div>
      {contract?.projects?.length > 0 && (
        <EntitiesTable
          title={"Существующие проекты"}
          list={contract?.projects}
          headers={[
            "#",
            "ID",
            "Наименование проекта",
            "Тип оценки",
            "Число РМ",
          ]}
          printFunc={(item, idx) => (
            <EntitiesTableItem
              values={[
                idx + 1,
                item?.id,
                item?.name || "-",
                asessementTypes[item?.assessmentTypeId] || "-",
                item?.workspaceCount || "-",
              ]}
              key={item?.id}
            />
          )}
        />
      )}
      <label className="modal__input-title">Наименование проекта:</label>
      <input
        className="input"
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <div
        className="infoCollectionModal"
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "65%",
        }}
      >
        <GroupButton
          onChangeHandler={handleRadioChange}
          type={"radio"}
          values={asses} //asses
          title={"Тип оценки"}
          info={assesmentTypeArray}
        />
        <div style={{ marginTop: -5 }}>
          <label className="modal__input-title">Количество РМ:</label>
          <div style={{ display: "flex" }}>
            <input
              className="input"
              type="text"
              value={workspaceCount}
              onChange={(e) =>
                setWorkspaceCount(validateNumber(e.target.value))
              }
            />
            <label style={{ display: "flex", marginLeft: 5 }}>
              <input
                checked={isNullPK}
                type="checkbox"
                onClick={() => setIsNullPK(!isNullPK)}
              />
              <span
                style={{
                  fontFamily: "monospace",
                  marginLeft: 5,
                  marginTop: 7,
                  fontSize: 16,
                }}
              >
                X
              </span>
            </label>
          </div>
        </div>
        <div
          style={{
            width: 190,
            marginTop: -25,
            position: "absolute",
            right: 0,
          }}
        >
          <div>
            <SelectRegions regions={regions} updateRegions={updateRegions} />
          </div>
        </div>
      </div>
      <div
        style={{ display: "flex", width: 420, justifyContent: "space-between" }}
      >
        {!contract?.workOnRequest && (
          <div style={{ width: 200, marginTop: 10 }}>
            <LabelTitle
              styles={{
                marginBottom: "5px",
                fontSize: "16px",
                fontWeight: "400",
              }}
            >
              Плановая дата исполнения
            </LabelTitle>
            <DatePicker
              className="input w-200"
              name="plannedExecutionDate"
              value={plannedExecutionDate || null}
              onChange={(date) => {
                setPlannedExecutionDate(date);
              }}
            />
          </div>
        )}

        {asses === "WorkingConditionsSpecialAssesment" && (
          <div style={{ marginTop: 15, width: 200 }}>
            <Select
              label="Идентиф. номер"
              classNameLabel={"modal__input-title"}
              dataSrc="server"
              defaultValue={
                contract?.departments?.length === 1
                  ? contract?.departments[0]?.departmentNumber
                  : null
              }
              disabled={contract?.departments?.length === 1}
              name="organizationId"
              value={null}
              onChange={(e) => setDep(e.target.value)}
              data={contract?.departments?.map((dep) => {
                return {
                  id: dep?.departmentNumber,
                  name: dep?.departmentNumber,
                };
              })}
            />
          </div>
        )}
      </div>
      <button
        className={"button--orange button mesBtn"}
        onClick={nextProjectHandler}
        disabled={disable}
      >
        Сoздать проект
      </button>
      {projects?.length > 0 && (
        <EntitiesTable
          title={"Созданные проекты"}
          list={projects}
          headers={["#", "Наименование проекта", "Тип оценки", "Число РМ"]}
          printFunc={(item, idx) => (
            <EntitiesTableItem
              values={[
                idx + 1,
                item?.name || "-",
                asessementTypes[item?.assessmentTypeId] || "х",
                item?.workspaceCount || "-",
              ]}
              key={item?.id}
              canDelete={true}
              deleteFunc={() =>
                setProjects(projects?.filter((i, indx) => indx !== idx))
              }
            />
          )}
        />
      )}
    </div>
  );
};

export default ProjectsCreationContractStage;
