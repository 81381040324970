import React, { useState } from "react";

const EntitiesTable = (props) => {
  const { title, list, headers, printFunc, canDelete = false } = props;
  const [isVisible, setIsVisible] = useState(true);

  return (
    <div style={{ position: "relative" }}>
      <div>
        <button
          className="modal__close-button close-button"
          style={{ fontSize: 20 }}
          onClick={() => setIsVisible(!isVisible)}
        >
          {isVisible ? <span>▴</span> : <span>▾</span>}
        </button>
      </div>

      <p className="tableTitle">{title}</p>
      {isVisible && (
        <table className="entitiesTable">
          <thead>
            <tr>
              {headers?.map((item, id) => (
                <th key={id}>{item}</th>
              ))}
            </tr>
          </thead>
          <tbody>{list?.map(printFunc)}</tbody>
        </table>
      )}
    </div>
  );
};

export default EntitiesTable;
