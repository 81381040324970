import { useContext, useEffect, useState, memo } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import SearchContracts from "entities/contracts/components/SearchContracts";
import { isCommercialUser } from "utils/defineUser";

const StageContracts = memo(() => {
  const params = useParams();

  const navigate = useNavigate();

  const [stage, setStage] = useState({
    title: null,
    query: null,
  });

  const WORKSPACE_OPTIONS = {
    sout: {
      contracts: {
        GlobalSearch: ["Общий поиск", "null=null"],
        Actual: [
          "Все актуальные",
          `WorkFlowStatuses=Draft&WorkFlowStatuses=IdAssigning&WorkFlowStatuses=ProjectsCreation&WorkFlowStatuses=LocationsCreation&WorkFlowStatuses=InformationRequest&WorkFlowStatuses=PreparationAndExecution&WorkFlowStatuses=Acts&
        WorkFlowStatuses=WaitingForUnloadingDocuments&WorkFlowStatuses=Finish&WorkFlowStatuses=SigningAndCollectingInformation`,
        ],
        Draft: ["Черновик", `WorkFlowStatuses=Draft`],
        IdAssigning: ["Присвоение ID", `WorkFlowStatuses=IdAssigning`],
        SigningAndCollectingInformation: [
          "Подписание и сбор информации",
          `WorkFlowStatuses=SigningAndCollectingInformation`,
        ],
        InformationRequest: [
          "Запрос информации",
          `WorkFlowStatuses=InformationRequest`,
        ],
        LocationsCreation: [
          "Создание замерных блоков",
          `WorkFlowStatuses=LocationsCreation`,
        ],
        ProjectsCreation: [
          "Создание проектов",
          `WorkFlowStatuses=ProjectsCreation`,
        ],
        PreparationAndExecution: [
          "Подготовка и исполнение",
          `WorkFlowStatuses=PreparationAndExecution`,
        ],
        Acts: ["Акты", `WorkFlowStatuses=Acts`],
        WaitingForUnloadingDocuments: [
          "Ожидание выгрузки",
          `WorkFlowStatuses=WaitingForUnloadingDocuments`,
        ],
        Finish: ["Завершённые", `WorkFlowStatuses=Finish`],
        Suspended: ["Приостановленные", "WorkFlowStatuses=Suspended"],
        Hung: ["Зависшие", "WorkFlowStatuses=Hung"],
        Archive: ["Архив", `WorkFlowStatuses=Archive`],

        InExecutionForCommercials: [
          "В исполнении",
          `WorkFlowStatuses=LocationsCreation&WorkFlowStatuses=ProjectsCreation&WorkFlowStatuses=InformationRequest&WorkFlowStatuses=PreparationAndExecution`,
        ],
        InExecutionForTenders: [
          "В исполнении",
          `WorkFlowStatuses=LocationsCreation&WorkFlowStatuses=ProjectsCreation&WorkFlowStatuses=Suspended&WorkFlowStatuses=InformationRequest&WorkFlowStatuses=PreparationAndExecution&WorkFlowStatuses=WaitingForUnloadingDocuments&WorkFlowStatuses=Acts&WorkFlowStatuses=SigningAndCollectingInformation`,
        ],
        DisassemblyPreparationAndExecution: [
          "Разборка, подготовка и исполнение",
          "WorkFlowStatuses=IdAssigning&WorkFlowStatuses=LocationsCreation&WorkFlowStatuses=ProjectsCreation&WorkFlowStatuses=InformationRequest&WorkFlowStatuses=Suspended&WorkFlowStatuses=PreparationAndExecution&WorkFlowStatuses=Acts&WorkFlowStatuses=SigningAndCollectingInformation",
        ],
        InExecutionForLabAsistant: [
          "В исполнении",
          "WorkFlowStatuses=ProjectsCreation&WorkFlowStatuses=Suspended&WorkFlowStatuses=PreparationAndExecution",
        ],
        Terminated: ["Рассторгнутые", `WorkFlowStatuses=Terminated`],
        Basket: ["Корзина", `WorkFlowStatuses=Basket`],
      },
    },
  };

  useEffect(() => {
    if (stage) {
      if (params.directionId === "undefined") {
        navigate("/Home");
        return;
      }
      const title =
        WORKSPACE_OPTIONS[params.directionId][params.departmentId][
          params.stageId
        ][0];
      const query =
        WORKSPACE_OPTIONS[params.directionId][params.departmentId][
          params.stageId
        ][1];

      setStage({
        title,
        query,
      });
    }
  }, [params]);

  return <>{stage && <SearchContracts stage={stage} />}</>;
});
export default StageContracts;
