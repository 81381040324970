import { api } from 'settings/Api';
const fetchUsers = async (departmentName,setTenderUsers, setCommercialUsers, setLaboratoryUsers, branchOfficeId, onlyActive = false) => {
  const token = JSON.parse(localStorage.getItem('authTokens')).data.accessToken;
  let response = await api(`/api/sout/helpers/handbooks/engineers?department=${departmentName}&branchOfficeId=${branchOfficeId}&onlyActive=${onlyActive}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
      // 'Authorization':  'String(authTokens.access) ,
    },
  })
  let data = await response.json();

  switch (departmentName) {
    case 'TenderDepartment':
      setTenderUsers(data.data);
      break;
    case 'CommercialDepartment':
      setCommercialUsers(data.data);
      break;
    case 'Laboratory':
      setLaboratoryUsers(data.data);
      break;
    default:
      throw new Error('Fetch error: department cannot found!');
  }
}
export default fetchUsers