export const TENDER_STAGES = [
	"Draft", //0
	"Accepted", //1
	"Filed", //2
	"WonAndSigning", //3
	"AlternateIdAssignment", //!4
	"InformationRequest", //5
	"ReadyToWork", //6
	"Measurements", //7
	"PerformerAppointed", //8
	"AcceptedToWork", //9
	"Agreement", //10
	"PrintedAndSent", //11
	"UnloadingDocuments", //12
	"Acts", //13
	"Payment", //14
	"WaitingForReport", //15
	"ReturnOfSecurity", //16
	"Finish", //17
	"Archive", //18
];

export const COMMERCIAL_STAGES = [
	"Draft", //0
	"AlternateIdAssignment", //!1
	"InformationRequest", //2
	"ReadyToWork", //3
	"Measurements", //4
	"PerformerAppointed", //5
	"AcceptedToWork", //6
	"Agreement", //7
	"PrintedAndSent", //8
	"UnloadingDocuments", //9
	"Acts", //10
	"Payment", //11
	"WaitingForReport", //12
	"Finish", //13
	"Archive", //14
];

export const REJECT_STAGES = [
	"Basket",
	"Lost",
	"NotFiled",
	"NotSigned",
	"Terminated",
];

export const assessmentTypesTranslations = {
	ProductionControl: "ПК",
	WorkingConditionsSpecialAssesment: "СОУТ",
	ProfessionalRisks: "ОПР",
};

const RU_DICT = {
	AdditionalName: "Доп.наименование",
	ProjectsCreation: "Создание проектов",
	Filed: "Подано",
	ProjectsCreation: "Создание проектов",
	WonAndSigning: "Победа в тендере и подписание",
	InformationRequest: "Сбор информации",
	ReadyToWork: "Готово к работе",
	Measurements: "Замеры",
	PerformerAppointed: "Исполнитель назначен",
	AcceptedToWork: "Принято в работу",
	Agreement: "Согласование",
	PrintedAndSent: "Распечатан и отправлен",
	InLeaving: "На выезде",
	Suspended: "Приостановленные",
	Hung: "Зависшие",
	UnloadingDocuments: "Выгрузка",
	Verification: 'Проверка',
	Errors: 'Ошибки',
	ExecutorsAnalysis: "Разбор по исполнителям",
	Waiting: 'Ожидание',
	Acts: "Акты",
	Payment: "Ожидают оплаты и получения актов",
	ReturnOfSecurity: "Возврат обеспечения",
	WaitingForReport: "Ожидают отчёта агента",
	Finish: "Завершен",
	Archive: "Архив",
	AlternateIdAssignment: "Присвоение ID",
	OrganizationsAnalysis: "Первичный разбор",
	Unassigned: "Неназначен",
	Planned: "Запланирован",

	// 'None': 'Нет',
	CommercialDepartment: "Коммерческий отдел",
	TenderDepartment: "Тендерный отдел",
	Laboratory: "Лаборатория",
	AccountingDepartment: "Бухгалтерия",

	LaborExpert_3_0: "ТрудЭксперт",
	Attestation_5_1: "Аттестация",

	ProductionControl: "ПК",
	ProfessionalRisks: 'ОПР',
	WorkingConditionsSpecialAssesment: "СОУТ",

	None: " - ",
	null: "Нет",

	TenderId: "ID Тендера",
	IdAssigning: "Присвоение ID",
	SigningAndCollectingInformation: "Подписание и сбор информации",
	LocationsCreation: "Создание замерных блоков",
	WaitingForRequest: "Ожидание заявки",
	InformationRequest: "Запрос информации",
	PreparationAndExecution: "Подготовка и исполнение",
	Acts: "Акты",
	WaitingForPayment: "Ожидание оплаты",
	ReturnOfSecurity: "Возврат обеспечения",
	ReturnOfActs: "Возврат актов",
	Terminated: "Рассторгнут",
	WaitingForUnloadingDocuments: "Ожидание выгрузки",
	AssesmentTypeId: "Тип оценки",
	HaveWorkbook: "Рабочая тетрадь",
	OfferSecuringMethodId: "Обеспечение предложения",
	ContractSecuringMethodId: "Обеспечение контракта",
	CurrentWorkFlowStageId: "Текущая площадка",
	AcceptingOffersDeadline: "Дата окончания подачи заявок",
	PlannedExecutionDate: "Дата планового исполнения",
	PublicationDate: "Дата публикации",
	ConclusionContractDate: "Дата заключения договора",
	AuctionDate: "Дата аукциона",
	ContractPrice: "Цена контракта",

	false: "Нет",
	true: "Да",

	DigitalAuction: "Электронный аукцион",
	RequestForQuotations: "Запрос котировок или предложений",
	Other: "Прочее",

	Gov: "44 ФЗ",
	PartialGov: "223 ФЗ",

	PaymentOrder: "ПП",
	BankGuarantee: "БГ",
	Rate: "Тариф",

	SummaryReportDate: "Дата итогового протокола",

	"01": "Январь",
	"02": "Февраль",
	"03": "Март",
	"04": "Апрель",
	"05": "Май",
	"06": "Июнь",
	"07": "Июль",
	"08": "Август",
	"09": "Сентябрь",
	10: "Октябрь",
	11: "Ноябрь",
	12: "Декабрь",

	NotAssigned: "Не назначены",
	NotRequired: "Не требуются",
	Assigned: "Назначены",
	Complited: "Проведен",

	Draft: "Черновик",
	Estimated: "Оцененные",
	Approved: "Одобренные",
	AcceptedToWork: "Принято в работу",
	Filed: "Поданные",
	WonAndSigning: "Победа в тендере и подписание",
	Finish: "Завершен",
	Archive: "Архив",

	Basket: "Корзина",
	Lost: "Не выигранные",
	NotFiled: "Не поданные",
	NotSigned: "Не подписанные",
};

const DATES = {
	1: "Январь",
	2: "Февраль",
	3: "Март",
	4: "Апрель",
	5: "Май",
	6: "Июнь",
	7: "Июль",
	8: "Август",
	9: "Сентябрь",
	"01": "Январь",
	"02": "Февраль",
	"03": "Март",
	"04": "Апрель",
	"05": "Май",
	"06": "Июнь",
	"07": "Июль",
	"08": "Август",
	"09": "Сентябрь",
	10: "Октябрь",
	11: "Ноябрь",
	12: "Декабрь",
};

export const RU_USER = {
	CommercialDepartment: "Коммерческий отдел",
	TenderDepartment: "Тендерный отдел",
	Laboratory: "Лаборатория",
	AccountingDepartment: "Лаборатория",
	MedicalCenter: "МЕД",

	lab_chief: "Начальник лаборатории",
	lab_manager: "Заведующий лабораторией",
	lab_expert: "Эксперт лаборатории",
	lab_assist: 'Помощник заведующего лабораторией',
	lab_emp: 'Сотрудник лаборатории',
	tender_chief: 'Начальник тендерного отдела',
	tender_emp: 'Сотрудник тендерного отдела',
	comercial_chief: 'Начальник коммерческого отдела',
	comercial_emp: 'Сотрудник коммерческого отдела',
	admin: 'Админ',

	None: "Нет",
	Employee: "Сотрудник",
	DepartmentHead: "Начальник отдела",
	Expert: "Эксперт",
	Manager: "Заведующий",
	Assistant: "Помощник заведующего",
	Accountant: "Бухгалтер",
};

export const translateText = (text) => RU_DICT[text] || text;
export const translateUser = (text) => RU_USER[text] || text;
export const translateDate = (text) => DATES[text] || text;

export const dateToText = (date) => {
	const arr = date.split("/");
	if (arr.length <= 1) return date;
	const mounth = translateDate(arr[1]);
	const formatedDate = mounth + " " + arr[0];
	return formatedDate;
};

export const dateToTextTemp = (date) => {
	const arr = date.split("/");
	if (arr.length <= 1) return date;
	const mounth = translateDate(arr[0]);
	const year = arr[1];
	const formatedDate = mounth + " " + year;
	return formatedDate;
};

export const dateToTextDashed = (date) => {
	if (!date) return date;

	const arr = date.split("-");
	if (arr.length <= 1) return date;
	const mounth = translateDate(arr[1]);
	const formatedDate = mounth + " " + arr[0];
	return formatedDate;
};

const getTenderStageId = (stage) => TENDER_STAGES.indexOf(stage);
export { getTenderStageId };
